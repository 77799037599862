import React, { useEffect } from 'react'
import { BannerContent } from './BannerContent'
import { DownloadContent } from './DownloadContent'
import { Layout } from '@/Components/Layouts/AuthLayout/component/Layout'
import { isIOS } from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'

const GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.reactnativefirebase.gpmappspecific'

const APPLE_URL = 'https://apps.apple.com/fr/app/budly-network/id6463802429'
const STORE_URL = isIOS ? APPLE_URL : GOOGLE_URL

const DownloadApp = () => {
  const [searchParams] = useSearchParams()
  const redirect = JSON.parse(searchParams.get('redirect'))

  useEffect(() => {
    if (redirect) {
      window.location.href = STORE_URL
    }
  }, [redirect])

  if (redirect) {
    return null
  }

  return (
    <Layout
      leftContent={() => (
        <DownloadContent googleURL={GOOGLE_URL} appleURL={APPLE_URL} />
      )}
      rigthContent={BannerContent}
    />
  )
}
export default DownloadApp
