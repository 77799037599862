import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import SectionTab from './SectionTab'
import { useTranslation } from 'react-i18next'
// import {
//   getMorePostsByUserId,
//   getPostsByUserId,
// } from '../../redux/action/userAction/userAction'
import Post from '@/Components/Post/Post'
import useInfiniteScrolling from '@/Hooks/useInfiniteScrolling'
import { useDispatch, useSelector } from 'react-redux'
import { useProfileContext } from './Contexts/ProfileContext'
import { useTheme } from '@/Hooks'
import { LoaderButton } from '../LoaderButton'
import { useGetPostsByUserIdQuery } from '@/Services/modules/posts'
import { Spinner } from '../Spinner'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const Line = styled.div`
  ${({ theme }) => css`
    border-bottom: 2px solid ${theme.colors.grey1};
    width: 100%;
  `}
`
const Separator = () => <Line />

export const ProfileNews = () => {
  const { profile } = useProfileContext()
  const { theme } = useTheme()
  const { t } = useTranslation()
  const [lastDate, setLastDate] = useState(Date.now())
  const options = {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  }
  const { data: posts, isLoading } = useGetPostsByUserIdQuery(
    {
      lastDate,
      userId: profile.uid,
    },
    options,
  )

  const postsAreFromAnotherUser = useMemo(() => {
    return posts?.some(post => post.userId !== profile.uid)
  }, [posts, profile.uid])

  return (
    <>
      <Wrapper>
        <SectionTab title={t('previewpage.news_tab_Publications')}>
          {!postsAreFromAnotherUser && !isLoading && (
            <Posts
              posts={posts}
              onEndReached={() =>
                setLastDate(posts?.[posts.length - 1]?.createdAt)
              }
            />
          )}
        </SectionTab>
      </Wrapper>
    </>
  )
}

const Posts = ({ posts, onEndReached, isLoading }) => {
  useInfiniteScrolling({ isLoading, onEndReached })
  const filteredPosts = posts?.filter(post => !post.deleted)
  const { t } = useTranslation()

  if (!filteredPosts?.length) {
    return (
      <span className="text-center text-grey2">
        {t('previewpage.no_publications')}
      </span>
    )
  }

  return (
    <>
      {filteredPosts.map(post => {
        return (
          <div key={post.id}>
            <Post post={post} />
            <Separator />
          </div>
        )
      })}
      <Spinner className="mt-3" size={24} loading={isLoading} />
    </>
  )
}
