const signup = {
  title_page: 'Créer un compte',
  create_your_account: 'Créez votre compte',
  join_the_community: 'Entrez dans la communauté Budly',
  inputs: {
    email: 'Votre e-mail',
    email_address_is_invalid: " L'adresse e-mail n'est pas valide",
    password: 'Votre mot de passe',
    confirm: 'Confirmer votre mot de passe',
  },
  others: {
    continue: 'Continuer',
    agree: 'En vous inscrivant, vous acceptez nos',
    terms: "conditions d'utilisation",
    and: ' et notre',
    privacy: 'politique de confidentialité',
    register: "S'inscrire",
  },
  manually: 'Ajouter manuellement',
  registration_not_allowed: "Vous n'êtes pas autorisé à vous inscrire.",
}

export default signup
