const modal = {
  header: {
    all: 'Tous',
  },
  report: {
    title: 'Signaler ce post',
    modal_title: 'Êtes-vous sûr de vouloir signaler ce post ?',
  },
  loading: {
    title: 'Chargement ...',
  },
  modalPsw: {
    title: 'Modification du mot de passe',
    subtitle: 'Êtes-vous sûr de vouloir modifier votre mot de passe ?',
  },
  forgetPsw: {
    title: 'Mot de passe oublié',
    subtitle:
      'Entrez votre adresse e-mail pour réinitialiser votre mot de passe',
    email: 'Votre e-mail',
  },
  btn: {
    accept: 'Accepter',
    decline: 'Décliner',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    delete: 'Supprimer',
    back: 'Retour',
    finish: 'Terminer',
    gallery_only: 'Uniquement dans la galerie',
  },
  editPost: {
    edit_btn: 'Modifier ce post',
    remove_btn: 'Supprimer ce post',
  },
  deletepost: {
    title: 'Êtes-vous sûr de vouloir supprimer ce post ?',
  },
  publishPost: {
    create_you_post: 'Créer une publication',
    you_should_pay:
      'Pour partager votre publication sur votre fil d’actualité, vous devez dépenser une graine pour que les utilisateurs puissent l’arroser.',
    all_spent_seeds_publication:
      'Retrouvez toutes les graines dépensées par la mise en ligne de votre publication.',
    post_publish_price_title: 'Publication du post',
    description_publish_price_title: 'Description ({{length}} caractères)',
    hashtag_publish_price_title: 'Hashtag ({{length}})',
    image_publish_price_title: 'Image ({{length}})',
    total_publish_price_title: 'Total de dépense',
    plant_a_seed: 'Planter une graine',
    publish: 'Publier',
    get_seeds: 'Obtenir des graines',
    btn_photo: 'Photo',
    btn_AI: 'IA',
    btn_tag: 'Tag',
    change_confirmation: 'Confirmation de modification',
    are_you_sure: 'Voulez-vous vraiment éditer ce post ?',
    free_editing: 'Modification gratuite',
  },
  modal_ai_companion: {
    title: "Générer des images instantanément avec l'aide de votre compagnon !",
    subtitle:
      "Laissez-vous guider par votre compagnon dans la création d'images grâce à notre outil d'intelligence artificielle.",
    btn_discover_companions: 'Découvrir les compagnons',
    btn_not_now: 'Pas maintenant',
  },
  publish_gallery_in_post: "Publier votre photo dans le fil d'actualité",
  customColor: {
    title: 'Personnaliser votre couleur',
  },
  removeAccount: {
    title: 'Suppression de compte',
    subtitle:
      'Vous êtes sur le point de supprimer votre compte ainsi que toutes vos données.\n\nÊtes-vous sûr de vouloir effectuer cette action ?',
    input_confirm:
      'Pour confirmer, saisissez "SUPPRIMER MON COMPTE" dans le champ ci-dessous',
    toastErrorInvalidConfirmation: 'Le texte saisi est invalide',
    toastErrorFieldEmpty:
      'Champ vide. Vous devez saisir "SUPPRIMER MON COMPTE" pour confirmer la suppression',
  },
  removeImagePost: {
    title: "Suppression de l'image",
    subtitle:
      'Vous êtes sur le point de supprimer cette image.\n\nÊtes-vous sûr de vouloir effectuer cette action ?',
  },
  chooseThumbnail: {
    title: 'Modifier la miniature de votre contenu',
    btn: {
      perso: 'Miniature personnalisée',
      default: 'Miniature par défaut',
    },
  },
  editOrDeletePicture: {
    title: 'Modifier ou supprimer la photo',
    edit: 'Éditer la photo',
    delete: 'Supprimer la photo',
  },
  imageTypeSelection: {
    title: 'Personnaliser votre profil',
  },
  imageOriginSelection: {
    title: 'Télécharger votre image',
    choice1: {
      title: 'Téléchargez votre propre image',
      subtitle: 'Depuis votre téléphone, Facebook, Instagram ou Google Drive.',
    },
    choice2: {
      title: 'Photos par Unsplash',
      subtitle: 'Choisissez parmi plus de 500 000 images.',
    },
    choice3: {
      title: 'Arrière-plan animé',
      subtitle: 'Choisissez parmi plus de 100 arrière-plans animés.',
    },
    choice4: {
      title: 'Concevoir avec Canva',
      subtitle: 'Concevez votre propre image de couverture dans Canva.',
    },
    choice5: {
      title: 'Vidéos de Coverr',
      subtitle: 'Choisissez parmi plus de 100 000 vidéos.',
    },
    choice6: {
      title: 'Génération par IA',
      subtitle: 'Générez des images uniques avec l’aide de l’IA.',
    },
  },
  editProduct: {
    nfc_option: 'Relier votre profil à ce produit via NFC',
  },
  addContent: {
    label_phone: 'Votre numéro',
  },
  accountDisconnection: {
    title: 'Êtes-vous sûr de vouloir vous déconnecter de votre compte ?',
    subtitle:
      'La session active sera fermée et vous devrez vous reconnecter pour accéder à nouveau à votre compte.',
  },
  imageDeleteConfirmation: {
    title: 'Êtes-vous sûr de vouloir supprimer cette image ?',
    subtitle:
      "Cette action est irréversible et l'image sera définitivement supprimée de votre compte.",
  },
  contentDeleteConfirmation: {
    title: 'Êtes-vous sûr de vouloir supprimer ce contenu ?',
    subtitle:
      'Cette action est irréversible et le contenu sera définitivement supprimé de votre compte.',
  },
  postOptions: {
    title: 'Gérer les interactions avec ce post',
    subtitle:
      'En signalant ce post, vous nous aidez à maintenir une communauté respectueuse en nous permettant de modérer les publications. De plus, si vous souhaitez ne plus voir les contenus de cet utilisateur, vous pouvez choisir de le bloquer.',
  },
  blockedUser: {
    title: 'Êtes-vous sûr de vouloir bloquer cet utilisateur ?',
    subtitle: 'En bloquant cet utilisateur, vous ne verrez plus ses contenus.',
  },
  businessCardOption: {
    title: 'Options de carte',
    option1: 'Éditer ma carte',
    option2: 'Consulter ma carte',
    option3: 'Supprimer ma carte',
  },
  deleteBusinessCard: {
    title: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
  },
  checkPhoneNumber: {
    title: 'Vérifier votre numéro',
    subtitle:
      'Confirmez le code envoyé par SMS pour vérifier votre numéro de téléphone. Votre compte pourra ensuite prétendre à devenir certifié.',
    btnLabel1: 'Envoyer un code',
    btnLabel2: 'Confirmer',
    modalBtnLabel: 'Vérifier',
    placeholder: 'Entrez votre numéro de vérification',
  },
  sponsor: {
    title: 'Recherchez votre parrain',
    subtitle:
      "Trouvez votre parrain et bénéficiez d'un approvisionnement initial de ressources, de graines, de pièces et de gouttes d'eau pour vous aider à démarrer votre voyage dans le monde de Budly.",
    placeholder: 'Entrez le nom de votre parrain',
  },
  insufficientResource: {
    title: 'Ressource insuffisante',
    subtitle:
      'Il semble que vous n’ayez pas assez de ressources pour effectuer cette action.',
    btnLabelVisitStore: 'Visiter la boutique',
  },
  doubleEarnings: {
    title_double_your_earnings: 'Doublez vos gains ?',
    subtitle_play_with_your_companion:
      'Jouez avec votre compagnon pour multiplier tous les gains durant la partie par 2. En contrepartie, vous utiliserez 25% de la vitalité de votre compagnon à chaque partie.',
    btn_play_with_compagnon: 'Jouer avec mon compagnon',
    btn_play_without_compagnon: 'Jouer sans mon compagnon',
  },
  generated_style: {
    image_generation: "Génération d'image par IA",
    pay_and_generate: 'Payer et générer',
    generate_an_image: 'Générer une image',
    spend_coins: 'Dépensez {{price}} pièces pour pouvoir générer votre image.',
    generate_the_image: "Générer l'image",
  },
  re_generated: {
    regeneration: 'Régénération',
    Pay_and_regenerate: 'Payer et régénérer',
    Regenerate_variation: 'Régénérer une variation',
    spend_one_coins:
      'Dépensez {{price}} pièces pour pouvoir régénérer une variation de votre image.',
  },
  buy_offer: {
    learn_more: 'En savoir plus sur l’offre',
    spend_coins: 'Dépenser {{price}} pièces',
  },
  steps_reward: {
    steps: 'pas',
    steps_on: ' le ',
    validated_steps: '{{steps}} pas validés',
    acquired_coins: '{{coins}} pièces obtenus !',
    receive_coins_every_day: 'Recevez quotidiennement des pièces en marchant.',
    every_day_steps:
      'Chaque jour, votre compteur de pas est réinitialisé à minuit. Pour ne pas perdre vos pièces du jour, n’oubliez pas de valider vos pas avant la fin de journée',
  },
  deactivateMember: {
    deactivate_selected_member: 'Désactiver le membre sélectionné',
    reactivate_this_member: 'Réactiver ce membre',
    by_deactivating_this_member:
      "En désactivant ce membre, son compte sera suspendu et il ne pourra plus accéder à l'application. De ce fait, son profil ne sera plus visible pour les autres utilisateurs.",
    by_reactivating_this_member:
      "En réactivant ce membre, son accès à l'application sera restauré, lui permettant de se reconnecter. De plus, son profil redeviendra visible pour les autres utilisateurs.",
    disable_state: 'Désactivé',
    disable: 'Désactiver',
    reactivate: 'Réactiver',
    your_account_has_been_deactivated:
      "Votre compte a été désactivé par l'administrateur.",
    the_member_has_been_succesfully: 'Le membre a été désactivé avec succès.',
    the_member_has_been_reactivated: 'Le membre a été réactivé avec succès.',
  },
  addToContact: {
    share_this_profile: 'Partager ce profil',
  },
  socialMedia: {
    share_on_your_favorite: 'Partagez sur vos réseaux sociaux favoris',
    share_on_facebook: 'Partagez sur Facebook',
    share_on_linkedin: 'Partagez sur LinkedIn',
    share_on_twitter: 'Partagez sur Twitter',
    share_on_whatsapp: 'Partagez sur WhatsApp',
    share_on_telegram: 'Partagez sur Telegram',
    share_via_email: 'Partagez via Email',
  },
  member: {
    delete_profile: 'Supprimer ce profil',
    delete_profile_subtitle:
      'Supprimer toutes les informations relatives à ce profil de manière permanente.',
    role_change: 'Changement de rôle',
    role_change_subtitle:
      'Changer le rôle d’un membre de l’équipe entraîne une modification de ses droits dans l’application Budly.',
    duplicate_profile: 'Profil à dupliquer',
    old_profile: 'Ancien profil',
    new_profile: 'Nouveau profil',
  },
}

export default modal
