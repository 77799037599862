const casino = {
  coins_left: 'Pièces restantes',
  slots_budly: {
    no_free_slot: 'Vous n’avez plus de tirage gratuit pour aujourd’hui',
    in_progress: 'EN COURS',
    spin: 'LANCER',
    starting_bet: 'Mise de départ',
    win_multiple: 'Multiple de gain',
    each_spin_offers_a_chance_to_win: 'Chaque tour offre une chance de gagner!',
    ready_go_jackpot: 'Prêt, partez, jackpot!',
    hit_the_jackpot_today: 'Remportez le jackpot aujourd’hui!',
    start_a_new_game: 'Commencer une nouvelle partie.',
    no_gain_this_time: 'Aucun gain cette fois-ci.',
    its_not_your_lucky_day: "Ce n'est pas votre jour de chance.",
    luck_was_not_on_your_side: 'La chance n’était pas de votre côté.',
    you_have_won: 'Vous avez gagné!',
    well_done_you_won: 'Bien joué, vous avez gagné!',
    absolute_triumph: 'Triomphe absolu!',
    luck_is_on_your_side: 'La chance est de votre côté!',
    small_bundle_of_resources: 'Petit lot de ressources',
    large_bundle_of_resources: 'Grand lot de ressources',
    jackpot: 'Jackpot',
    turn_of_luck: 'Tour de chance',
    a_large_bundle_of_resources_garanteed:
      'Un grand lot de ressources garanti tous les {{frequency}} tirages',
    chance_of_winning: 'Chance de gagner',
    multiple: 'Multiplicateur',
    all_multiples: 'Tous les multiplicateurs',
    try_your_luck:
      'Tentez votre chance à la machine à sous de Budly pour accumuler un maximum de ressources!',
    winning_percentage_details: 'Détails des pourcentages de gains',
    chances_of_winning_increase_by:
      'En plus de multiplier vos gains, les multiples supplémentaires augmentent vos chances de gain de {{increase}}',
    gains: 'Gains',
    for_each_resources: '{{quantity}} pour chaque ressource',
  },
}


export default casino
