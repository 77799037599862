const activitypage = {
  casino: {
    remaining_free_slot: ' 1 free draw remaining',
    tabName: 'Casino',
    title: 'Budly Slots',
    description:
      'Play to accumulate a maximum of Budly resources usable for planting, watering and a multitude of actions in the application',
    possible_gains_by_game: 'Possible gains by game',
    costs_of_multiple_gains_per_game: 'Costs of multiple gains per game',
    bet: 'Bet',
    multiple: 'multiple',
    play_free: 'Play for free',
  },
  tab_games: 'Games',
  title_tab_bottom: 'Play',
  quests: 'Quests',
  games: 'Games',
  leaderboard: 'Leaderboard',
  experience: 'Experience',
  planted: 'Planted',
  planted_plural: 'Planted',
  streak: 'Streak',
  streak_unit: 'consecutive day',
  streak_unit_plural: 'consecutive days',
  plantations: 'Plantations',
  claim_your_daily_rewards: 'Claim your daily resources',
  claim_your_daily_reward: 'Claim your daily {{ressource}}',
  claim_multi: 'Claim my resources',
  claim: 'Claim my {{resource}}',
  claimed: 'Next {{resource}} in ',
  claimed_multi: 'Next resources in',
  come_every_days: 'Come back every day to claim 1 {{ressource}} for free',
  come_every_days_multi:
    'Come back every day to collect your daily resources for free',
  my_daily_quests: 'My daily quests',
  my_permanent_quests: 'My permanent quests',
  welcome_quests: 'Welcome quests',
  remaining: 'remaining',
  seed: 'seed',
  exp: 'exp',
  drop: 'drop',
  coin: 'coin',
  ticket: 'ticket',
  level_short: 'lvl',
  plant_trees: 'Plant your trees',
  add_seeds_to_plant_trees: 'Add seeds to plant trees',
  add_seeds: 'Add {{quantity}} seeds',
  plant_your_tree: 'Plant your tree',
  complete_welcome_quests_to_unlock: 'Complete welcome quests to unlock',
  daily_quests: 'daily quests',
  permanent_quests: 'permanent quests',
  planted_trees: 'planted tree{{plurial}}',
  planted_trees_subtitle: 'Find all your planted trees.',
  planted_on: 'Planted on',
  participants_number: 'participants',
  seeds_number: 'seeds',
  drops_number: 'drops',
  planted_by_you: 'By you',
  user_plantations: "{{userName}}'s plantations",
  modalSelectResourceQuantity: {
    title: 'Select the quantity of your resource',
    subtitle: 'Choose how much resource you want to spend.',
  },
  quantity: 'Quantity',
  collective: 'Collective ({{quantity}})',
  individual: 'Individual ({{quantity}})',
  step_history: 'Step history',
  step_toasters: {
    info: {
      text1: 'Budly needs access to your health data',
      text2: 'Please enable it in your settings',
    },
    error: {
      text1: 'Error getting steps data',
      text2: 'Please try again later',
    },
    warning: {
      text1: 'No steps found',
      text2: 'Please make sure you have steps data or permissions enabled',
    },
    banner: {
      title: 'You must have the {{appName}} app and enable permissions',
      subtitle:
        'Click here to download {{appName}} then come back to enable permissions',
    },
  },
}


export default activitypage
