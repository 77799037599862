import {
  useGetUserProgressDetailsQuery,
  useGetUserResourcesGainedByTypeQuery,
} from '@/Services/modules/resourceGains'
import { useGetStreaksQuery } from '@/Services/modules/streaks'
import { useGetDetailsTreesPlantByUserQuery } from '@/Services/modules/trees'
import { useMemo } from 'react'

const options = {
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnWindowFocus: true,
}

export const useGetUserActivity = uid => {
  const { data: userTrees, isLoading: userTreesIsLoading } =
    useGetDetailsTreesPlantByUserQuery(uid)
  const { data: seedsEarned, isLoading: seedsEarnedIsLoading } =
    useGetUserResourcesGainedByTypeQuery({
      uid: uid,
      type: 'seed',
    })
  const { data: streaks, isLoading: streaksIsLoading } = useGetStreaksQuery(uid)
  const { data: progressDetails, isLoading: progressDetailsIsLoading } =
    useGetUserProgressDetailsQuery(uid, options)
  const queriesCompleted = useMemo(
    () =>
      !userTreesIsLoading &&
      !seedsEarnedIsLoading &&
      !streaksIsLoading &&
      !progressDetailsIsLoading,
    [
      progressDetailsIsLoading,
      seedsEarnedIsLoading,
      streaksIsLoading,
      userTreesIsLoading,
    ],
  )

  const userActivity = useMemo(() => {
    if (!queriesCompleted) {
      return {}
    }

    return {
      ...userTrees,
      ...seedsEarned,
      ...streaks,
      ...progressDetails,
    }
  }, [progressDetails, seedsEarned, streaks, userTrees, queriesCompleted])

  return { userActivity, queriesCompleted }
}
