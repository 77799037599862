const PORT = '192.168.1.35'
//const PORT = '192.168.1.23'

export const Config = {
  API_URL: 'https://europe-west3-gopopme-7353a.cloudfunctions.net/api2',
  API_URL_DEV: `http://${PORT}:5001/gopopme-7353a/europe-west3/api2`,
  WEB3_API_URL: 'https://api.gopop.me',
  NAME: 'Budly',
  SLUG: 'gpm',
  CUSTOM_DOMAIN: 'www.budly.link',
  ADMIN_UID: 'd2OM80yi4VgNMBUP4ExTlsjAPNI3',
  FILESTACK_API_KEY: 'AkwiaSgsTbuLq3zgwxKlgz',
  BEACONSTAC_API_KEY: '6b2ed0a1c4dfa8f2ac155b433efea08bbb91ae31',
  BEACONSTAC_ORGANIZATION: '126214',
  SEQUENCE_PROJECT_ACCESS_KEY: "AQAAAAAAAFLSWTEjtLt4s24Naqnw1H1RopA",
  WALLET_CONNECT_PROJECT_ID: "d91f0b125061e5d62c4233e15e14f6e5",
  USDC_ADDRESS: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
}
