// import ModalDrops from '../Modals/ModalDrops'
import { Pill } from './Pill'
import React, { useState } from 'react'
import ModalDrops from '../Modals/ModalDrops'
import { useTheme } from '@/Hooks'
import { Waterdrop } from '@/Assets/icons/components'

const ModalDropsButton = ({ drops, ...props }) => {
  const { theme } = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <ModalDrops
        isOpen={isModalOpen}
        action={setIsModalOpen}
        drops={drops}
        {...props}
      />
      <Pill
        disabled={!Boolean(drops.length)}
        className="mr-1 cursor-pointer hover:opacity-75"
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <Waterdrop className="mr-1" />
        <span>{drops.length}</span>
        <span color={theme.colors.grey2}>/100</span>
      </Pill>
    </>
  )
}

export default ModalDropsButton
