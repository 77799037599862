import { useCosmeticMembers } from '@/Components/Team/Contexts/CosmeticMembersContext'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])
  const memberCosmetics = useCosmeticMembers()
  const [isCartOpen, setIsCartOpen] = useState(false)

  const handleAddItemToCart = useCallback(
    item => {
      setCart(current => [...current.filter(i => i.type !== item.type), item])
    },
    [setCart],
  )

  const handleRemoveItemFromCart = useCallback(type => {
    setCart(current => current.filter(i => i.type !== type))
  }, [])

  const handleClearCart = useCallback(() => {
    setCart([])
  }, [])

  const isItemAcquiredByAllMembers = item => {
    if (memberCosmetics.length > 1 && item.label !== 'default') {
      return !memberCosmetics?.every(member =>
        member.acquiredCosmeticIds.includes(item?.id),
      )
    }
    return !item.isAcquired
  }
  const unAcquiredItems = cart.filter(item => isItemAcquiredByAllMembers(item))

  const value = useMemo(() => {
    return {
      cart,
      isCartOpen,
      setIsCartOpen,
      addItemToCart: handleAddItemToCart,
      removeItemFromCart: handleRemoveItemFromCart,
      clearCart: handleClearCart,
      unAcquiredItems,
    }
  }, [
    cart,
    isCartOpen,
    setIsCartOpen,
    handleAddItemToCart,
    handleRemoveItemFromCart,
    handleClearCart,
    unAcquiredItems,
  ])

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export function useCartContext() {
  return useContext(CartContext)
}
