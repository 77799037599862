const generate_style_page = {
  image_generation: "Génération de l'image",
  regenerate: 'Re-générer',
  edit: 'Éditer',
  download: 'Télécharger',
  validate: 'Valider',
  image_downloaded: 'Image téléchargée avec succès',
  companion_is_generating: 'Un peu de patience... Je génère ton image !',
  generation_done: 'Et voilà le résultat ! Ça te plait ?',
  good_to_know: 'Bon à savoir',
  good_to_know_texts: {
    1: 'Tu as déjà tenté ta chance sur la machine à sous ? On dit que certains ont gagné le jackpot !',
    2: "Savais-tu qu'en atteignant 100 likes sur un post, un arbre est planté ? Fais le bien tout en partageant tes moments préférés !",
    3: 'Connais-tu notre feature exclusive de cartes de visite numériques ? Crée et partage la tienne pour te démarquer dans le réseau !',
    4: "Le Budly Mall arrive bientôt ! Attends-toi à des réductions incroyables sur les grandes marques. N'oublie pas de t'inscrire pour être le premier informé !",
    5: "Aimes-tu les défis ? Rejoins nos classements de jeux et montre à tous qui est le véritable champion dans l'app !",
    6: 'Recueille des graines et des gouttes pour accéder à des contenus spéciaux ou pour avancer plus vite dans nos jeux. Chaque interaction compte !',
    7: 'Transforme tes interactions en impact positif : chaque fois que tu utilises notre app, tu contribues à des initiatives écoresponsables.',
    8: "Découvre nos packs exclusifs dans la boutique : achète des graines, des pièces, des gouttes ou des tickets et accélère ta progression dans l'app !",
    9: "Tous les jours, tu as la possibilité de récupérer tes ressources journalières. N'oublie pas de te connecter pour ne pas les manquer !",
    10: 'Avec chaque ami que tu parraines, tu gagnes des ressources précieuses. Plus tu invites, plus tu gagnes, alors partage ton code de parrainage dès maintenant !',
    11: 'Participe à nos quêtes quotidiennes pour accumuler encore plus de graines et de pièces. Chaque jour apporte de nouveaux défis et de nouvelles récompenses !',
    12: "As-tu déjà visionné une publicité pour obtenir des ressources gratuites aujourd'hui ? Regarde une pub et gagne des gouttes instantanément !",
    13: 'Tu savais que tu peux suivre tes statistiques en temps réel dans notre app ? Découvre qui regarde tes posts et qui interagit avec eux !',
    14: "Chaque post aimé rapproche notre communauté de la prochaine plantation d'arbre. Participe activement à notre mission verte !",
    15: 'Utilise nos pièces pour jouer dans les jeux ou pour obtenir des avantages exclusifs. Plus tu en as, plus tu peux faire !',
    16: 'Découvre de nouvelles façons de gagner des récompenses chaque jour. Visite notre section des défis et commence à cumuler les avantages maintenant !',
  },
}

export default generate_style_page
