const games = {
  play: 'Play',
  score: 'Score',
  point: 'point',
  the: 'the',
  game_historical: 'History of your games',
  gain_per_game: 'Gains per game',
  cost_per_game: 'Costs per game',
  final_score: 'Final score',
  game_over: 'Game over ?',
  game_over2: 'Game over',
  you_are_about_to_leave:
    'You are about to leave the current game.\nYour score and winnings will be lost.',
  leave: 'Leave',
  yes: 'Yes',
  no: 'No',
  continue: 'Continue',
  you: 'You',
  no_score_found: 'No score found for this game.',
  no_ranking: 'No ranking found.',
  out_ranking: 'You are not part of the top 100.',
  no_ranking_subtitle: 'Become the first to score on this game!',
  experience_gained: 'Experience gained',
  rewards: 'Rewards',
  volume: 'Volume',
  sound_effects: 'Sound effects',
  music: 'Music',
  replay: 'Replay',
  tired_companion: 'Tired companion',
  too_tired_to_double:
    'It seems that your companion is too tired to continue doubling your winnings.',
  replay_without_companion: 'Do you want to play again without him?',
  no_ticket: 'No ticket',
  you_do_not_have_any_tickets:
    'You do not have any tickets in your possession.',
  get_in_shop: 'You can pick it up immediately in the store.',
  new_game: 'New game ?',
  you_will_use_resources: 'By restarting a game, you will use your resources:',
  vitality_of_companion: '25% vitality of your companion',
  ticket: 'ticket',
  remaining: 'Tries',
  level: 'Level',
  remaining_time: 'Time',
  king_budlies: {
    description:
      'Achieve the highest score by merging the Budlies together! Rumor has it that some have summoned the Budly king!',
  },
  find_budlies: {
    description:
      "Put your observation and memorization skills to the test by tracing the paths of Budlies. These adorable creatures hide among the boxes, and it's up to you to piece together their original path. Each level presents a unique challenge, with increasingly complex courses to reconstruct. Can you take up the challenge and become a real Budlie detective?",
  },
  budmories: {
    description:
      'Put your memory to the test by discovering the matching pairs! Do you dare to take on the challenge and become Budmories’s master?',
  },
  game_paid_success: 'Game paid successfully',
  subtitle_welcome_quests_banner: 'To play mini-games',
  subtitle_welcome_quests_banner_business: 'To switch to business profile',
  subtitle_welcome_quests_banner_post: 'To create a post',
  subtitle_welcome_quests_banner_watered: 'To water a post',
  subtitle_welcome_quests_banner_profile: 'To interact with a profile',
  cumulated_rewards: 'Cumulated rewards',
  seeds_rewarded: 'Seeds obtained',
  drops_rewarded: 'Drops obtained',
  coins_rewarded: 'Coins obtained',
  ticket_rewarded: 'Tickets obtained',
  no_lives_left: 'No lives left',
  spend_coins_to_revive:
    'To continue your game, you must spend coins to recover 1 life.',
  one_new_life: '1 new life',
}


export default games
