import ModalBusinessCardOptions from '@/Components/Modals/ModalBusinessCardOptions'
import { useGetBusinessCardsByUidQuery } from '@/Services/modules/businessCards'
import { useTranslation } from 'react-i18next'
import BusinessCardOption from './BusinessCardOption'
import Headings from '../../Headings'
import { Button } from '@/Components/Buttons/Button'
import { useEditBusinessCard } from '@/Components/Dashboard/Contexts/EditBusinessCardContext'
import { useCartContext } from '@/Components/Dashboard/Contexts/CartContext'
import { useEffect, useMemo } from 'react'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'
import { LockedFieldSwitch } from '@/Components/LockedFieldSwitch'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import { toast } from 'react-toastify'

const BusinessCardList = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { currentMember, isMultiMemberEdition } = useCurrentMember()
  const user = currentMember || currentUser
  const { data: businessCards = [] } = useGetBusinessCardsByUidQuery({
    userId: user.uid,
  })
  const { defaultBusinessCard, setSelectedBusinessCard } = useEditBusinessCard()
  const { cart, clearCart } = useCartContext()
  const { isAllTabFieldLocked, LOCKED_FIELDS } = useCurrentMember()
  const { setEditCurrentUser, editCurrentUser, onSubmit } = useEditCurrentUser()

  const isLocked = useMemo(() => {
    return isAllTabFieldLocked(
      LOCKED_FIELDS.businessCard,
      editCurrentUser?.lockedFields?.businessCard,
    )
  }, [
    LOCKED_FIELDS.businessCard,
    editCurrentUser?.lockedFields?.businessCard,
    isAllTabFieldLocked,
  ])

  const handleLockAllFields = () => {
    const tabFieldsLock = isLocked ? [] : LOCKED_FIELDS.businessCard
    setEditCurrentUser(prev => {
      return {
        ...prev,
        lockedFields: {
          ...prev.lockedFields,
          businessCard: tabFieldsLock,
        },
      }
    })
  }
  useEffect(() => {
    if (cart.length) {
      clearCart()
    }
  }, [cart, clearCart])
  const isBusinessCardModificationLocked =
    user?.lockedFields?.businessCard?.length !==
    editCurrentUser?.lockedFields?.businessCard?.length

  const buttonLabel = isBusinessCardModificationLocked
    ? t('others.submit')
    : t('businessCard.btn_add')
  const onOpenEditBusinessCard = setIsOpen => {
    if (isMultiMemberEdition) {
     toast.warning(t('businessCard.in_multiple_edit_mode'))
     return 
    }
    setIsOpen(true)
  }
    const submitOrCreateBusinessCard = isBusinessCardModificationLocked
      ? () => onSubmit()
      : () => setSelectedBusinessCard(defaultBusinessCard)
    return (
      <div className="px-1">
        <Headings
          title={t('businessCard.title')}
          subtitle={
            !businessCards.length
              ? t('businessCard.subtitle1')
              : t('businessCard.subtitle2')
          }
          renderComponent={() => (
            <LockedFieldSwitch
              label={t('businessCard.block_all_fields')}
              onChange={handleLockAllFields}
              isLocked={isLocked}
            />
          )}
        />

        <div className="pb-32">
          <Button
            label={buttonLabel}
            onClick={submitOrCreateBusinessCard}
            className="w-full"
            disabled={isLocked && !currentMember}
          />
          {businessCards.map((businessCard, index) => (
            <ModalBusinessCardOptions key={index} businessCard={businessCard}>
              {setIsOpen => (
                <BusinessCardOption
                  businessCard={businessCard}
                  onClick={() => onOpenEditBusinessCard(setIsOpen)}
                />
              )}
            </ModalBusinessCardOptions>
          ))}
        </div>
      </div>
    )
}

export default BusinessCardList
