import promiseRetry from 'promise-retry'
import { lazy } from 'react'

const importWithRetry = importComponent => {
  return () =>
    promiseRetry(
      retry => {
        return importComponent().catch(retry)
      },
      { retries: 3, minTimeout: 1000 },
    )
}

export const Home = lazy(
  importWithRetry(() => import('@/Containers/Home/Home')),
)
export const DashboardProfile = lazy(
  importWithRetry(() => import('@/Containers/Dashboard/Profile/Profile')),
)
export const DashboardReferral = lazy(
  importWithRetry(() => import('@/Containers/Dashboard/Referral/Referral')),
)
export const DashboardTeam = lazy(
  importWithRetry(() => import('@/Containers/Dashboard/Team/Team')),
)
export const Profile = lazy(
  importWithRetry(() => import('@/Containers/Profile/Profile')),
)
export const SignIn = lazy(
  importWithRetry(() => import('@/Containers/Auth/SignIn')),
)
export const SignUp = lazy(
  importWithRetry(() => import('@/Containers/Auth/SignUp')),
)
export const OnboardingPage = lazy(
  importWithRetry(() => import('@/Containers/Onboarding/OnBoardingPage')),
)
export const BusinessCard = lazy(
  importWithRetry(() => import('@/Containers/BusinessCard/BusinessCard')),
)
export const DownloadApp = lazy(
  importWithRetry(() => import('@/Containers/DownloadApp/DownloadApp')),
)

export const DashboardSettings = lazy(
  importWithRetry(() => import('@/Containers/Dashboard/Settings/Settings')),
)

export const DashboardStatistics = lazy(
  importWithRetry(() => import('@/Containers/Dashboard/Statistics/Statistics')),
)

export const Shop = lazy(
  importWithRetry(() => import('@/Containers/Shop/Shop')),
)

export const Verification = lazy(
  importWithRetry(() => import('@/Containers/Verification')),
)

export const ResetPinCode = lazy(
  importWithRetry(() => import('@/Containers/ResetPinCode')),
)
