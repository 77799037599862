import {
  LineGallery,
  LineLink,
  LineMessage4,
  LineTag,
  LineTwoUsers,
} from '@/Assets/icons/components'
import { useTranslation } from 'react-i18next'
import Contacts from './Contacts'
import SocialLinks from './SocialLinks'
import Links from './Links'
import Gallery from './Gallery'
import ShopRocket from './ShopRocket/ShopRocket'
// import Gallery from './Gallery'

const tabs = [
  {
    title: 'profilepage.contact.title_tab',
    name: 'contact',
    icon: LineMessage4,
    component: Contacts,
    className: 'text-xs',
    submit: true,
  },
  {
    title: 'profilepage.social.title_tab',
    name: 'social',
    icon: LineTwoUsers,
    component: SocialLinks,
    className: 'text-xs',
    submit: true,
  },
  {
    title: 'profilepage.link.title_tab',
    name: 'links',
    icon: LineLink,
    component: Links,
    className: 'text-xs',
    submit: true,
  },
  {
    title: 'profilepage.galerie.title_tab',
    name: 'gallery',
    icon: LineGallery,
    component: Gallery,
    className: 'text-xs',
    submit: true,
  },
  {
    title: 'Shop',
    name: 'shop',
    icon: LineTag,
    component: ShopRocket,
    className: 'text-xs',
    submit: false,
  },
]

const useGetTabs = () => {
  const { t } = useTranslation()
  return tabs.map(tab => {
    return {
      ...tab,
      title: t(tab.title),
    }
  })
}

export default useGetTabs
