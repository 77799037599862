import styled, { css } from 'styled-components'
import Text from './Texts/Text'
import { useTranslation } from 'react-i18next'
import CostBadge from './Activity/components/CostBadge'

const SelectColor = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.space.xs};
    border: ${({ selected }) =>
      selected ? `2px solid ${theme.colors.primary}` : `2px solid transparent`};
    border-radius: ${theme.borderRadius.xl};
    justify-content: space-between;
    align-items: center;
  `}
`
const ContainerColor = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.m};
    border-radius: ${theme.borderRadius.xxl};
    justify-content: center;
    align-items: center;
    background: ${({ bgOpacity }) => bgOpacity};
  `}
`
const Color = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.std};
    border-radius: ${theme.borderRadius.xl};
    background: ${({ bg }) => bg};
    aspect-ratio: 1;
  `}
`
const ColorText = styled(Text)`
  ${({ theme }) => css`
    margin-top: ${theme.space.xs};
    font-size: ${theme.fontSize.xs};
    font-weight: 500;
    color: ${({ selected }) =>
      selected ? theme.colors.primary : theme.colors.grey2};
  `}
`

const ColorItem = ({ item, selectedColor, usedColor, onAddToCart }) => {
  const { value: color, label, price, isAcquired } = item
  const { t } = useTranslation()

  return (
    <SelectColor
      selected={color.toLowerCase() === selectedColor.toLowerCase()}
      onClick={() => onAddToCart(item)}
    >
      <ContainerColor bgOpacity={color + '20'}>
        <Color bg={color} />
      </ContainerColor>
      <ColorText
        className="mb-2"
        selected={color.toLowerCase() === selectedColor.toLowerCase()}
      >
        {t(`edit_profil.appearance.color_theme.${label}`)}
      </ColorText>
      <CostBadge
        isAcquired={isAcquired}
        price={price}
        isUsed={color.toLowerCase() === usedColor}
      />
    </SelectColor>
  )
}

export default ColorItem
