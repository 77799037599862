import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import styled, { css } from 'styled-components'
import Polygon from './Polygon'
import { useCartContext } from '@/Components/Dashboard/Contexts/CartContext'
import CostBadge from '@/Components/Activity/components/CostBadge'

const AvatarFormSelected = styled.div`
  ${({ theme, selected, border, qrCodeFormValue }) => css`
    align-items: center;
    justify-content: center;
    border: ${selected
      ? `2px solid ${theme.colors.primary}`
      : `2px solid ${theme.colors.white}`};
    padding: ${theme.space.xxs};
    border-bottom-left-radius: ${border};
    border-bottom-right-radius: ${border};
    border-top-right-radius: ${border};
    border-top-left-radius: ${border};
  `}
`
const AvatarRoundShape = styled(TouchableOpacity)`
  ${({ theme, borderColor, bg }) => css`
    border: ${borderColor
      ? `1px solid ${borderColor}`
      : `1px solid ${theme.colors.black}`};
    width: 89px;
    height: 89px;
    /* padding: ${theme.space.xxl2} */
    border-radius: ${theme.borderRadius.xxl};
    background: ${bg ? bg : theme.colors.white};
  `}
`
const SquareShapeAvatar = styled(TouchableOpacity)`
  ${({ theme, borderColor, bg }) => css`
    border: ${borderColor
      ? `1px solid ${borderColor}`
      : `1px solid ${theme.colors.black}`};
    /* padding: ${theme.space.xxl2}; */
    width: 89px;
    height: 89px;
    background: ${bg ? bg : theme.colors.white};
  `}
`

const RoundedShapeAvatar = styled(TouchableOpacity)`
  ${({ theme, borderColor, bg }) => css`
    border: ${borderColor
      ? `1px solid ${borderColor}`
      : `1px solid ${theme.colors.black}`};
    /* padding: ${theme.space.xxl2}; */
    width: 89px;
    height: 89px;
    background: ${bg ? bg : theme.colors.white};
    border-radius: ${theme.borderRadius.l};
  `}
`

const Diamond = styled(TouchableOpacity)`
  ${({ theme, borderColor, bg }) => css`
    border: ${borderColor
      ? `1px solid ${borderColor}`
      : `1px solid ${theme.colors.black}`};
    margin: ${theme.space.std};
    padding: ${theme.space.xl};
    border-radius: ${theme.borderRadius.xxs};
    transform: rotate(45deg);
    background: ${bg ? bg : theme.colors.white};
  `}
`

const shapes = [
  {
    StyledShape: SquareShapeAvatar,
    value: 'square',
    border: '0px',
  },
  {
    StyledShape: AvatarRoundShape,
    value: 'circle',
    border: '44px',
  },
  {
    StyledShape: RoundedShapeAvatar,
    value: 'rounded',
    border: '24px',
  },
  {
    StyledShape: Diamond,
    value: 'kite',
    border: '0px',
  },
  {
    StyledShape: Polygon,
    value: 'right-diamond',
    border: '0px',
  },
  {
    StyledShape: Polygon,
    value: 'left-diamond',
    border: '0px',
  },
]
const QrCodeShapeChoice = ({
  selectedShape,
  usedShape,
  onChange,
  background,
  excludes = [],
  cosmetics = [],
}) => {
  const { addItemToCart } = useCartContext()
  const getCosmetic = _value => {
    const cosmetic = cosmetics.find(({ value }) => value === _value)
    return cosmetic || { price: 0, isAcquired: false }
  }

  const onClick = value => {
    const cosmetic = getCosmetic(value)
    addItemToCart(cosmetic)
    onChange(value)
  }

  return (
    <div className="w-full flex flex-row justify-between">
      {shapes
        .filter(({ value }) => !excludes.includes(value))
        .map(({ StyledShape, value, border }, index) => {
          const { price, isAcquired } = getCosmetic(value)
          return (
            <AvatarFormSelected
              key={index}
              qrCodeFormValue={selectedShape}
              selected={selectedShape === value}
              border={border}
            >
              <StyledShape
                bg={background}
                onClick={() => onClick(value)}
                rotate={value === 'left-diamond'}
              />
              <div className="mt-2">
                <CostBadge
                  price={price}
                  isAcquired={isAcquired}
                  isUsed={usedShape === value}
                />
              </div>
            </AvatarFormSelected>
          )
        })}
    </div>
  )
}

export default QrCodeShapeChoice
