import React from 'react'
import ColorPickerField from './ColorPickerField'
import CostBadge from './Activity/components/CostBadge'
import { useCosmetics } from './Dashboard/Contexts/CosmeticsContext'
import { useCartContext } from './Dashboard/Contexts/CartContext'

export const ColorPickerFieldPrice = ({ value, onChange, cosmeticName }) => {
  const cosmetics = useCosmetics()
  const { addItemToCart } = useCartContext()

  if (!cosmetics || !cosmetics[cosmeticName]) {
    return null
  }
  const cosmetic = cosmetics[cosmeticName][0]

  const handleAddCustomColor = color => {
    onChange(color)
    const isAddCustomColor = cosmetic.value !== color
    if (isAddCustomColor) {
      addItemToCart(cosmetic)
    }
  }

  return (
    <div className="flex">
      <div className="flex-1 items-center relative">
        <ColorPickerField value={value} onChange={handleAddCustomColor} />
        <div className="absolute right-4 top-2">
          <CostBadge
            isAcquired={cosmetic.isAcquired}
            isUsed={false}
            price={cosmetic.price}
          />
        </div>
      </div>
    </div>
  )
}
