const getHighestLevelCompanion = build =>
  build.query({
    query: uid => {
      return {
        url: `/users/${uid}/get-highest-nft`,
        method: 'GET',
      }
    },
    providesTags: ['Companion'],
  })
export default getHighestLevelCompanion
