const signup = {
  title_page: 'Crear una cuenta',
  create_your_account: 'Crea tu cuenta',
  join_the_community: 'Únete a la comunidad de Budly',
  inputs: {
    email: 'Su email',
    email_address_is_invalid: 'La dirección de correo electrónico no es válida',
    password: 'Su contraseña',
    confirm: 'Confirmar la contraseña',
  },
  others: {
    continue: 'Seguir',
    agree: 'Al registrarte, aceptas nuestro',
    terms: 'condicións de uso',
    and: ' y nuestro',
    privacy: 'política de privacidad',
    register: 'Registrarse',
  },
  manually: 'Agregar manualmente',
  registration_not_allowed: 'No está autorizado para registrarse.',
}

export default signup
