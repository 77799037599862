import React from 'react'
import bannerDownloadApp from '../../Assets/images/downloadApp/content-dl-app.webp'
import { GlassComments } from './GlassComments'
import styled from 'styled-components'

const BgImage = styled.div`
  background-image: url(${props => props.imageUrl});
  background-size: cover;
`
export const BannerContent = () => {
  return (
    <BgImage
      className="h-full bg-primary rounded-3xl flex flex-col justify-end"
      imageUrl={bannerDownloadApp}
    >
      <GlassComments />
    </BgImage>
  )
}
