import { auth } from '@/Firebase/Auth'
import { signOutUser as authSignoutUser } from '@/Firebase/Auth'
import { setCurrentMember } from '@/Store/Member'
import { setCurrentUser, setUserChecked } from '@/Store/User'
import { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useListenerUser } from './useListenerUser'

export const useAuth = () => {
  const [authUser, setAuthUser] = useState(null)
  const [checked, setChecked] = useState(false)
  const timer = useRef()
  const dispatch = useDispatch()

  const signOutUser = useCallback(async () => {
    await authSignoutUser()
  }, [])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setAuthUser({
          uid: user.uid,
        })
      } else {
        setAuthUser(null)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    timer.current = setTimeout(() => {
      setChecked(true)
      dispatch(setUserChecked(true))
    }, 2000)

    return () => clearTimeout(timer.current)
  }, [dispatch])

  return { user: authUser, checked, signOutUser }
}

export const AuthProvider = ({ children }) => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const memberUserId = searchParams.get('member_user_id')
  const { currentUser } = useSelector(state => state.user)
  const { currentMember } = useSelector(state => state.member)
  const { user, checked } = useAuth()

  const onValidate = useCallback(
    data => {
      if (!data?.main_uid) {
        return true
      }

      if (!currentUser?.uid) {
        return false
      }

      return (
        data?.main_uid === currentUser.uid ||
        (data?.main_uid === currentUser.main_uid &&
          currentUser.memberRole === 'admin')
      )
    },
    [currentUser?.uid, currentUser?.main_uid, currentUser?.memberRole],
  )

  useListenerUser({
    uid: user?.uid,
    setUser: setCurrentUser,
    validate: onValidate,
  })
  useListenerUser({
    uid: memberUserId,
    setUser: setCurrentMember,
    validate: onValidate,
  })

  if (
    (memberUserId && currentMember?.uid !== memberUserId) ||
    (!checked && pathname.includes('dashboard') && !currentUser)
  ) {
    return null
  }

  return children
}
