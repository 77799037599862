import React from 'react'
import { Stepper } from '../Stepper'
import Text from '../Texts/Text'
import { useTheme } from '@/Hooks'
import { LineMessage5 } from '@/Assets/icons/components'
import { styled } from 'styled-components'
import clsx from 'clsx'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
`

export const IconLabel = ({ childrenClassName }) => {
  const { theme } = useTheme()
  return (
    <div className={clsx('flex flex-row items-center', childrenClassName)}>
      <img
        className="w-10 h-10 mr-2"
        src={require('../../Assets/images/logo.png')}
        alt="logo-budly"
      />
      <Text color={theme.colors.primary} className="text-2xl">
        Budly
      </Text>
    </div>
  )
}
const Footer = () => {
  const { theme } = useTheme()

  return (
    <div className="flex flex-row px-6 py-10 justify-between">
      <Text className="text-sm" regular="true" color={theme.colors.grey2}>
        © Budly {new Date().getFullYear()}
      </Text>
      <div className="flex flex-row items-center shrink flex-1 w-1/2 justify-end">
        <LineMessage5
          color={theme.colors.grey2}
          size={theme.size.xxs}
          viewBox="0 0 24 24"
        />
        <Text className="text-sm ml-2" regular color={theme.colors.grey2}>
          <a href="mailto:hi@getbudly.com" target="_blank" rel="noreferrer">
            hi@getbudly.com
          </a>
        </Text>
      </div>
    </div>
  )
}

export const Steps = () => {
  return (
    <Container className="h-full rounded-[40px] w-[392px] flex flex-col item-center justify-center px-4 pt-10">
      <IconLabel childrenClassName="mb-16 pl-6 tablet:mb-10" />
      <Stepper />
      <Footer />
    </Container>
  )
}
