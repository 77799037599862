import React from 'react'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import { HashtagFieldLock } from '@/Components/ControlsLocked/HashtagFieldLock'

const EditProfileHashtagField = ({ children, disabled, name }) => {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const handleChange = values => {
    setEditCurrentUser({
      ...editCurrentUser,
      hashtags: values.map(value =>
        value[0] !== '#' ? '#' + value.trim() : value.trim(),
      ),
    })
  }
  return (
    <HashtagFieldLock
      hashtags={editCurrentUser?.hashtags || []}
      onChange={handleChange}
      disabled={disabled}
      name={name}
    >
      {children}
    </HashtagFieldLock>
  )
}

export default EditProfileHashtagField
