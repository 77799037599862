import { useState } from 'react'
import styled, { css } from 'styled-components'
import ModalImageOptions from './ModalImageOptions/ModalImageOptions'
import Text from '../Texts/Text'
import { useTheme } from '@/Hooks'
import { LineAdd, LineGallery, LineUser } from '@/Assets/icons/components'
import { useTranslation } from 'react-i18next'
import { Modal } from './ModalWrapper/Modal'
import { CosmeticsProvider } from '../Dashboard/Contexts/CosmeticsContext'
import { TouchableOpacity } from '../Buttons/TouchableOpacity'
import { useLockedField } from '@/Hooks/useLockedField'
import { LockedFieldSwitch } from '../LockedFieldSwitch'

const StyledChoice = styled(TouchableOpacity)`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    width: ${theme.size.fill};
    padding: ${theme.space.std};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${theme.space.xs};
  `}
`

const Choice = ({
  choice,
  setEditCurrentUser,
  editCurrentUser,
  clearCart = () => null,
  onDismiss,
  disabled,
  ...props
}) => {
  return (
    <ModalImageOptions
      onChange={value => {
        clearCart()
        setEditCurrentUser({
          ...editCurrentUser,
          ...value,
        })
      }}
      setEditCurrentUser={setEditCurrentUser}
      editCurrentUser={editCurrentUser}
      clearCart={clearCart}
      {...choice}
      {...props}
    >
      {setIsOpen => (
        <StyledChoice
          onClick={() => {
            setIsOpen(true)
            onDismiss()
          }}
          disabled={disabled}
        >
          <div className="pb-2">{choice.icon}</div>
          <Text>{choice.title}</Text>
        </StyledChoice>
      )}
    </ModalImageOptions>
  )
}

const ModalImageTypeSelection = ({
  children: button,
  onboarding,
  ...props
}) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { isFieldDisabled } = useLockedField()
  const { onLocked } = useLockedField()

  const choices = [
    {
      type: 'photo',
      title: t('edit_profil.informations.add_pp'),
      icon: <LineUser color={theme.colors.black} />,
      additionalOptions: {
        imageAI: true,
      },
      disabled: isFieldDisabled({ fieldName: 'photo' }),
    },
    {
      type: 'banner',
      title: t('edit_profil.informations.add_bg'),
      icon: <LineGallery color={theme.colors.black} />,
      ...(!onboarding && {
        additionalOptions: {
          unsplash: true,
          animatedBackground: true,
          canva: true,
          coverr: true,
          imageAI: true,
        },
      }),
      format: { width: 1280, height: 663 },
      disabled: isFieldDisabled({ fieldName: 'banner' }),
    },
    ...(!props.editCurrentUser.businessMode
      ? [
          {
            type: 'logo',
            title: t('edit_profil.informations.add_logo'),
            icon: <LineAdd color={theme.colors.black} />,
            additionalOptions: {
              imageAI: true,
            },
            disabled: isFieldDisabled({ fieldName: 'logo' }),
          },
        ]
      : []),
  ]

  return (
    <>
      {button(setIsOpen)}
      <Modal
        title={t('modal.imageTypeSelection.title')}
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <CosmeticsProvider>
          {choices.map((choice, index) => {
            const isLocked =
              props.editCurrentUser.lockedFields?.['user']?.includes(
                choice.type,
              ) || false
            return (
              <div className="flex flex-col items-end">
                <LockedFieldSwitch
                  isLocked={isLocked}
                  onChange={e =>
                    onLocked({
                      e,
                      type: 'user',
                      fieldName: choice.type,
                    })
                  }
                  className="mt-2"
                />
                <Choice
                  key={index}
                  onDismiss={() => setIsOpen(false)}
                  choice={choice}
                  disabled={isFieldDisabled({ fieldName: choice.type })}
                  {...props}
                />
              </div>
            )
          })}
        </CosmeticsProvider>
      </Modal>
    </>
  )
}

export default ModalImageTypeSelection
