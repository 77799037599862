const profilepage = {
  application_available: 'Aplicación disponible',
  download_app: 'Descargar',
  title_tab_bottom: 'Perfil',
  preview: 'Vista previa',
  at_home_bar: 'a',
  warning_email: 'Reenviar el correo electrónico de confirmación',
  my_profile: 'Mi perfil',
  advanced_options: 'Opciones avanzadas',
  instant_redirection: 'Redirección instantánea',
  activity: 'Actividad',
  add_content: 'Añadir contenido',
  add_image: 'Añadir una imagen',
  multiple_edition: 'Edición múltiple',
  contact: {
    tel_option: 'Teléfono',
    email_option: 'Email',
    address_option: 'Dirección',
    title_tab: 'Contacto',
    top: {
      sentence: 'Que tenga un buen día',
      addcontact: 'Agregar un nuevo contacto',
    },
    bottom: {
      visible_all: 'Visible para todos',
      visible_contact_only: 'Visible solo por mis contactos',
    },
    contact_only: {
      adresse: 'Mi dirección',
      phone_work: 'Telefono de oficina',
    },
  },
  social: {
    title_tab: 'Social',
    add_social: 'Agregar una nueva red social',
    add_social2: 'Agregar una nueva red',
    my_social_network: 'Mis redes sociales',
    search_network: 'Encuentra contenido nuevo',
  },
  link: {
    add_link: 'Agregar un nuevo enlace',
    my_links: 'Mis enlaces',
    title_tab: 'Enlaces',
    video_tab_show_on_profile: 'Mostrar en el perfil',
  },
  galerie: {
    title_tab: 'Galería',
    add_photo: 'Añadir una nueva foto',
    galerie: 'Mi galería',
    edit_photo: {
      add_hashtag: 'Agrega un hashtag',
      save: 'Para salvaguardar',
    },
    edit_image_panel_title: 'Editar una imagen',
    add_image_panel_title: 'Añadir una imagen',
  },
  shop: {
    create_shop: 'Crear mi tienda',
    create_shop_message:
      'La tienda integrada le permite crear fichas de productos y administrar sus pedidos en una plataforma dedicada. Los clientes podrán comprar sus productos directamente desde su perfil. La activación de este servicio requiere el pago de {{quantity}} monedas.',
    edit_shop: 'Editar mi tienda',
    enable_shop: 'Activar mi tienda',
    disable_shop: 'Desactivar mi tienda',
  },
}

export default profilepage
