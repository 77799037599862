import { Backdrop, Popover } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'
import { styled } from 'styled-components'
import { Button } from '../Buttons/Button'
import { TouchableOpacity } from '../Buttons/TouchableOpacity'
import { DefaultParent } from '../DefaultParent'
import Text from '../Texts/Text'

const Option = styled(TouchableOpacity)`
  border-bottom: ${({ isLast, theme }) =>
    isLast ? 'none' : `1px solid ${theme.colors.grey1}`};
  min-width: 124px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 8px;
`

const StyledMenuList = styled.div`
  background: ${({ theme }) => theme.colors.white};
`

const MenuList = ({ options = [], onClose, selected }) => {
  return (
    <StyledMenuList className="flex flex-col gap-1 p-2">
      {options.map(
        (
          {
            label,
            onClick,
            parent: Parent = DefaultParent,
            autoClose = true,
            ...option
          },
          index,
        ) => {
          const isSelected = selected ? selected.label === label : false
          const last = index === options.length - 1
          return (
            <Parent>
              {props => (
                <Option
                  key={label}
                  onClick={() => {
                    if (autoClose) {
                      onClose()
                    }
                    onClick(option, props)
                  }}
                  isLast={last}
                >
                  <Text
                    className={clsx('text-sm', isSelected && 'text-primary')}
                  >
                    {label}
                  </Text>
                </Option>
              )}
            </Parent>
          )
        },
      )}
    </StyledMenuList>
  )
}

const MenuPopover = ({
  children: button,
  position = 'left',
  options,
  selected,
  ...props
}) => {
  const open = Boolean(props.anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className="flex gap-1">
      {button({ id })}
      <Backdrop className="z-20" open={open} onClick={props.onClose} />
      <Popover
        {...props}
        id={id}
        open={open}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position,
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: position,
        }}
      >
        <MenuList
          onClose={props.onClose}
          options={options}
          selected={selected}
        />
      </Popover>
    </div>
  )
}

const Menu = ({ position, options, selected, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <MenuPopover
      position={position}
      options={options}
      anchorEl={anchorEl}
      onClose={handleClose}
      selected={selected}
    >
      {({ id }) => (
        <Button
          {...props}
          aria-describedby={id}
          onClick={event => {
            setAnchorEl(event.currentTarget)
          }}
        />
      )}
    </MenuPopover>
  )
}

export default Menu
