import React, { useEffect } from 'react'
import { Router } from './Router/Router'
import { ThemeProvider } from 'styled-components'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/ar'
import { useTranslation } from 'react-i18next'
import { useTheme } from './Hooks'
import './global.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-day-picker/dist/style.css'
import { Provider, useSelector } from 'react-redux'
import { store, persistor } from '@/Store'
import {
  ThemeProvider as MuiThemeProvider,
  useTheme as useMuiTheme,
  createTheme,
} from '@mui/material'
import { searchClient } from './algolia'
import { InstantSearch } from 'react-instantsearch'
import { IntercomProvider } from 'react-use-intercom'
import { PersistGate } from 'redux-persist/integration/react'
import { HelmetProvider } from 'react-helmet-async'

const INTERCOM_APP_ID = 'yw5w6w0j'

const Application = () => {
  const { theme } = useTheme()
  const muiTheme = useMuiTheme()
  const customMuiTheme = createTheme({
    ...muiTheme,
    palette: {
      ...muiTheme.palette,
      primary: {
        main: theme.colors.primary,
      },
    },
  })

  const language = useSelector(state => state.language.lang)
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(language)
    moment.locale(language)
  }, [language, i18n])

  return (
    <MuiThemeProvider theme={customMuiTheme}>
      <ThemeProvider theme={theme}>
        <Router />
        <ToastContainer theme="light" position="top-center" limit={3} />
      </ThemeProvider>
    </MuiThemeProvider>
  )
}
const App = () => {
  return (
    <HelmetProvider>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <InstantSearch searchClient={searchClient} indexName="users">
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Application />
            </PersistGate>
          </Provider>
        </InstantSearch>
      </IntercomProvider>
    </HelmetProvider>
  )
}

export default App
