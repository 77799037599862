import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import Form from '@/Components/Form/Form'
import React, { useMemo, useState } from 'react'
import { EditProfileBusinessSwitch } from '../../../Components/Dashboard/Profile/Informations/EditProfileBusinessSwitch'
import PrivateProfileSwitch from '@/Components/Dashboard/Profile/Informations/PrivateProfileSwitch'
import EditProfileHashtagField from '@/Components/Dashboard/Profile/Informations/EditProfileHashtagField'
import Text from '@/Components/Texts/Text'
import { useTranslation } from 'react-i18next'
import EditProfileBanner from '@/Components/Dashboard/Profile/Informations/EditProfileBanner'
import FormSubmitButton from '@/Components/Form/FormSubmitButton'
import Headings from '@/Components/Dashboard/Profile/Headings'
import { LockedFieldSwitch } from '@/Components/LockedFieldSwitch'
import { useLockedField } from '@/Hooks/useLockedField'
import { LabeledInputLock } from '@/Components/ControlsLocked/LabeledInputLock'
import { LabeledTextAreaLock } from '@/Components/ControlsLocked/LabeledTextAreaLock'
import useCurrentMember from '@/Hooks/useCurrentMember'

const TITLE = 'edit_profil.informations.title'
const SUBTITLE = 'edit_profil.informations.subtitle'

const getSectionFields = editCurrentUser => {
  return {
    'edit_profil.informations.my_infos.title': [
      ...(!editCurrentUser?.businessMode
        ? [
            {
              name: 'firstName',
              title: 'edit_profil.informations.my_infos.name',
              placeholder: 'edit_profil.informations.my_infos.name_placeholder',
            },
            {
              name: 'lastName',
              title: 'edit_profil.informations.my_infos.surname',
              placeholder:
                'edit_profil.informations.my_infos.surname_placeholder',
            },
            {
              name: 'job',
              title: 'edit_profil.informations.my_infos.job',
              placeholder: 'edit_profil.informations.my_infos.job_placeholder',
            },
          ]
        : []),
      {
        name: 'businessName',
        title: 'edit_profil.informations.my_infos.enterprise',
        placeholder: 'edit_profil.informations.my_infos.enterprise_placeholder',
      },
      {
        name: 'description',
        title: 'edit_profil.informations.my_infos.description',
        placeholder:
          'edit_profil.informations.my_infos.description_placeholder',
      },
    ],
    'edit_profil.informations.hashtags.title': [
      {
        name: 'hashtags',
        component: EditProfileHashtagField,
      },
    ],
    'edit_profil.informations.advanced_options.title': [
      {
        name: 'businessMode',
        component: EditProfileBusinessSwitch,
      },
      {
        name: 'privateMode',
        component: PrivateProfileSwitch,
      },
    ],
  }
}

const Informations = () => {
  const { t } = useTranslation()
  const { editCurrentUser, setEditCurrentUser, onSubmit } = useEditCurrentUser()
  const { isFieldDisabled } = useLockedField()
  const sectionFields = useMemo(
    () => getSectionFields(editCurrentUser),
    [editCurrentUser],
  )
  const { informationFields, isAllTabFieldLocked, currentMember } =
    useCurrentMember()
  const isLocked = useMemo(() => {
    return isAllTabFieldLocked(
      informationFields,
      editCurrentUser?.lockedFields?.user.filter(val =>
        informationFields.includes(val),
      ),
    )
  }, [
    editCurrentUser?.lockedFields?.user,
    informationFields,
    isAllTabFieldLocked,
  ])

  const handleLockAllFields = () => {
    const tabFieldsLock = isLocked ? [] : informationFields
    setEditCurrentUser(prev => {
      const updatedUserFields =
        prev?.lockedFields?.user.filter(
          field => !informationFields.includes(field),
        ) || []

      return {
        ...prev,
        lockedFields: {
          ...prev.lockedFields,
          user: [...updatedUserFields, ...tabFieldsLock],
        },
      }
    })
  }

  if (!editCurrentUser) {
    return null
  }

  return (
    <>
      <Headings
        title={t(TITLE)}
        subtitle={t(SUBTITLE)}
        renderComponent={() => (
          <LockedFieldSwitch
            label={t('businessCard.block_all_fields')}
            onChange={handleLockAllFields}
            isLocked={isLocked}
          />
        )}
      />
      <Form onSubmit={onSubmit} className="flex flex-col">
        {/* <FormAutoSubmit /> */}
        {/* <FormSaveIndicatorTeleporter.Source>
          <FormSaveIndicator />
        </FormSaveIndicatorTeleporter.Source> */}
        <div className="mb-6">
          <EditProfileBanner />
        </div>
        {Object.entries(sectionFields).map(([sectionName, fields]) => {
          return (
            <div key={sectionName} className="mt-2">
              <Text className="font-semibold text-base">{t(sectionName)}</Text>
              <div className="pt-3 pb-5">
                {fields.map(
                  ({ name, title, placeholder, component: CustomField }) => {
                    const disabled = isFieldDisabled({ fieldName: name })
                    if (CustomField) {
                      return (
                        <CustomField
                          key={name}
                          name={name}
                          disabled={disabled}
                        />
                      )
                    }

                    if (name === 'description') {
                      return (
                        <LabeledTextAreaLock
                          key={name}
                          label={t(title)}
                          description={editCurrentUser.description}
                          onChange={text =>
                            setEditCurrentUser(prev => {
                              return { ...prev, description: text }
                            })
                          }
                          maxLength={200}
                          disabled={disabled}
                          fieldName={name}
                        />

                        // <TextArea
                        //   key={name}
                        //   className="mb-3 mt-1"
                        //   placeholder={placeholder}
                        //   maxLength={400}
                        //   value={editCurrentUser[name] || ''}
                        //   onChange={value =>
                        //     setEditCurrentUser({
                        //       ...editCurrentUser,
                        //       [name]: value,
                        //     })
                        //   }
                        //   copyable
                        // />
                      )
                    }

                    return (
                      <LabeledInputLock
                        key={name}
                        fieldName={name}
                        title={t(title)}
                        className="mb-3"
                        placeholder={t(placeholder)}
                        value={editCurrentUser[name] || ''}
                        onChange={value =>
                          setEditCurrentUser({
                            ...editCurrentUser,
                            [name]: value,
                          })
                        }
                        regular
                        disabled={disabled}
                      />
                    )
                  },
                )}
              </div>
            </div>
          )
        })}
        <FormSubmitButton disabled={isLocked && !currentMember} />
      </Form>
    </>
  )
}

export default Informations
