import React from 'react'
import QrCodeShapeChoice from './components/QrCodeShapeChoice'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import Text from '@/Components/Texts/Text'
import { useTranslation } from 'react-i18next'
import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useSelector } from 'react-redux'
import { ColorPickerFieldPrice } from '@/Components/ColorPickerFieldPrice'

const PatternQrCode = () => {
  const { t } = useTranslation()
  const { currentUser } = useSelector(state => state.user)
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const { 'qr-pattern-shape': cosmetics } = useCosmetics()

  return (
    <div className="flex flex-col w-full gap-4">
      <Text className="mb-3">
        {t('edit_profil.appearance.shape_avatar.shapes')}
      </Text>
      <div className="mb-4">
        <QrCodeShapeChoice
          background={editCurrentUser.qrcode?.attributes.colorDark}
          onChange={shape =>
            setEditCurrentUser(prev => {
              let newEditCurrentUser = JSON.parse(JSON.stringify(prev))
              newEditCurrentUser.qrcode.attributes.dataPattern = shape
              return newEditCurrentUser
            })
          }
          selectedShape={editCurrentUser.qrcode?.attributes.dataPattern}
          usedShape={currentUser.qrcode?.attributes.dataPattern}
          excludes={['rounded', 'left-leaf', 'right-leaf']}
          cosmetics={cosmetics}
        />
      </div>
      <Text className="mb-3">
        {t('edit_profil.appearance.color_theme.color')}
      </Text>
      <ColorPickerFieldPrice
        value={editCurrentUser.qrcode?.attributes.colorDark}
        onChange={color =>
          setEditCurrentUser(prev => {
            let newEditCurrentUser = JSON.parse(JSON.stringify(prev))
            newEditCurrentUser.qrcode.attributes.colorDark = color
            return newEditCurrentUser
          })
        }
        cosmeticName="qr-background-color-shape-custom"
      />
    </div>
  )
}

export default PatternQrCode
