import React from 'react'
import { useTranslation } from 'react-i18next'
import Drops from '../Post/Drops'
import { Modal } from './ModalWrapper/Modal'

const ModalDrops = ({ drops, isOpen, loading, action, ...props }) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal
        isOpen={isOpen}
        loading={loading}
        onDismiss={() => action(false)}
        title={`${drops.length} ${t('others.participants_on_this_post', { plurial: drops.length > 1 ? 's' : '' })}`}
      >
        <Drops drops={drops} {...props} />
      </Modal>
    </>
  )
}

export default ModalDrops
