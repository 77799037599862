import { TopbarIdentity } from '../Profile/TopbarIdentity'
import React from 'react'

export const Header = ({ post, discover, hideOptions }) => {
  return (
    <div className="pb-3">
      <TopbarIdentity discover={discover} resource={post} />
    </div>
  )
}
