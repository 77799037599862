import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import Text from '@/Components/Texts/Text'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

const StyledBadge = styled.button`
  ${({ theme, missingResource }) => css`
    background-color: ${missingResource
      ? theme.colors.red + '20'
      : theme.colors.grey1};
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${theme.size.std};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.xs};
    height: 48px;
    border: ${missingResource ? `1px solid ${theme.colors.red} ` : 'none'};
  `}
`

const AddResourceBadge = ({
  quantity = 0,
  type,
  childrenClassName,
  children,
  format = v => v,
  resourcePrice,
}) => {
  const missingResource = useMemo(() => {
    if (!resourcePrice) {
      return false
    }

    return quantity < resourcePrice.price && resourcePrice.type.includes(type)
  }, [quantity, resourcePrice, type])
  const navigate = useNavigate()

  const handlePress = e => {
    if (resourcePrice) {
      return null
    }
    navigate(`/dashboard/shop/${type}`)
  }

  return (
    <StyledBadge
      type={type}
      missingResource={missingResource}
      className={childrenClassName}
      onClick={handlePress}
      disabled={resourcePrice ? true : false}
    >
      {children}
      <Text className="pl-1 pr-2 text-xs">{format(quantity)}</Text>
    </StyledBadge>
  )
}
export default AddResourceBadge
