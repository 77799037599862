import React from 'react'
import { LineSend } from '@/Assets/icons/components'
import { Popover } from '@mui/material'
import { OptionsMenu } from '../Profile/OptionsMenu'
import { Button } from '../Buttons/Button'
import { PlayerExperienceAvatar } from '../PlayerExperienceAvatar'
import PanelShareProfile from '../Panels/PanelShareProfile/PanelShareProfile'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledPopover = styled(Popover)`
  ${({ theme }) => css`
    .MuiPaper-root {
      background-color: ${theme.colors.white};
    }
  `}
`
export const OptionsMenuPopover = props => {
  const { t } = useTranslation()

  return (
    <StyledPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: -16,
        horizontal: 'right',
      }}
      {...props}
    >
      <div className="px-4 pt-6  pb-3 flex flex-col w-72">
        <PlayerExperienceAvatar />
        <PanelShareProfile>
          {setIsOpen => (
            <Button
              onClick={() => setIsOpen(true)}
              className="mb-6"
              variant="primary"
              icon={LineSend}
              label={t('share.share')}
            />
          )}
        </PanelShareProfile>
        <OptionsMenu />
      </div>
    </StyledPopover>
  )
}
