import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import { usePutCurrentUserMutation } from '@/Services/modules/users'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

export const RetryCreationSection = ({ user }) => {
  const { theme } = useTheme()
  const [putCurrentUser] = usePutCurrentUserMutation()
  const [loading, setLoading] = useState(false)

  const handleClearShop = async () => {
    setLoading(true)
    try {
      await putCurrentUser({
        body: {
          shopRocket: { alreadyPaid: true },
        },
        userId: user.uid,
      })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  if (!user?.shopRocket?.store_id) {
    return null
  }

  return (
    <div className="flex items-center justify-center mt-10 w-full">
      <Text color={theme.colors.grey2} className="text-sm text-center" regular>
        Vous n'arrivez plus à vous connecter à votre shop ?
        <TouchableOpacity disabled={loading} onClick={handleClearShop}>
          <Text color={theme.colors.primary} className="text-sm" regular>
            Recommencez la création de votre shop
          </Text>
        </TouchableOpacity>
      </Text>
    </div>
  )
}
