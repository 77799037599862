import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Text from '../../Texts/Text'
import { LineArrowRight } from '@/Assets/icons/components'
import { Modal } from '../ModalWrapper/Modal'
import {
  CosmeticsProvider,
  useCosmetics,
} from '../../Dashboard/Contexts/CosmeticsContext'
import { useState } from 'react'
import imageOptions from './ImageOptions'
import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import { useCartContext } from '@/Components/Dashboard/Contexts/CartContext'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'

const DefaultWrapper = ({ children }) => {
  return children
}

const StyledOption = styled(TouchableOpacity)`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    width: ${theme.size.fill};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.space.xs};
    padding: ${theme.space.std};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
  `}
`

const IconWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.size.std};
    aspect-ratio: 1;
    border-radius: 20px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  `}
`

const TextWrapper = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding: ${theme.space.none} ${theme.space.std};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`

const Option = ({
  destination,
  onDismiss,
  onChange,
  disabled,
  modalRef,
  option,
  format,
  parent: Parent,
  setEditCurrentUser = () => null,
  editCurrentUser = {},
}) => {
  const { theme } = useTheme()
  const { useGetOnClick, icon: Icon, title, subtitle } = option
  const { t } = useTranslation()
  const [isParentOpen, setIsParentOpen] = useState(false)
  const { banner } = useCosmetics()
  const { addItemToCart, clearCart, cart } = useCartContext() || {}
  const { editCurrentUser: editUser, setEditCurrentUser: setEditUser } =
    useEditCurrentUser() || {
      editCurrentUser,
      setEditCurrentUser,
    }

  const onClick = useGetOnClick({
    setIsParentOpen,
    destination,
    onDismiss,
    onChange,
    modalRef,
    format,
  })

  return (
    <>
      <Parent
        key={option.title}
        onChange={onChange}
        destination={destination}
        isOpen={isParentOpen}
        onDismiss={() => {
          setIsParentOpen(false)
          onDismiss()
        }}
        onSelected={(originType, value, id) => {
          const cosmetic = banner.find(c => c.label === option.type)
          if (!cosmetic.isAcquired.includes(id) && addItemToCart) {
            const formattedCosmetic = {
              ...cosmetic,
              id,
              cosmeticId: cosmetic.id,
              isAcquired: false,
            }
            addItemToCart(formattedCosmetic)
          }
          if (
            cosmetic.isAcquired.includes(id) &&
            cart?.length > 0 &&
            clearCart
          ) {
            clearCart()
          }

          setEditUser({
            ...editUser,
            [destination]: { type: originType, value },
          })
          setIsParentOpen(false)
        }}
        format={format}
      />
      <StyledOption disabled={disabled} onClick={onClick}>
        <IconWrapper>
          <Icon color={theme.colors.black} />
        </IconWrapper>
        <TextWrapper>
          <Text className="font-medium">{t(title)}</Text>
          <Text className="text-xs break-normal">{t(subtitle)}</Text>
        </TextWrapper>
        <LineArrowRight size={12} />
      </StyledOption>
    </>
  )
}

const ModalImageOptions = ({
  type,
  additionalOptions = { gallery: true },
  onChange,
  format,
  children: button,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      {button(setIsOpen)}
      <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <Text className="pb-1 font-semibold text-base">
          {t('modal.imageOriginSelection.title')}
        </Text>
        {imageOptions.map(({ parent = DefaultWrapper, ...option }) => {
          if (!additionalOptions[option.type] && option.type !== 'gallery') {
            return null
          }
          return (
            <CosmeticsProvider>
              <Option
                parent={parent}
                option={option}
                destination={type}
                onDismiss={() => setIsOpen(false)}
                onChange={value => {
                  onChange(value)
                }}
                format={format}
                {...props}
              />
            </CosmeticsProvider>
          )
        })}
      </Modal>
    </>
  )
}

export default ModalImageOptions
