const notifications = {
  notifications: 'Notifications',
  all: {
    title: 'All notifications',
  },
  daily_quests_reminder: {
    title: 'Daily quests',
    message: 'Complete all your daily quests',
  },
  daily_rewards_reminder: {
    title: 'Daily rewards',
    message: 'Claim all your daily rewards',
  },
  follow_request: {
    title: 'Follow request',
    message: 'requested to follow you',
  },
  follow_response: {
    title: 'Follow response',
    message: '{{response}} your follow request',
  },
  mention_in_image: {
    title: 'Mention in image',
    message: 'mentionned you in an image',
  },
  mention_in_post: {
    title: 'Mention in post',
    message: 'mentionned you in a post',
  },
  mention_in_profile: {
    title: 'Mention in profile',
    message: 'mentionned you in profile description',
  },
  new_follow: {
    title: 'New follower',
    message: 'started following you',
  },
  new_image: {
    title: 'New image',
    message: 'posted a new image',
  },
  new_post: {
    title: 'New post',
    message: 'posted a new post',
  },
  new_watering: {
    title: 'New watering',
    message: 'watered your seed',
  },
  welcome_quests_reminder: {
    title: 'Welcome quests',
    message: 'Complete all your welcome quests',
  },
  sponsor_validated: {
    title: 'Referral validated',
    message: 'just validated all welcome quests',
  },
  mall_is_open: {
    title: 'Budly Mall is available!',
    message: 'Come and enjoy our exclusive promotions!',
  },
}

export default notifications
