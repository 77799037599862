import getFollowings from './getFollowings'
import { api } from '@/Services/api'

export const followingsApi = api.injectEndpoints({
  endpoints: build => ({
    getFollowings: getFollowings(build),
  }),
  overrideExisting: true,
})

export const { useGetFollowingsQuery } = followingsApi
