import { getResourceByType } from './utils'
import React, { useMemo } from 'react'
import styled from 'styled-components'

export const ResourceIconImage = styled.img`
  width: ${({ size = 24 }) => size}px;
  height: ${({ size = 24 }) => size}px;
`

const ResourceIcon = ({ type, size = 24, ...props }) => {
  const { icon: Icon } = useMemo(() => getResourceByType(type), [type])

  return <Icon size={size} {...props} />
}

export default ResourceIcon
