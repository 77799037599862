import './Config'
import { db } from '@/Firebase/Database'
import { doc, getDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import app from './Config'

export const euFunctions = getFunctions(app, 'europe-west1')
// export const functions = getFunctions(app, 'us-central1')

export const createdataUser = (identifiants, linkId, slug = 'gpm') => {
  const dataUser = {
    ids: linkId ? [linkId] : [],
    test: true,
    needToActiveAccount: true,
    createdAt: Date.now(),
    email: identifiants.email.trim().toLowerCase(),
    firstName: '',
    lastName: '',
    job: '',
    businessName: '',
    address1: '',
    address2: '',
    customUrl: '',
    whiteLabel: slug,
    accountPublic: true,
    captureEmail: {
      isActive: false,
      title: '',
      success: '',
      position: 'top',
      cgv: '',
      rgpd: '',
      apiKey: '',
      audienceId: '',
    },
    links: [],
    tels: [
      {
        function: null,
        id: 0,
        number: '',
      },
    ],
    mails: [identifiants.email.trim().toLowerCase()],
    photoURL: '',
    socials: [],
    plan: slug === 'gpm' ? 'FREE' : 'PRO',
    trial_period: false,
    pixelFacebookId: '',
    subscriptionId: '',
    styles: {
      'background-url': '',
      'background-color': '',
      'buttons-color': '',
      'border-radius': '35px',
      logoIsHide: false,
      modeBg: 'color',
      btnShape: 'shape1',
      'shape-avatar': 'shape1',
    },
    status: 'creation',
    tutorialSeen: false,
  }
  return dataUser
}

export const getTokenAuth = uid => {
  return new Promise((resolve, reject) => {
    httpsCallable(
      euFunctions,
      'getCustomTokenAuth',
    )({
      uid,
    })
      .then(async res => {
        resolve(res)
      })
      .catch(err => reject(err))
  })
}

// export const resendMailVerification = data => {
//   return new Promise((resolve, reject) => {
//     httpsCallable(
//       functions,
//       'resendMailVerification',
//     )(data)
//       .then(async res => {
//         resolve(res)
//       })
//       .catch(err => reject(err))
//   })
// }

export const getUser = async uid => {
  const userRef = doc(db, 'users', uid)
  const docSnap = await getDoc(userRef)
  const user = docSnap.data()
  return user
}
