import getAllAnalyticsFormatted from './getAllAnalyticsFormatted'
import getAnalytics from './getAnalytics'

import { api } from '@/Services/api'

export const analyticssApi = api.injectEndpoints({
  endpoints: build => ({
    getAnalytics: getAnalytics(build),
    getAllAnalyticsFormatted: getAllAnalyticsFormatted(build),
  }),
  overrideExisting: true,
})

export const { useGetAnalyticsQuery, useGetAllAnalyticsFormattedQuery } =
  analyticssApi
