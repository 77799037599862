import { useTheme } from '@/Hooks'
import { useState } from 'react'
import { useEditCurrentUser } from '../Dashboard/Contexts/EditProfileContext'
import { useCartContext } from '../Dashboard/Contexts/CartContext'
import { Modal } from './ModalWrapper/Modal'
import { CosmeticsProvider } from '../Dashboard/Contexts/CosmeticsContext'
import ModalImageOptions from './ModalImageOptions/ModalImageOptions'
import { LineEdit, LineTrash } from '@/Assets/icons/components'
import ModalImageDeleteConfirmation from './ModalImageDeleteConfirmation'
import { useTranslation } from 'react-i18next'
import { useProfileContext } from '../Profile/Contexts/ProfileContext'

const fields = {
  banner: 'banner',
  photo: 'photoURL',
  logo: 'logoURL',
}

const ModalEditOrDelete = ({ type, children, additionalOptions }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const editionContext = useEditCurrentUser()
  const cartContext = useCartContext()
  const { profile } = useProfileContext()
  const [isOpen, setIsOpen] = useState(false)

  if (!editionContext || profile || !cartContext) {
    return children({
      onClick: null,
    })
  }

  const { addItemToCart, clearCart } = cartContext
  const { editCurrentUser, setEditCurrentUser } = editionContext

  const onDelete = () => {
    const field = fields[type]
    setEditCurrentUser({ ...editCurrentUser, [field]: null })
    clearCart()
  }

  return (
    <>
      {children({
        onClick: () => setIsOpen(true),
      })}
      <CosmeticsProvider>
        <ModalImageOptions
          type={type}
          onDismiss={() => setIsOpen(false)}
          addItemToCart={addItemToCart}
          onChange={value => {
            clearCart()
            setEditCurrentUser({
              ...editCurrentUser,
              ...value,
            })
          }}
          additionalOptions={additionalOptions}
          format={type === 'banner' ? { width: 1280, height: 663 } : undefined}
        >
          {setIsModalImageOptionsOpen => (
            <ModalImageDeleteConfirmation
              onDelete={onDelete}
              onDismiss={() => setIsOpen(false)}
            >
              {setModalImageDeleteConfirmationOpen => (
                <Modal
                  title={t('modal.editOrDeletePicture.title')}
                  isOpen={isOpen}
                  onDismiss={() => setIsOpen(false)}
                  actions={[
                    {
                      label: t('modal.editOrDeletePicture.edit'),
                      color: theme.colors.grey1,
                      onClick: () => {
                        setIsModalImageOptionsOpen(true)
                        setIsOpen(false)
                      },
                      icon: LineEdit,
                    },
                    {
                      label: t('modal.editOrDeletePicture.delete'),
                      color: theme.colors.red,
                      icon: LineTrash,
                      onClick: () => {
                        setModalImageDeleteConfirmationOpen(true)
                        setIsOpen(false)
                      },
                    },
                  ]}
                />
              )}
            </ModalImageDeleteConfirmation>
          )}
        </ModalImageOptions>
      </CosmeticsProvider>
    </>
  )
}

export default ModalEditOrDelete
