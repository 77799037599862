import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Avatar } from './Avatar'
import DynamicBackgroundAnimated from '@/Utils/DynamicBackgroundAnimated'
import { useProfileContext } from './Contexts/ProfileContext'
import { useTranslation } from 'react-i18next'
import { LineGallery, Tree } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import { useGetUserActivity } from '@/Hooks/useGetUserActivity'
import { useGetAllTreesCountQuery } from '@/Services/modules/trees'

const BannerWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.l};
    width: ${theme.size.fill};
    aspect-ratio: 1.93;
    overflow: hidden;
    background-color: ${theme.colors.grey1};
    position: relative;
  `}
`

const ColorBanner = styled.div`
  flex: 1;
  background-color: ${props => props.color};
`

const BackgroundVideo = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    left: 0px;
  }
  .video-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: opacity 400ms ease 0ms;
  }
  .tiny {
    filter: blur(20px);
    transform: scale(1.1);
    transition: visibility 0ms ease 400ms;
  }
`

const VideoBanner = ({ source }) => {
  return (
    <BackgroundVideo>
      <video key={source.uri} autoPlay loop muted playsInline>
        <source src={source.uri} type="video/mp4" />
      </video>
    </BackgroundVideo>
  )
}

const NoBanner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ noBannerStyle }) => noBannerStyle};
`

const AvatarWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${props => '-' + parseInt(props.avatarSize, 10) / 2}px;
    left: ${theme.space.xs};
  `}
`

const Banner = ({ banner, bg, ...props }) => {
  const { theme } = useTheme()

  return (
    <BannerWrapper banner={banner} {...props}>
      {banner ? (
        <>
          {banner.type === 'animatedBackground' && (
            <DynamicBackgroundAnimated name={banner.value} />
          )}
          {banner.type === 'image' && (
            <img
              alt="banner"
              className="w-full h-full object-cover"
              src={banner.value}
            />
          )}
          {banner.type === 'video' && (
            <VideoBanner className="flex-1" source={{ uri: banner.value }} />
          )}
          {banner.type === 'color' && <ColorBanner color={banner.value} />}
        </>
      ) : (
        <NoBanner
          className="flex flex-1 items-center justify-center h-full"
          noBannerStyle={theme.colors.grey1}
        >
          <LineGallery color={theme.colors.grey2} />
        </NoBanner>
      )}
    </BannerWrapper>
  )
}

const Badge = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.borderRadius.l};
    position: absolute;
    right: 0px;
    bottom: ${props => '-' + parseInt(props.bottom, 10) / 2}px;
    color: ${theme.colors.black};
  `}
`

const TreeBadge = props => {
  const { profile, isAdmin } = useProfileContext()
  const { t } = useTranslation()
  const { userActivity } = useGetUserActivity(profile?.uid)
  const { data: allTreesCount } = useGetAllTreesCountQuery()

  const treesCount = useMemo(() => {
    return isAdmin ? allTreesCount || 0 : userActivity?.trees?.count || 0
  }, [isAdmin, allTreesCount, userActivity?.trees?.count])

  const getLabelWithPlurial = (count, label) => {
    if (count > 1) {
      return label
    }

    if (label.endsWith('s')) {
      return label.slice(0, -1)
    }

    return label
  }

  if (!userActivity) {
    return null
  }

  return (
    <Badge {...props} className="p-2">
      <Tree size={16} />
      {treesCount} {getLabelWithPlurial(treesCount, t('previewpage.planted'))}
    </Badge>
  )
}

export const BannerAvatar = ({
  profile,
  avatarSize,
  showEmptyLogo = false,
  hideTreeBadge,
  ...props
}) => {
  return (
    <div className="relative">
      <Banner
        banner={profile.banner}
        bg={profile?.styles?.['background-color']}
        {...props}
      />
      <AvatarWrapper avatarSize={avatarSize}>
        <Avatar
          shape={profile.styles?.['shape-avatar']}
          size={avatarSize}
          showEmptyLogo={showEmptyLogo}
          profile={profile}
        />
      </AvatarWrapper>
      {!hideTreeBadge && <TreeBadge bottom={avatarSize} />}
    </div>
  )
}
