const profilepage = {
  application_available: 'Application available',
  download_app: 'Download',
  title_tab_bottom: 'Profile',
  preview: 'Preview',
  at_home_bar: 'at',
  warning_email: 'Resend the confirmation email',
  my_profile: 'My profile',
  advanced_options: 'Advanced options',
  instant_redirection: 'Instant redirection',
  activity: 'Activity',
  add_content: 'Add content',
  add_image: 'Add an image',
  multiple_edition: 'Multiple edition',
  contact: {
    tel_option: 'Phone',
    email_option: 'Email',
    address_option: 'Address',
    title_tab: 'Contact',
    top: {
      sentence: 'Good day',
      addcontact: 'Add new contact',
    },
    bottom: {
      visible_all: 'Visible to all',
      visible_contact_only: 'Visible only for my contact',
    },
    contact_only: {
      adresse: 'Mon adresse',
      phone_work: 'Téléphone bureau',
    },
  },
  social: {
    title_tab: 'Social',
    add_social: 'Add new social',
    add_social2: 'Add new social',
    my_social_network: 'My social networks',
    search_network: 'Search for new content',
  },
  link: {
    add_link: 'Add new link',
    my_links: 'My links',
    title_tab: 'Links',
    video_tab_show_on_profile: 'Show on profile',
  },
  galerie: {
    title_tab: 'Gallery',
    add_photo: 'Add new photo',
    galerie: 'My gallery',
    edit_photo: {
      add_hashtag: 'Add hashtag',
      save: 'Save',
    },
    edit_image_panel_title: 'Edit your image',
    add_image_panel_title: 'Add a new image',
  },
  shop: {
    create_shop: 'Create my shop',
    create_shop_message:
      'The integrated shop allows you to create product sheets and manage your orders on a dedicated platform. Customers will be able to buy your products directly from your profile. Activating this service requires payment of {{quantity}} coins.',
    edit_shop: 'Edit my shop',
    enable_shop: 'Enable my shop',
    disable_shop: 'Disable my shop',
  },
}

export default profilepage
