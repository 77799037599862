const tutorialModal = {
  water_Plant_your_trees: 'Water & Plant Your Trees',
  plant_trees:
    'Plant trees with the community or alone to increase your eco-responsible impact.',
  your_games: 'Your Games & Rankings',
  collect_resources:
    'Collect resources daily through quests, mini-games, and the casino.',
  budly_actions: 'Budly Activities',
  publish_post_share:
    'Retrieve your resources, complete your quests, plant and walk to earn rewards.',
  exclusive_promotions: 'Exclusive Promotions',
  find_exclusive_promotions:
    'Find exclusive promotions on a multitude of products thanks to Budly.',
  your_profile: 'Your Profile',
  your_profile_is_your:
    'Your profile is your business card. Find all your information in one place.',
  personalize_your_profile: 'Personalize Your Profile',
  change_the_appearance:
    'Change the appearance of your profile and add new content about yourself.',
  done: 'Done',
  next: 'Next',
}

export default tutorialModal
