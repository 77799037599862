import getAvailableResourcesByUid from './getAvailableResourcesByUid'
import { api } from '@/Services/api'

export const availableResourcesApi = api.injectEndpoints({
  endpoints: build => ({
    getAvailableResourcesByUid: getAvailableResourcesByUid(build),
  }),
  overrideExisting: true,
})

export const { useGetAvailableResourcesByUidQuery } = availableResourcesApi
