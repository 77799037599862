import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgQrcodeCircle = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200pt"
      height="200pt"
      viewBox="0 0 200 200"
      {...props}
    >
      <g transform={`scale(${1})`}>
        <path
          fill="#7ACB8B"
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="M150 100c0 27.613-22.387 50-50 50s-50-22.387-50-50 22.387-50 50-50 50 22.387 50 50"
        />
      </g>
    </svg>
  )
}
export default SvgQrcodeCircle
