import { Button } from '@/Components/Buttons/Button'
import { usePutCurrentUserMutation } from '@/Services/modules/users'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const EnableShopButton = ({ user }) => {
  const { t } = useTranslation()
  const [putCurrentUser] = usePutCurrentUserMutation()
  const [loading, setLoading] = useState(false)

  const handleToggleShop = async () => {
    setLoading(true)
    try {
      await putCurrentUser({
        body: {
          shopRocket: {
            ...user.shopRocket,
            enable: !user.shopRocket.enable,
          },
        },
        userId: user.uid,
      })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  const label = user?.shopRocket?.enable
    ? 'profilepage.shop.disable_shop'
    : 'profilepage.shop.enable_shop'

  if (!user?.shopRocket?.store_id) {
    return null
  }

  return (
    <Button
      label={t(label)}
      onClick={handleToggleShop}
      className="w-full"
      loading={loading}
      variant="secondary"
    />
  )
}
