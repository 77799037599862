const tutorialModal = {
  water_Plant_your_trees: 'Riegue y Plante Sus Árboles',
  plant_trees:
    'Plante árboles con la comunidad o solo para aumentar su impacto eco-responsable.',
  your_games: 'Sus Juegos y Clasificaciones',
  collect_resources:
    'Recopile recursos diariamente a través de misiones, mini-juegos y el casino.',
  budly_actions: 'Actividades de Budly',
  publish_post_share:
    'Recupera tus recursos, completa tus misiones, planta y camina para ganar recompensas.',
  exclusive_promotions: 'Promociones Exclusivas',
  find_exclusive_promotions:
    'Encuentre promociones exclusivas en una multitud de productos gracias a Budly.',
  your_profile: 'Su Perfil',
  your_profile_is_your:
    'Su perfil es su tarjeta de presentación. Encuentre toda su información en un solo lugar.',
  personalize_your_profile: 'Personalice Su Perfil',
  change_the_appearance:
    'Cambie la apariencia de su perfil y agregue nuevo contenido sobre usted mismo.',
  done: 'Terminado',
  next: 'Siguiente',
}

export default tutorialModal
