const deleteUserByUid = build =>
  build.mutation({
    query: uid => {
      return {
        url: `/users/${uid}`,
        method: 'DELETE',
      }
    },
    invalidatesTags: ['UsersByHashtag', 'Whitelabel', 'TeamMembers'],
  })

export default deleteUserByUid
