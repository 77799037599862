import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import React from 'react'
import StarRatings from 'react-star-ratings'

export const RatingStars = ({ userName, rate }) => {
  const { theme } = useTheme()
  return (
    <div className="flex flex-col">
      <Text className="text-1xl xl:text-2xl select-none text-white">
        {userName}
      </Text>
      <StarRatings
        rating={rate}
        starRatedColor="#FFF"
        starEmptyColor={theme.colors.grey2}
        starDimension="20px"
        starSpacing="1px"
        numberOfStars={5}
      />
    </div>
  )
}
