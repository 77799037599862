import { useEffect } from 'react'

function useInfiniteScrolling({ isLoading, onEndReached }) {
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading
    ) {
      return
    }
    onEndReached()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isLoading, onEndReached])
}

export default useInfiniteScrolling
