const getUsersByHashtag = build =>
  build.query({
    query: ({ hashtag, whitelabel = 'gpm' }) => {
      return {
        url: `/users/hashtag/${hashtag}?whitelabel=${whitelabel}`,
        method: 'GET',
      }
    },
    providesTags: ['UsersByHashtag'],
  })

export default getUsersByHashtag
