import clsx from 'clsx'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import CostBadge from './Activity/components/CostBadge'
import { TouchableOpacity } from './Buttons/TouchableOpacity'

const ContentWrapper = styled.div`
  ${({ theme, ratio }) => css`
    aspect-ratio: ${ratio || 1.77};
    border-radius: ${theme.borderRadius.m};
    overflow: hidden;
    width: 100%;
  `}
`

const StyledTouchableOpacity = styled(TouchableOpacity)`
  ${({ theme, showSelected, selected }) => css`
    border-color: ${selected ? theme.colors.primary : theme.colors.white};
    border-radius: ${theme.borderRadius.m};
    background: ${showSelected ? theme.colors.grey1 : 'transparent'};
    align-items: center;
  `}
`

const CostBadgeWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

export const OptionItem = ({
  onSelected,
  ratio,
  selected,
  showSelected = true,
  children,
  cosmetic,
  id,
  ...props
}) => {
  const { currentUser } = useSelector(state => state.user)
  return (
    <StyledTouchableOpacity
      onClick={showSelected ? onSelected : null}
      disabled={!showSelected}
      className={clsx(
        'mb-2 relative w-full',
        showSelected && 'border-2',
        !selected && 'border-white',
      )}
      showSelected={showSelected}
      selected={selected}
    >
      <ContentWrapper ratio={ratio} {...props}>
        {children}
      </ContentWrapper>
      <CostBadgeWrapper>
        {cosmetic && (
          <CostBadge
            price={cosmetic.price}
            isAcquired={cosmetic.isAcquired.includes(id)}
            isUsed={
              cosmetic.label === 'animatedBackground'
                ? currentUser.banner?.value === id
                : currentUser.banner?.value.includes(id)
            }
            forceBg
          />
        )}
      </CostBadgeWrapper>
    </StyledTouchableOpacity>
  )
}
