import { useTranslation } from 'react-i18next'
import { TouchableOpacity } from '../Buttons/TouchableOpacity'
import Badge from './Badge'
import { Tree } from '@/Assets/icons/components'
import Text from '../Texts/Text'
import { useProfileContext } from '../Profile/Contexts/ProfileContext'
import {
  useGetAllTreesCountQuery,
  useGetDetailsTreesPlantByUserQuery,
} from '@/Services/modules/trees'
import { useMemo } from 'react'

const options = {
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
}
const ADMIN_UID = 'd2OM80yi4VgNMBUP4ExTlsjAPNI3'

const TreeBadgeButton = ({ onClick, treesCount }) => {
  const { t } = useTranslation()

  const getLabelWithPlurial = (count, label) => {
    if (count > 1) {
      return label
    }

    if (label.endsWith('s')) {
      return label.slice(0, -1)
    }

    return label
  }

  return (
    <TouchableOpacity onClick={onClick}>
      <Badge className="pl-2 pr-1 py-1.5">
        <Tree className="mb-0.5" size={16} />
        <Text className="pl-1 pr-2">
          {treesCount}{' '}
          {getLabelWithPlurial(treesCount, t('previewpage.planted'))}
        </Text>
      </Badge>
    </TouchableOpacity>
  )
}

const TreeBadge = () => {
  const { profile } = useProfileContext()
  const { data: userTrees } = useGetDetailsTreesPlantByUserQuery(
    profile?.uid,
    options,
  )

  const isAdmin = useMemo(() => {
    return profile?.uid === ADMIN_UID
  }, [profile?.uid])

  const { data: allTreesCount } = useGetAllTreesCountQuery(null, {
    skip: !isAdmin,
  })

  const treesCount = isAdmin ? allTreesCount : userTrees?.trees?.count

  if (!userTrees) {
    return null
  }

  // if (isAdmin) {
  //   return (
  //     <WebView uri="https://ecologi.com/budly">
  //       {ref => (
  //         <TreeBadgeButton
  //           onClick={() => ref.current.present()}
  //           treesCount={treesCount}
  //         />
  //       )}
  //     </WebView>
  //   )
  // }

  return (
    // <ModalUsersPlantations
    //   individualTreesCount={userTrees?.trees?.individualTreesCount}
    //   collectiveTreesCount={userTrees?.trees?.collectiveTreesCount}
    //   profile={profile}
    // >
    //   {ref => (
    <TreeBadgeButton
      // onClick={() => ref.current.present()}
      treesCount={treesCount}
    />
    //   )}
    // </ModalUsersPlantations>
  )
}

export default TreeBadge
