const get_by_csv = {
  upload: {
    select_csv_file: 'Select CSV file',
    to_upload: 'to upload',
    or_drag_and_drop: 'or drag and drop it here',
    must_be: 'Must be',
    file_with_maximum: 'file with maximum',
    rows: 'rows',
    import_limited:
      'Import is limited to 100 rows at a time. Please use a smaller file.',
    upload: 'Upload',
    how_to_format_csv: 'How to format the CSV',
    look_possible_columns:
      'Take a look at the possible columns below or download the CSV template.',
    name_fields_for_profile_information:
      "Name the fields below to easily retrieve information about your colleagues' profiles.",
    use_preformatted_csv_template:
      "You can use the pre-formatted CSV template provided by Budly. Fill in each associated field with your colleagues' information.",
    download_template: 'Download CSV Template',
  },
  mapping_table: {
    select_field: 'Select field',
    select_csv_column: 'Select CSV Column',
    sample_data: 'Sample Data',
    required: '(required)',
    unmaped: 'Unmaped',
  },
  mapping: {
    invalid_value_for: 'Invalid_value_for ',
    check_your_csv_file_at_column: '. Check your CSV file at column: ',
    row: ' row:',
    mapping: 'Mapping',
    choose_columns: 'Choose columns to be imported',
    we_have_found: 'This is a preview of your CSV file and we have found',
    to_use: 'to use.',
    select_columns: 'Select which columns to include below',
  },
  modal_download_app: {
    title: 'Great job ! Want the same profile ?',
    subtitle: 'Get Budly to create your own digital profile',
    btn_label: 'Get Budly',
    download_the_budly_app: 'Download the Budly App',
    explore_budly:
      "Explore Budly's unique features: plant trees, customize your profile, adopt companions, and much more!",
  },
}

export default get_by_csv
