import * as React from 'react'
const SvgLineZoomGreen = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        fill="#7ACB8B"
        d="m3.316 14.519.73-.171zm0-5.457.73.171zm15.473 0 .73-.171zm0 5.457.73.171zm-5.008 5.008-.171-.73zm-5.457 0-.171.73zm0-15.473-.171-.73zm5.457 0 .171-.73zm6.689 18.214a.75.75 0 1 0 1.06-1.06zm-6.8-9.728a.75.75 0 0 0 0-1.5zm-5.235-1.5a.75.75 0 0 0 0 1.5zm1.867 3.368a.75.75 0 0 0 1.5 0zm1.5-5.235a.75.75 0 0 0-1.5 0zm-7.756 5.175a11.2 11.2 0 0 1 0-5.115l-1.46-.342a12.7 12.7 0 0 0 0 5.8zm14.013-5.115a11.2 11.2 0 0 1 0 5.115l1.46.342a12.7 12.7 0 0 0 0-5.8zm-4.45 9.564a11.2 11.2 0 0 1-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0zM8.496 4.784a11.2 11.2 0 0 1 5.115 0l.342-1.46a12.7 12.7 0 0 0-5.8 0zm0 14.013a5.97 5.97 0 0 1-4.45-4.45l-1.46.343a7.47 7.47 0 0 0 5.568 5.567zm5.457 1.46a7.47 7.47 0 0 0 5.568-5.567l-1.46-.342a5.97 5.97 0 0 1-4.45 4.449zM13.61 4.784a5.97 5.97 0 0 1 4.45 4.45l1.46-.343a7.47 7.47 0 0 0-5.568-5.568zm-5.457-1.46A7.47 7.47 0 0 0 2.586 8.89l1.46.342a5.97 5.97 0 0 1 4.45-4.45zm8.652 15.28 3.665 3.664 1.06-1.06-3.665-3.665zM13.67 11.04h-2.617v1.5h2.617zm-2.617 0H8.435v1.5h2.617zm.75 3.368V11.79h-1.5v2.618zm0-2.618V9.173h-1.5v2.617z"
      />
    </g>
  </svg>
)
export default SvgLineZoomGreen
