import Bolt from '@/Assets/icons/components/Bolt'
import React from 'react'

const type = 'exp'

const Icon = props => (
  <div {...props}>
    <Bolt size={props.size} />
  </div>
)

const getLabel = t => t('activitypage.experience')

export const exp = {
  type,
  getLabel,
  icon: Icon,
  isPrimary: false,
  isConsumable: false,
}
