import Seed from '@/Assets/icons/components/Seed'
import { SeedWaving } from '../../Mascot/seed-variants'
import {
  SeedJumping,
  SeedReward,
  SeedSunglasses,
} from '../../Mascot/seed-variants'
import React from 'react'

const type = 'seed'

const Icon = props => (
  <div {...props}>
    <Seed size={props.size} />
  </div>
)

const getLabel = t => t('shop.seed')

const getFreeOffers = t => [
  {
    subtitle: t('shop.free_subtitle', { resource: getLabel(t) }),
    quantity: 1,
    type,
    image: SeedWaving,
  },
]

const getOffers = t => [
  {
    subtitle: t('shop.five_seeds_pack_subtitle'),
    quantity: 5,
    image: SeedReward,
    type,
    priceId: 'price_1P6D0dGhdaiFJMYlekGuqxQx',
  },
  {
    subtitle: t('shop.fifty_seeds_pack_subtitle'),
    quantity: 50,
    image: SeedJumping,
    type,
    priceId: 'price_1P6WGpGhdaiFJMYlAcf3zROz',
  },
  {
    subtitle: t('shop.two_hundred_seeds_pack_subtitle'),
    quantity: 200,
    image: SeedSunglasses,
    type,
    priceId: 'price_1P6WKwGhdaiFJMYlBYhST9Bp',
  },
]

export const seed = {
  type,
  getLabel,
  icon: Icon,
  getFreeOffers,
  getOffers,
  isPrimary: true,
  isConsumable: true,
}
