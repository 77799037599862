const modal = {
  header: {
    all: 'Todos',
  },
  report: {
    title: 'Reportar esta publicación',
    modal_title: 'Estás seguro de querer denunciar este post ?',
  },
  loading: {
    title: 'Cargando ...',
  },
  modalPsw: {
    title: 'Cambiar la contraseña',
    subtitle: 'Está seguro de que desea cambiar su contraseña ?',
  },
  btn: {
    accept: 'Aceptar',
    decline: 'Declinar',
    confirm: 'Confirmar',
    cancel: 'Anular',
    delete: 'Suprimir',
    back: 'vuelta',
    finish: 'Terminar',
    gallery_only: 'Sólo en la galería',
  },
  editPost: {
    edit_btn: 'Modificar este post',
    remove_btn: 'Eliminar este post',
  },
  publish_gallery_in_post: 'Publicar su foto en el feed',
  deletepost: {
    title: 'Estás seguro de que quieres eliminar este post ?',
  },
  publishPost: {
    create_you_post: 'Crear una publicación',
    you_should_pay:
      'Para compartir tu publicación en tu servicio de noticias, debes gastar una semilla para que los usuarios puedan regarla.',
    all_spent_seeds_publication:
      'Encuentre todas las semillas gastadas poniendo su publicación en línea.',
    post_publish_price_title: 'Publicación de la entrada',
    description_publish_price_title: 'Descripción ({{length}} caracteres)',
    hashtag_publish_price_title: 'Hashtag ({{length}})',
    image_publish_price_title: 'Imagen ({{length}})',
    total_publish_price_title: 'Gasto Total',
    plant_a_seed: 'Planta una semilla',
    publish: 'Publicar',
    get_seeds: 'Obtener semillas',
    btn_photo: 'Foto',
    btn_AI: 'IA',
    btn_tag: 'Etiqueta',
    change_confirmation: 'Confirmación de Cambio',
    are_you_sure: '¿Estás seguro de que quieres editar esta publicación?',
    free_editing: 'Edición gratuita',
  },
  modal_ai_companion: {
    title: '¡Genera imágenes al instante con la ayuda de tu compañero!',
    subtitle:
      'Déjate guiar por tu compañero en la creación de imágenes con nuestra herramienta de inteligencia artificial.',
    btn_discover_companions: 'Descubrir los compañeros',
    btn_not_now: 'No ahora',
  },
  customColor: {
    title: 'Personalizar el color',
  },
  removeAccount: {
    title: 'Eliminar mi cuenta',
    subtitle:
      'Estás a punto de eliminar tu cuenta y todos tus datos.\n\nEstá seguro de que quiere hacerlo ?',
    input_confirm:
      'Para confirmar, escriba "ELIMINAR MI CUENTA" en el campo a continuación',
    toastErrorInvalidConfirmation: 'El texto ingresado no es válido',
    toastErrorFieldEmpty:
      'Campo vacío. Debes escribir "ELIMINAR MI CUENTA" para confirmar la eliminación',
  },
  removeImagePost: {
    title: 'Supresión de la imagen',
    subtitle:
      'Estás a punto de borrar esta imagen.\n\nEstás seguro de querer realizar esta acción ?',
  },
  chooseThumbnail: {
    title: 'Editar la miniatura de su contenido',
    btn: {
      perso: 'Miniatura personalizada',
      default: 'Miniatura predeterminada',
    },
  },
  editOrDeletePicture: {
    title: 'Editar o eliminar la imagen',
    edit: 'Editar la imagen',
    delete: 'Eliminar la imagen',
  },
  imageTypeSelection: {
    title: 'Personalizar su perfil',
  },
  imageOriginSelection: {
    title: 'Cargar su imagen',
    choice1: {
      title: 'Sube tu propia imagen',
      subtitle: 'Desde tu teléfono, Facebook, Instagram o Google Drive.',
    },
    choice2: {
      title: 'Fotos de Unsplash',
      subtitle: 'Elija entre más de 500.000 imágenes.',
    },
    choice3: {
      title: 'Fondo animado',
      subtitle: 'Elija entre más de 100 fondos animados.',
    },
    choice4: {
      title: 'Diseñar con Canva',
      subtitle: 'Diseñe su propia imagen de portada en Canva.',
    },
    choice5: {
      title: 'Vídeos de Coverr',
      subtitle: 'Elija entre más de 100.000 vídeos.',
    },
    choice6: {
      title: 'Generación por IA',
      subtitle:
        'Genera imágenes únicas con la ayuda de la inteligencia artificial.',
    },
  },
  editProduct: {
    nfc_option: 'Conecte su perfil a este producto a través de NFC',
  },
  addContent: {
    label_phone: 'Your phone',
  },
  accountDisconnection: {
    title: 'Está seguro de que desea cerrar sesión en su cuenta ?',
    subtitle:
      'La sesión actual se cerrará y tendrás que volver a iniciar sesión para volver a acceder a tu cuenta.',
  },
  imageDeleteConfirmation: {
    title: 'Estás seguro de que quieres borrar esta imagen ?',
    subtitle:
      'Esta acción es irreversible y la imagen se eliminará permanentemente de su cuenta.',
  },
  contentDeleteConfirmation: {
    title: 'Estás seguro de que quieres borrar este contenido ?',
    subtitle:
      'Esta acción es irreversible y el contenido se eliminará permanentemente de su cuenta.',
  },
  postOptions: {
    title: 'Gestionar las interacciones con este post',
    subtitle:
      'Al marcar este post, nos ayuda a mantener una comunidad respetuosa permitiéndonos moderar las publicaciones. Además, si quieres dejar de ver el contenido de este usuario, puedes optar por bloquearlo.',
  },
  blockedUser: {
    title: 'Estás seguro de que quieres bloquear a este usuario ?',
    subtitle: 'Si bloqueas este usuario, no verás su contenido.',
  },
  businessCardOption: {
    title: 'Opciones de tarjeta',
    option1: 'Editar mi tarjeta',
    option2: 'ver mi tarjeta',
    option3: 'Eliminar mi tarjeta',
  },
  deleteBusinessCard: {
    title: 'Está seguro de que desea eliminar este tarjeta',
  },
  checkPhoneNumber: {
    title: 'Verificar tu número',
    subtitle:
      'Confirma el código enviado por SMS para verificar tu número de teléfono. Tu cuenta puede luego solicitar la certificación.',
    btnLabel1: 'Enviar un código',
    btnLabel2: 'Confirmar',
    modalBtnLabel: 'Verificar',
    placeholder: 'Enter your verification number',
  },
  sponsor: {
    title: 'Encuentra a tu Patrocinador',
    subtitle:
      'Encuentra a tu patrocinador y obtén un suministro inicial de recursos, semillas, monedas y gotas de agua para ayudarte a iniciar tu viaje en el mundo de Budly.',
    placeholder: 'Ingresa el nombre de tu patrocinador',
  },
  insufficientResource: {
    title: 'Recursos insuficientes',
    subtitle:
      'Parece que no tienes suficientes recursos para realizar esta acción.',
    btnLabelVisitStore: 'Visitar la tienda',
  },
  doubleEarnings: {
    title_double_your_earnings: 'Duplicar tus ganancias ?',
    subtitle_play_with_your_companion:
      'Jugar con tu compañero te permite multiplicar todas las ganancias durante el juego por 2. Sin embargo, utilizarás el 25% de la vitalidad de tu compañero en cada partida.',
    btn_play_with_compagnon: 'Jugar con mi compañero',
    btn_play_without_compagnon: 'Jugar sin mi compañero',
  },
  generated_style: {
    image_generation: 'Generación de imágenes con IA',
    pay_and_generate: 'Pagar y generar',
    generate_an_image: 'Generar una imagen',
    spend_coins: 'Gasta {{price}} monedas para poder generar tu imagen.',
    generate_the_image: 'Generar la imagen',
  },
  re_generated: {
    regeneration: 'Regeneración',
    Pay_and_regenerate: 'Pagar y regenerar',
    Regenerate_variation: 'Regenerar una variación',
    spend_one_coins:
      'Gaste {{price}} moneda para poder regenerar una variación de su imagen.',
  },
  buy_offer: {
    learn_more: 'Obtén más información sobre la oferta',
    spend_coins: 'Gasta {{price}} monedas',
  },
  steps_reward: {
    steps: 'pasos',
    steps_on: ' el ',
    validated_steps: '{{steps}} pasos validados',
    acquired_coins: '{{coins}} piezas obtenidas !',
    receive_coins_every_day: 'Gana monedas cada día mientras caminas.',
    every_day_steps:
      'Cada día, tu contador de pasos se pone a cero a medianoche. Para asegurarte de no perder tus monedas diarias, no olvides validar tus pasos antes de que acabe el día',
  },
  deactivateMember: {
    deactivate_selected_member: 'Desactivar el miembro seleccionado',
    reactivate_this_member: 'Reactivar este miembro',
    by_deactivating_this_member:
      'Al desactivar este miembro, su cuenta será suspendida y ya no podrá acceder a la aplicación. Como resultado, su perfil ya no será visible para otros usuarios.',
    by_reactivating_this_member:
      'Al reactivar a este miembro, su acceso a la aplicación será restaurado, lo que le permitirá volver a conectarse. Además, su perfil volverá a ser visible para otros usuarios.',
    disable_state: 'desactivado',
    disable: 'Desactivar',
    reactivate: 'Reactivar',
    your_account_has_been_deactivated:
      'Su cuenta ha sido desactivada por el administrador.',
    the_member_has_been_succesfully:
      'El miembro ha sido desactivado exitosamente.',
    the_member_has_been_reactivated:
      'El miembro ha sido reactivado exitosamente.',
  },
  addToContact: {
    share_this_profile: 'Compartir este perfil',
  },
  socialMedia: {
    share_on_your_favorite: 'Partagez sur vos réseaux sociaux favoris',
    share_on_facebook: 'Compartir en Facebook',
    share_on_linkedin: 'Compartir en LinkedIn',
    share_on_twitter: 'Compartir en Twitter',
    share_on_whatsapp: 'Compartir en WhatsApp',
    share_on_telegram: 'Compartir en Telegram',
    share_via_email: 'Compartir por Email',
  },
  member: {
    delete_profile: 'Eliminar este perfil',
    delete_profile_subtitle:
      'Eliminar permanentemente toda la información relacionada con este perfil.',
    role_change: 'Cambio de rol',
    role_change_subtitle:
      'Cambiar el rol de un miembro del equipo modificará sus permisos en la aplicación Budly.',
    duplicate_profile: 'Perfil a duplicar',
    old_profile: 'Perfil antiguo',
    new_profile: 'Nuevo perfil',
  },
}

export default modal
