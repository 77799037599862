import { Button } from '@/Components/Buttons/Button'
import { usePutCurrentUserMutation } from '@/Services/modules/users'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { onConnectShop, onCreateShop, onSwitchLiveStore } from './functions'
import ResourceIcon from '@/Components/Activity/Resources/ResourceIcon'
import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import ModalInsufficientResource from '@/Components/Modals/ModalInsufficientResource'
import { useGetAvailableResourcesByUidQuery } from '@/Services/modules/availableResources'
import { useAddResourceUsesByUidMutation } from '@/Services/modules/resourceUses'
import { useTranslation } from 'react-i18next'

const PRICE = { type: 'coin', value: 500 }

export const CreateShopButton = ({ user }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const [putCurrentUser] = usePutCurrentUserMutation()
  const [loading, setLoading] = useState(false)
  const alreadyPaid = user?.shopRocket?.alreadyPaid
  const { data: availableResources } = useGetAvailableResourcesByUidQuery(
    user.uid,
  )
  const [addResourceUses] = useAddResourceUsesByUidMutation()

  const handlePressCreate = async setIsOpen => {
    if (
      !alreadyPaid &&
      (availableResources[`${PRICE.type}s`] || 0) < PRICE.value
    ) {
      return setIsOpen(true)
    }
    try {
      setLoading(true)
      const createRes = await onCreateShop({ user })
      if (createRes.status !== 200) {
        throw new Error('Une erreur est survenue')
      }

      const { store_id, live_publishable_key, live_secret_key } = createRes.data
      const connectRes = await onConnectShop({
        email: user.email,
        storeID: store_id,
      })

      if (connectRes.status !== 200) {
        throw new Error('Une erreur est survenue')
      }

      const { id, password } = connectRes.data
      const resourceUse = {
        uid: user.uid,
        ...PRICE,
      }
      await Promise.all([
        onSwitchLiveStore({ storeApiKey: live_secret_key }),
        putCurrentUser({
          body: {
            shopRocket: {
              alreadyPaid: true,
              store_id,
              live_publishable_key,
              live_secret_key,
              user_id: id,
              password,
            },
          },
          userId: user.uid,
        }),
        ...(!alreadyPaid ? [addResourceUses(resourceUse)] : []),
      ])

      toast.success(t('shop.shop_creation_success'))
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  if (user?.shopRocket?.store_id) {
    return null
  }

  return (
    <>
      {!alreadyPaid && (
        <Text color={theme.colors.grey2} regular>
          {t('profilepage.shop.create_shop_message', { quantity: PRICE.value })}
        </Text>
      )}

      <ModalInsufficientResource
        resourcePrice={{
          type: PRICE.type,
          price: PRICE.value,
        }}
      >
        {setIsOpen => (
          <Button
            label={t('profilepage.shop.create_shop')}
            onClick={() => handlePressCreate(setIsOpen)}
            className="w-full"
            loading={loading}
            afterIcon={
              !alreadyPaid
                ? () => {
                    return (
                      <div className="flex flex-row">
                        <ResourceIcon type={PRICE.type} className="ml-3 mr-1" />
                        <span>{PRICE.value}</span>
                      </div>
                    )
                  }
                : null
            }
          />
        )}
      </ModalInsufficientResource>
    </>
  )
}
