const createMembers = build =>
  build.mutation({
    query: body => {
      return {
        url: '/members/gpm/multiple',
        method: 'POST',
        body,
      }
    },
    invalidatesTags: ['TeamMembers'],
  })

export default createMembers
