const modal_download_app = {
  title: 'Bien joué ! Vous voulez le même profil ?',
  subtitle: 'Obtenez Budly pour créer votre propre profil numérique',
  btn_label: 'Obtenez Budly',
  download_the_budly_app: 'Téléchargez l’application Budly',
  explore_budly:
    'Explorez les fonctionnalités uniques de Budly : plantez des arbres, personnalisez votre profil, adoptez des compagnons et bien plus encore !',
}

export default modal_download_app
