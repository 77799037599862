const reported_page = {
  i_understood: 'Entendí',
  been_reported: '¡Has sido reportado!',
  it_is_important: {
    main_text:
      '¡Es importante que sigas todas nuestras reglas para seguir utilizando Budly en las mejores condiciones!',
  },
  accept_our_rules:
    'Al continuar, aceptas nuestras normas de conducta y así preservas la calidad de nuestro servicio.',
  good_behavior: {
    be_respectfull: 'Sé Respetuoso',
    Insults_racist:
      'Están prohibidos los insultos, los comentarios racistas, homofóbicos, sexistas o cualquier otro comentario discriminatorio.',
    be_honest: 'Sé Honesto',
    fake_profiles:
      'Están prohibidos los perfiles falsos, las fotos de celebridades o imágenes que no te representen.',
    be_courteous: 'Sé Cortés',
    request_for_personal:
      'Están prohibidas las solicitudes de información personal, las solicitudes de servicios sexuales o cualquier otro comportamiento inapropiado.',
    be_yourself: 'Sé tú mismo',
    photo_of_landscapes:
      'Están prohibidas las fotos de paisajes, flores o cualquier otro elemento que no te represente.',
  },
}

export default reported_page
