import companions from './index'

export const getCompanionByLevel = level => {
  const companion = Object.values(companions).find(
    (_, index) => index + 1 === level,
  )
  return companion || {}
}

export const defaultResources = ['coin', 'exp', 'ticket']

export const processYields = (yields, resourcesToYield = defaultResources) => {
  return Object.entries(yields).reduce((acc, [key, value]) => {
    if (resourcesToYield.includes(key)) {
      acc[key] = value
    }
    return acc
  }, {})
}

export const getAllCompanions = () => {
  return Object.values(companions)
}
