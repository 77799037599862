const games = {
  play: 'Jouer',
  score: 'Score',
  point: 'point',
  the: 'le',
  game_historical: 'Historique de vos parties',
  gain_per_game: 'Gains par partie',
  cost_per_game: 'Coûts par partie',
  final_score: 'Score final',
  game_over: 'Partie terminée ?',
  game_over2: 'Terminer',
  you_are_about_to_leave:
    'Vous êtes sur le point de quitter la partie.\nVotre score et vos gains seront perdus ?',
  leave: 'Quitter',
  yes: 'Oui',
  no: 'Non',
  you: 'Vous',
  no_score_found: 'Aucun score trouvé pour ce jeu.',
  out_ranking: 'Vous ne faites pas partie du top 100.',
  no_ranking: 'Aucun classement trouvé',
  no_ranking_subtitle: 'Devenez le premier à faire un score sur ce jeu !',
  continue: 'Continuer',
  experience_gained: 'Expérience gagnée',
  rewards: 'Récompenses',
  volume: 'Volume',
  sound_effects: 'Effets sonores',
  music: 'Musique',
  replay: 'Rejouer',
  tired_companion: 'Compagnon fatigué',
  too_tired_to_double:
    'Il semble que votre compagnon soit trop fatigué pour continuer à doubler vos gains.',
  replay_without_companion: 'Voulez-vous rejouer sans lui ?',
  no_ticket: 'Pas de ticket',
  you_do_not_have_any_tickets: "Vous n'avez aucun ticket en votre possession.",
  get_in_shop: 'Vous pouvez en récupérer immédiatement dans la boutique.',
  new_game: 'Nouvelle partie ?',
  you_will_use_resources:
    'En redémarrant une partie, vous utiliserez vos ressources :',
  vitality_of_companion: '25 % de la vitalité de votre compagnon',
  ticket: 'ticket',
  remaining: 'Essais',
  level: 'Niveau',
  remaining_time: 'Temps',
  king_budlies: {
    description:
      'Faites le meilleur score en fusionnant les Budlies entre eux ! On raconte que certains ont invoqué le roi Budly !',
  },
  find_budlies: {
    description:
      "Mettez à l'épreuve vos compétences d'observation et de mémorisation en traçant les parcours de Budlies. Ces adorables créatures se cachent parmi les cases, et c'est à vous de reconstituer leur chemin initial. Chaque niveau présente un défi unique, avec des parcours de plus en plus complexes à reconstituer. Pouvez-vous relever le défi et devenir un véritable détective Budlie ?",
  },
  budmories: {
    description:
      "Mettez votre mémoire à l'épreuve en découvrant les paires correspondantes ! Oserez-vous relever le défi et devenir le maître de Budmories ?",
  },
  game_paid_success: 'Jeu payé avec succès',
  subtitle_welcome_quests_banner: 'Pour jouer aux mini-jeux',
  subtitle_welcome_quests_banner_business: 'Pour passer en profil entreprise',
  subtitle_welcome_quests_banner_post: 'Pour publier un post',
  subtitle_welcome_quests_banner_watered: 'Pour arroser un post',
  subtitle_welcome_quests_banner_profile: 'Pour interagir avec un profil',
  cumulated_rewards: 'Gains cumulés',
  seeds_rewarded: 'Graines obtenues',
  drops_rewarded: 'Gouttes obtenues',
  coins_rewarded: 'Pièces obtenues',
  ticket_rewarded: 'Tickets obtenues',
  no_lives_left: 'Aucune vie restante',
  spend_coins_to_revive:
    'Pour continuer votre partie, vous devez dépenser des pièces pour récupérer 1 vie.',
  one_new_life: '1 nouvelle vie',
}


export default games
