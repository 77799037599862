import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from './ModalWrapper/Modal'
// import { useNavigate } from 'react-router-dom'
import SearchAccount from '../FeedSearch/Account'
import SearchBar from '../Controls/SearchBar'

const ModalSponsorCode = ({
  children: button = () => null,
  handleAddSponsorId,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  const onAddSponsorId = item => {
    handleAddSponsorId(prev => {
      return { ...prev, sponsorId: item.id }
    })
    setSearch('')
    setIsOpen(false)
  }

  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        // loading={loading}
        onDismiss={() => setIsOpen(false)}
        title={t('modal.sponsor.title')}
        subtitle={t('modal.sponsor.subtitle')}
        keyboardShouldPersistTaps="always"
      >
        <SearchBar
          placeholder={t('modal.sponsor.placeholder')}
          value={search}
          onChange={setSearch}
        />
        <div className="h-96">
          <SearchAccount search={search} onClick={onAddSponsorId} />
        </div>
      </Modal>
    </>
  )
}

export default ModalSponsorCode
