import * as React from 'react'
const SvgLineCamera = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 40})`}>
      <path
        fill="#EBF8EB"
        fillRule="evenodd"
        d="M19.877 0h.246c4.186 0 7.735.835 10.669 2.404a16.36 16.36 0 0 1 6.804 6.804C39.165 12.142 40 15.691 40 19.878v.245c0 4.186-.836 7.735-2.404 10.669a16.36 16.36 0 0 1-6.804 6.804C27.858 39.165 24.309 40 20.122 40h-.245c-4.186 0-7.735-.836-10.669-2.404a16.36 16.36 0 0 1-6.804-6.804C.835 27.858 0 24.309 0 20.122v-.245c0-4.186.835-7.735 2.404-10.669a16.36 16.36 0 0 1 6.804-6.804C12.142.835 15.691 0 19.878 0"
        clipRule="evenodd"
      />
      <path
        stroke="#7ACB8B"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.44 13.5c.374-1.582 1.826-2.762 3.56-2.762s3.186 1.18 3.56 2.762l.155.031c2.385.486 4.285 2.245 4.907 4.545.504 1.867.504 3.83 0 5.696-.622 2.3-2.522 4.06-4.907 4.545l-.456.093c-2.15.437-4.369.437-6.518 0l-.456-.093c-2.385-.486-4.285-2.245-4.906-4.545a10.9 10.9 0 0 1 0-5.696c.621-2.3 2.52-4.06 4.906-4.545z"
      />
      <path
        stroke="#7ACB8B"
        strokeWidth={1.5}
        d="M17.5 20.738a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
      />
    </g>
  </svg>
)
export default SvgLineCamera
