import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import ModalDownloadApp from '@/Components/Modals/ModalDownloadApp'
import { useTranslation } from 'react-i18next'
const ModalDownloadAppContext = createContext()

export const ModalDownloadAppProvider = ({ children }) => {
  const { t } = useTranslation()
  const [modalDownloadApp, setModalDownloadApp] = useState(null)

  const openModalDownloadApp = useCallback(({ title, subtitle }) => {
    setModalDownloadApp({ title, subtitle })
  }, [])

  const closeModalDownloadApp = () => {
    setModalDownloadApp(null)
  }

  const value = useMemo(() => {
    return {
      openModalDownloadApp,
    }
  }, [openModalDownloadApp])

  return (
    <ModalDownloadAppContext.Provider value={value}>
      <ModalDownloadApp
        isOpen={modalDownloadApp}
        onDismiss={closeModalDownloadApp}
        title={modalDownloadApp?.title || ''}
        subtitle={modalDownloadApp?.subtitle || ''}
        btnLabel={t('modal_download_app.btn_label')}
      />
      {children}
    </ModalDownloadAppContext.Provider>
  )
}

export function useModalDownloadAppContext() {
  return useContext(ModalDownloadAppContext) || {}
}
