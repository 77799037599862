import TextArea from '@/Components/Controls/TextArea'
import { useTheme } from '@/Hooks'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useUploadImage from '@/Hooks/useUploadImage'
import {
  useAddImageByUidMutation,
  useEditImageByUidMutation,
} from '@/Services/modules/users'
import { updateCurrentUser } from '@/Store/User'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Panel } from '../PanelWrapper/Panel'
import ModalImageOptions from '@/Components/Modals/ModalImageOptions/ModalImageOptions'
import { LineGallery, LineTrash } from '@/Assets/icons/components'
import ImageHashtagField from './ImageHashtagfield'
import ModalImageToFeed from '@/Components/Modals/ModalImageToFeed'
import ButtonsFooter from '@/Components/ButtonsFooter'
import ModalImageDeleteConfirmation from '@/Components/Modals/ModalImageDeleteConfirmation'
import IconButton from '@/Components/Buttons/IconButton'
import { TouchableOpacity } from '@/Components/Buttons/TouchableOpacity'
import useCurrentMember from '@/Hooks/useCurrentMember'

const ImageWrapper = styled(TouchableOpacity)`
  ${({ theme }) => css`
    width: ${theme.size.fill};
    height: 135px;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.space.std};
  `}
`

const Image = styled.img`
  ${({ theme }) => css`
    height: ${theme.size.l2};
    aspect-ratio: 1;
    border-radius: ${theme.borderRadius.s};
  `}
`

const PanelAddImageToGallery = ({ selectedImage = null, children: button }) => {
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const [image, setImage] = useState({
    src: selectedImage?.src || '',
    description: selectedImage?.description || '',
    tags: selectedImage?.tags || [],
    userId: user.uid,
  })
  const dispatch = useDispatch()
  const { uploadImage } = useUploadImage()
  const { theme } = useTheme()
  const { t } = useTranslation()
  const [imageToUpload, setImageToUpload] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isModalImageToFeedOpen, setIsModalImageToFeedOpen] = useState(false)
  const [addImageByUid] = useAddImageByUidMutation()
  const [editImageByUid] = useEditImageByUidMutation()

  const addImage = async newImage => {
    try {
      await addImageByUid({
        uid: user.uid,
        image: newImage,
      })
      const gallery = [...user.gallery, newImage]
      dispatch(updateCurrentUser({ gallery }))
    } catch (error) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: error?.message || t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }

  const updateImage = async newImage => {
    try {
      await editImageByUid({ uid: user.uid, image: newImage })
      const gallery = [
        ...user.gallery.filter(img => img.src !== selectedImage.src),
        newImage,
      ]
      dispatch(updateCurrentUser({ gallery }))
    } catch (error) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: error.message || t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }

  const onSubmit = async () => {
    try {
      const rank = selectedImage?.rank || null
      const postAt = imageToUpload ? Date.now() : selectedImage.postAt
      const { id, src } = imageToUpload
        ? await uploadImage(imageToUpload)
        : { id: selectedImage.id, src: selectedImage.src }
      const newImage = { ...image, id, src, postAt, rank }

      if (selectedImage) {
        await updateImage({ ...newImage, id: selectedImage.id })
        setImage({
          src: newImage.src,
          description: newImage.description,
          tags: newImage.tags,
        })
      } else {
        await addImage(newImage)
      }
      setImageToUpload(null)
      setIsOpen(false)
      // TODO TOAST
      // Toast.show({
      //   type: 'success',
      //   text1: t('toastModalImage.success'),
      // })
      setIsModalImageToFeedOpen(true)
    } catch (err) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: err.message || t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }

  useEffect(() => {
    if (imageToUpload) {
      setIsOpen(true)
    }
  }, [imageToUpload])

  const handleDismiss = () => {
    setImage({
      src: selectedImage?.src || '',
      description: selectedImage?.description || '',
      tags: selectedImage?.tags || [],
      userId: user.uid,
    })
    setImageToUpload(null)
    setIsOpen(false)
  }

  return (
    <>
      {button(setIsOpen)}
      <Panel
        title={
          selectedImage
            ? t('profilepage.galerie.edit_image_panel_title')
            : t('profilepage.galerie.add_image_panel_title')
        }
        isOpen={isOpen}
        onDismiss={handleDismiss}
        fullscreen
        actions={[
          {
            label: t('profilepage.galerie.edit_photo.save'),
            onClick: onSubmit,
          },
        ]}
      >
        {selectedImage && (
          <ModalImageDeleteConfirmation
            selectedImage={selectedImage}
            onDismiss={() => setIsOpen(false)}
          >
            {setIsModalImageDeleteConfirmationOpen => (
              <div className="w-10">
                <IconButton
                  icon={<LineTrash color={theme.colors.black} />}
                  onClick={() => {
                    setIsModalImageDeleteConfirmationOpen(true)
                  }}
                />
              </div>
            )}
          </ModalImageDeleteConfirmation>
        )}
        <div className="mb-20">
          <ModalImageOptions
            type={'gallery'}
            additionalOptions={{ imageAI: true }}
            onDismiss={() => setIsOpen(false)}
            onChange={(path, modalRef) => {
              setImage({ ...image, src: path })
              setImageToUpload(path)
              setIsOpen(false)
            }}
            format={{
              width: 800,
              height: 800,
            }}
          >
            {setIsOpen => (
              <ImageWrapper onClick={() => setIsOpen(true)}>
                {image.src ? (
                  <Image src={image.src} />
                ) : (
                  <LineGallery color={theme.colors.black} />
                )}
              </ImageWrapper>
            )}
          </ModalImageOptions>
          <div className="mt-5">
            <TextArea
              placeholder={t('edit_profil.informations.my_infos.description')}
              value={image.description}
              onChange={text => setImage({ ...image, description: text })}
              maxLength={2000}
              copyable
            />
          </div>
          <div className="mt-2">
            <ImageHashtagField image={image} setImage={setImage} insideModal />
          </div>

          <ModalImageToFeed
            isOpen={isModalImageToFeedOpen}
            onDismiss={() => setIsModalImageToFeedOpen(false)}
            image={image}
            setImage={setImage}
          />
        </div>
      </Panel>
    </>
  )
}

export default PanelAddImageToGallery
