import { useGetBusinessCardTemplatesByWhitelabelQuery } from '@/Services/modules/businessCards'
import { Config } from '@/Config'
import { useCartContext } from '../../Contexts/CartContext'
import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useCallback } from 'react'
import FlatList from '@/Components/FlatList'
import templates from '@/Components/BusinessCardPreview/templates'
import CostBadge from '@/Components/Activity/components/CostBadge'
import { useEditBusinessCard } from '../../Contexts/EditBusinessCardContext'

export const TemplateBusinessCard = () => {
  const {
    defaultBusinessCard,
    selectedBusinessCard,
    editBusinessCard,
    setEditBusinessCard,
  } = useEditBusinessCard()
  const { data: businessCardTemplates } =
    useGetBusinessCardTemplatesByWhitelabelQuery(Config.SLUG)
  const { addItemToCart } = useCartContext()
  const { 'card-template': cosmetics } = useCosmetics()

  const onSelected = useCallback(
    (item, cosmetic) => {
      setEditBusinessCard({
        ...editBusinessCard,
        template: item.slug,
      })
      addItemToCart(cosmetic)
    },
    [editBusinessCard, setEditBusinessCard, addItemToCart],
  )

  return (
    <div className="flex flex-1">
      <div className="px-4 pb-20 flex-1">
        <FlatList
          data={templates
            .filter(template => businessCardTemplates?.includes(template.slug))
            .map((item, index) => ({ ...item, index }))}
          renderItem={({ item }) => {
            const selected = item.slug === editBusinessCard.template
            const cosmetic = cosmetics.find(({ value }) => value === item.slug)
            return (
              <div className="relative mb-2">
                <item.selectTemplate
                  item={item}
                  onSelected={i => onSelected(i, cosmetic)}
                  selected={selected}
                />
                <div className="absolute top-2 right-2">
                  <CostBadge
                    isAcquired={cosmetic.isAcquired}
                    isUsed={
                      cosmetic.value ===
                      (selectedBusinessCard?.template ||
                        defaultBusinessCard.template)
                    }
                    price={cosmetic.price}
                    forceBg
                  />
                </div>
              </div>
            )
          }}
          keyExtractor={item => item.index}
        />
      </div>
    </div>
  )
}
