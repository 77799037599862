import activity_companion from './slices/activity_companion'
import activity_stats from './slices/activity_stats'
import activitypage from './slices/activitypage'
import benefitspage from './slices/benefitspage'
import businessCard from './slices/businessCard'
import businessCard_placeholder from './slices/businessCard_placeholder'
import casino from './slices/casino'
import companion_descriptions from './slices/companion_descriptions'
import companion_yields from './slices/companion_yields'
import create_image_AI_page from './slices/create_image_AI_page'
import customFallback from './slices/customFallback'
import discover_companions from './slices/discover_companions'
import discoverpage from './slices/discoverpage'
import edit_image_AI_page from './slices/edit_image_AI_page'
import edit_profil from './slices/edit_profil'
import feedpage from './slices/feedpage'
import follow from './slices/follow'
import games from './slices/games'
import games_page from './slices/games_page'
import generate_style_page from './slices/generate_style_page'
import hashtagsPage from './slices/hashtagsPage'
import homelogin from './slices/homelogin'
import image_style_page from './slices/image_style_page'
import items from './slices/items'
import modal from './slices/modal'
import my_offers_tab from './slices/my_offers_tab'
import negative_prompt_explanation from './slices/negative_prompt_explanation'
import no_internet_connexion from './slices/no_internet_connexion'
import notifications from './slices/notifications'
import onboarding from './slices/onboarding'
import others from './slices/others'
import parametre from './slices/parametre'
import plantation_details from './slices/plantation_details'
import post_details from './slices/post_details'
import previewpage from './slices/previewpage'
import profilepage from './slices/profilepage'
import promotions_page from './slices/promotions_page'
import prompt_suggestion_page from './slices/prompt_suggestion_page'
import publication_modal from './slices/publication_modal'
import publication_rules from './slices/publication_rules'
import quests_titles from './slices/quests_titles'
import reported_page from './slices/reported_page'
import reward_modal from './slices/reward_modal'
import security from './slices/security'
import seed_game_explanation_page from './slices/seed_game_explanation_page'
import share from './slices/share'
import share_profil from './slices/share_profil'
import shop from './slices/shop'
import signin from './slices/signin'
import signup from './slices/signup'
import timer from './slices/timer'
import toasts from './slices/toasts'
import tutorialModal from './slices/tutorialModal'
import verification from './slices/verification'
import version_check_modal from './slices/version_check_modal'
import yield_explanation_page from './slices/yield_explanation_page'
import parametersMenu from './slices/parametersMenu'
import accountPage from './slices/accountPage'
import seoPage from './slices/seoPage'
import download_app from './slices/download_app'
import form_submit_button_error from './slices/form_submit_button_error'
import get_by_csv from './slices/get_by_csv'
import modal_confirm_change_tab from './slices/modal_confirm_change_tab'
import modal_download_app from './slices/modal_download_app'
import teams_page from './slices/teams_page'
import carousel from './slices/carousel'
import statistics from './slices/statistics'
import tooltip from './slices/tooltip'
import referral from './slices/referral'

const fr = {
  ...toasts,
  others,
  security,
  signup,
  signin,
  verification,
  profilepage,
  previewpage,
  feedpage,
  discoverpage,
  activitypage,
  casino,
  games,
  activity_stats,
  activity_companion,
  yield_explanation_page,
  post_details,
  plantation_details,
  quests_titles,
  shop,
  businessCard,
  businessCard_placeholder,
  share,
  publication_modal,
  reward_modal,
  modal,
  share_profil,
  edit_profil,
  benefitspage,
  parametre,
  hashtagsPage,
  follow,
  onboarding,
  items,
  homelogin,
  promotions_page,
  my_offers_tab,
  timer,
  version_check_modal,
  seed_game_explanation_page,
  publication_rules,
  games_page,
  reported_page,
  create_image_AI_page,
  prompt_suggestion_page,
  negative_prompt_explanation,
  image_style_page,
  generate_style_page,
  edit_image_AI_page,
  notifications,
  no_internet_connexion,
  companion_yields,
  discover_companions,
  companion_descriptions,
  tutorialModal,
  customFallback,
  parametersMenu,
  accountPage,
  seoPage,
  download_app,
  form_submit_button_error,
  get_by_csv,
  modal_confirm_change_tab,
  modal_download_app,
  teams_page,
  carousel,
  statistics,
  tooltip,
  referral,
}

export default fr
