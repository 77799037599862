const casino = {
  coins_left: 'Coins left',
  slots_budly: {
    no_free_slot: 'No free slot available',
    in_progress: 'IN PROGRESS',
    spin: 'SPIN',
    starting_bet: 'Starting bet',
    win_multiple: 'Win multiple',
    each_spin_offers_a_chance_to_win: 'Each spin offers a chance to win!',
    ready_go_jackpot: 'Ready, go, jackpot!',
    hit_the_jackpot_today: 'Hit the jackpot today!',
    start_a_new_game: 'Start a new game.',
    no_gain_this_time: 'No gain this time.',
    its_not_your_lucky_day: "It's not your lucky day.",
    luck_was_not_on_your_side: 'Luck was not on your side.',
    you_have_won: 'You have won!',
    well_done_you_won: 'Well done, you won!',
    absolute_triumph: 'Absolute triumph!',
    luck_is_on_your_side: 'Luck is on your side!',
    small_bundle_of_resources: 'Small bundle of resources',
    large_bundle_of_resources: 'Large bundle of resources',
    jackpot: 'Jackpot',
    turn_of_luck: 'Turn of luck',
    a_large_bundle_of_resources_garanteed:
      'A large bundle of resources guaranteed every {{frequency}} draws',
    chance_of_winning: 'chance of winning',
    multiple: 'multiple',
    all_multiples: 'All multiples',
    try_your_luck:
      'Try your luck at the Budly slot machine to accumulate a maximum resources!',
    winning_percentage_details: 'Winning percentage details',
    chances_of_winning_increase_by:
      'In addition to multiplying your earnings, the additional multiples increase your chances of winning by {{increase}}.',
    gains: 'gains',
    for_each_resources: '{{quantity}} for each resource',
  },
}


export default casino
