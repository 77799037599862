import { ResourceIconImage } from '../ResourceIcon'
import React from 'react'

const type = 'ticket'

const ICON_URL = require('@/Assets/images/activity/ticket.webp')
const ICON_URL_5 = require('@/Assets/images/activity/ticket-5.webp')
const ICON_URL_50 = require('@/Assets/images/activity/ticket-50.webp')
const ICON_URL_200 = require('@/Assets/images/activity/ticket-200.webp')

const Img = ({ src, ...props }) => (
  <img
    className="h-10 w-10"
    resizeMode="contain"
    src={src}
    alt="src"
    {...props}
  />
)
const Icon = props => <ResourceIconImage src={ICON_URL} {...props} />

const getLabel = t => t('shop.ticket')

const getFreeOffers = t => [
  {
    subtitle: t('shop.free_subtitle', { resource: getLabel(t) }),
    quantity: 1,
    type,
    image: () => <Img src={ICON_URL} />,
  },
]

const getOffers = t => [
  {
    subtitle: t('shop.five_seeds_pack_subtitle'),
    quantity: 5,
    image: () => <Img src={ICON_URL_5} />,
    type,
    priceId: 'price_1P6WbaGhdaiFJMYlcHElOdsZ',
  },
  {
    subtitle: t('shop.fifty_seeds_pack_subtitle'),
    quantity: 50,
    image: () => <Img src={ICON_URL_50} />,
    type,
    priceId: 'price_1P6WcBGhdaiFJMYl7SPVgs79',
  },
  {
    subtitle: t('shop.two_hundred_seeds_pack_subtitle'),
    quantity: 200,
    image: () => <Img src={ICON_URL_200} />,
    type,
    priceId: 'price_1P6WcpGhdaiFJMYlOMmjaPUo',
  },
]

export const ticket = {
  type,
  getLabel,
  icon: Icon,
  getFreeOffers,
  getOffers,
  isPrimary: false,
  isConsumable: true,
}
