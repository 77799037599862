import React from 'react'
import styled, { css } from 'styled-components'
import { Comments } from './Comments'
import { useResizeContext } from '@/ResizeProvider'

const GradientContainer = styled.div`
  ${({ theme }) => css`
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1),
      rgba(0, 0, 0, 0.2)
    );
    height: 324px;
    padding-top: 96px;
    border-radius: ${theme.space.none} ${theme.space.none}
      ${theme.borderRadius.l} ${theme.borderRadius.l};
    overflow: hidden;
  `}
`
const Glass = styled.div`
  ${({ theme }) => css`
    height: 100%;
    backdrop-filter: blur(10px);
    border-radius: ${theme.space.none} ${theme.space.none}
      ${theme.borderRadius.l} ${theme.borderRadius.l};
    padding: ${theme.space.xxl};
  `};
`
export const GlassComments = () => {
  const { isSmallWidth } = useResizeContext()
  if (isSmallWidth) {
    return null
  }

  return (
    <GradientContainer>
      <Glass>
        <Comments />
      </Glass>
    </GradientContainer>
  )
}
