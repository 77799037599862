import * as React from 'react'
const SvgLineMessage3 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 40})`}>
      <path
        fill="#EBF8EB"
        fillRule="evenodd"
        d="M19.877 0h.246c4.186 0 7.735.835 10.669 2.404a16.36 16.36 0 0 1 6.804 6.804C39.165 12.142 40 15.691 40 19.878v.245c0 4.186-.836 7.735-2.404 10.669a16.36 16.36 0 0 1-6.804 6.804C27.858 39.165 24.309 40 20.122 40h-.245c-4.186 0-7.735-.836-10.669-2.404a16.36 16.36 0 0 1-6.804-6.804C.835 27.858 0 24.309 0 20.122v-.245c0-4.186.835-7.735 2.404-10.669a16.36 16.36 0 0 1 6.804-6.804C12.142.835 15.691 0 19.878 0"
        clipRule="evenodd"
      />
      <path
        fill="#7ACB8B"
        d="m12.68 21.881.73-.171zm0-5.64-.73-.171zm15.994 0-.73.171zm0 5.64.73.171zm-5.177 5.177-.171-.73zm-5.64 0 .171-.73zm0-15.994.171.73zm5.64 0-.171.73zM13.361 23.651l.657-.362-.232-.42-.479.034zm2.726 2.726.747.054.035-.479-.42-.232zm1.025-9.594a.75.75 0 0 0 0 1.5zm3.056 1.5a.75.75 0 0 0 0-1.5zM18.13 19.84a.75.75 0 1 0 0 1.5zm5.094 1.5a.75.75 0 1 0 0-1.5zm-9.814.37a11.6 11.6 0 0 1 0-5.297l-1.46-.342a13.1 13.1 0 0 0 0 5.982zm14.534-5.297a11.6 11.6 0 0 1 0 5.298l1.46.342a13.1 13.1 0 0 0 0-5.982zm-4.618 9.916a11.6 11.6 0 0 1-5.298 0l-.343 1.46c1.968.462 4.016.462 5.983 0zm-5.298-14.534a11.6 11.6 0 0 1 5.298 0l.342-1.46a13.1 13.1 0 0 0-5.983 0zm0 14.534a6.2 6.2 0 0 1-4.618-4.618l-1.46.342a7.7 7.7 0 0 0 5.735 5.736zm5.64 1.46a7.7 7.7 0 0 0 5.736-5.736l-1.46-.342a6.2 6.2 0 0 1-4.618 4.618zm-.342-15.994a6.2 6.2 0 0 1 4.618 4.618l1.46-.342a7.7 7.7 0 0 0-5.736-5.736zm-5.64-1.46a7.7 7.7 0 0 0-5.736 5.736l1.46.342a6.2 6.2 0 0 1 4.618-4.618zm-4.379 12.57a3.297 3.297 0 0 0-3.057 3.287h1.5c0-.947.735-1.724 1.665-1.791zM10.25 26.19a3.297 3.297 0 0 0 3.297 3.297v-1.5a1.796 1.796 0 0 1-1.797-1.797zm3.297 3.297a3.297 3.297 0 0 0 3.287-3.057l-1.496-.108a1.797 1.797 0 0 1-1.791 1.665zm2.902-3.768a6.2 6.2 0 0 1-2.431-2.431l-1.314.725a7.7 7.7 0 0 0 3.02 3.02zm.663-7.437h3.056v-1.5h-3.056zm1.018 3.056h5.094v-1.5H18.13z"
      />
    </g>
  </svg>
)
export default SvgLineMessage3
