import { SeedCoinReward } from '../../Mascot/coin-variants'
import { ResourceIconImage } from '../ResourceIcon'
import React from 'react'

const type = 'coin'

const Icon = props => (
  <ResourceIconImage
    {...props}
    src={require('@/Assets/images/activity/coinCm.webp')}
  />
)

const getLabel = t => t('shop.coin')

const getFreeOffers = t => [
  {
    subtitle: t('shop.free_subtitle', { resource: getLabel(t) }),
    quantity: 1,
    type,
    image: SeedCoinReward,
  },
]

const Img = ({ src }) => <img className="h-10 w-10" src={src} alt="src" />
const getOffers = t => [
  {
    subtitle: t('shop.five_seeds_pack_subtitle'),
    quantity: 5,
    image: () => (
      <Img src={require('@/Assets/images/activity/MultipleCoins.webp')} />
    ),
    type,
    priceId: 'price_1P6WP8GhdaiFJMYlFQRAHgWO',
  },
  {
    subtitle: t('shop.fifty_seeds_pack_subtitle'),
    quantity: 50,
    image: () => (
      <Img src={require('@/Assets/images/activity/HeapofCoins.webp')} />
    ),
    type,
    priceId: 'price_1P6WQCGhdaiFJMYlDMnfheiB',
  },
  {
    subtitle: t('shop.two_hundred_seeds_pack_subtitle'),
    quantity: 200,
    image: () => (
      <Img src={require('@/Assets/images/activity/SackOfCoins.webp')} />
    ),
    type,
    priceId: 'price_1P6WRdGhdaiFJMYleerRPz0Z',
  },
]

export const coin = {
  type,
  getLabel,
  icon: Icon,
  getFreeOffers,
  getOffers,
  isPrimary: true,
  isConsumable: true,
}
