import { LineLink } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled, { css } from 'styled-components'
import { TouchableOpacity } from './Buttons/TouchableOpacity'
import Text from './Texts/Text'

const Card = styled(TouchableOpacity)`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.m};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.space.m};
  `}
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.fontSize.xs};
    color: ${theme.colors.black};
  `}
`

const ContentCopy = ({ text, ...props }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    toast.info(t('toastText.copy_Link'))
  }

  return (
    <Card {...props} onClick={handleCopy}>
      <div className="flex flex-row items-center shrink pr-8">
        <LineLink className="mr-1" />
        <StyledText className="truncate">{text}</StyledText>
      </div>
      <Text color={theme.colors.primary}>
        {t('parametre.share.referral.copy')}
      </Text>
    </Card>
  )
}

export default ContentCopy
