import HashtagField from '@/Components/Controls/HashtagField'
import React from 'react'

const ImageHashtagField = ({ image, setImage, ...props }) => {
  const handleChange = values => {
    setImage({
      ...image,
      tags: values,
    })
  }

  return (
    <HashtagField
      {...props}
      hashtags={image.tags || []}
      onChange={handleChange}
    />
  )
}

export default ImageHashtagField
