const reported_page = {
  i_understood: "J'ai compris",
  been_reported: 'Vous avez été signalé !',
  it_is_important: {
    main_text:
      'Il est important que vous respectiez toutes nos règles pour continuer à utiliser Budly dans les meilleures conditions !',
  },
  accept_our_rules:
    'En continuant, vous acceptez nos règles de bonne conduite et vous préservez ainsi la qualité de notre service.',
  good_behavior: {
    be_respectfull: 'Soyez Respectueux',
    Insults_racist:
      'Les insultes, les propos racistes, homophobes, sexistes, ou tout autre propos discriminatoire sont interdits.',
    be_honest: 'Soyez Honnête',
    fake_profiles:
      'Les faux profils, les photos de célébrités ou de personnes qui ne sont pas vous sont interdits.',
    be_courteous: 'Soyez Courtois',
    request_for_personal:
      'Les demandes de coordonnées personnelles, les demandes de services sexuels ou tout autre comportement inapproprié sont interdits.',
    be_yourself: 'Soyez vous-même',
    photo_of_landscapes:
      'Les photos de paysages, de fleurs ou de tout autre élément qui ne vous représentent pas sont interdites.',
  },
}

export default reported_page
