// import { indexes } from '../../../algolia'
// import Empty from '../Empty'
// import ProfileItem from '../ProfileItem'
import { indexes } from '@/algolia'
// import { useTheme } from '@/Hooks'
// import { navigationRef } from '@/Navigators/utils'
import React, { useMemo } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { ActivityIndicator } from 'react-native'
// import { ScrollView } from 'react-native-gesture-handler'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import ProfileItem from '../ProfileItem'

const Wrapper = styled.div`
  ${({ theme }) => css`
    border-bottom-width: 2px;
    border-bottom-color: ${theme.colors.grey1};
  `}
`

// const redirect = item => {
//   navigationRef.navigate('ProfileModal', {
//     profileUID: item.uid,
//   })
// }

const Account = ({ search, onClick = () => null }) => {
  //   const { t } = useTranslation()
  const [searchResults, setSearchResults] = useState([])
  const { currentUser } = useSelector(state => state.user)
  const [isLoading, setIsLoading] = useState(false)

  const filter = useMemo(
    () => `whiteLabel:${currentUser?.whiteLabel}`,
    [currentUser?.whiteLabel],
  )
  //   const { theme } = useTheme()

  useEffect(() => {
    const getResults = async () => {
      if (!search) {
        setSearchResults([])
        setTimeout(() => {
          setIsLoading(false)
        }, 225)
        return
      }
      setIsLoading(true)
      const results = search
        ? await indexes.users.search(search, {
            filters: filter,
          })
        : await indexes.users.search(search, {
            filters: `${filter} AND emptySearch = 1`,
          })
      setSearchResults(results.hits)
      setTimeout(() => {
        setIsLoading(false)
      }, 225)
    }

    getResults()
  }, [search, filter])

  //   if (isLoading) {
  //     return <ActivityIndicator color={theme.colors.primary} size="large" />
  //   }

  //   if (search && searchResults.length === 0) {
  //     return (
  //       <Empty
  //         title={t('discoverpage.empty_results.no_user_found')}
  //         subtitle={t('discoverpage.empty_results.no_results_matched')}
  //       />
  //     )
  //   }

  return (
    <>
      {searchResults.map((item, indexes) => {
        return (
          <Wrapper
            key={indexes}
            onClick={
              () => console.log('navigate profile')
              //   navigationRef.navigate('ProfileModal', {
              //     profileUID: item.uid,
              //   })
            }
          >
            <ProfileItem
              key={indexes}
              profile={item}
              onClick={() => onClick(item)}
            />
          </Wrapper>
        )
      })}
    </>
  )
}

export default Account
