import * as React from 'react'
const SvgLineGallery1 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 40})`}>
      <path
        fill="#EBF8EB"
        fillRule="evenodd"
        d="M19.877 0h.246c4.186 0 7.735.835 10.669 2.404a16.36 16.36 0 0 1 6.804 6.804C39.165 12.142 40 15.691 40 19.878v.245c0 4.186-.836 7.735-2.404 10.669a16.36 16.36 0 0 1-6.804 6.804C27.858 39.165 24.309 40 20.122 40h-.245c-4.186 0-7.735-.836-10.669-2.404a16.36 16.36 0 0 1-6.804-6.804C.835 27.858 0 24.309 0 20.122v-.245c0-4.186.835-7.735 2.404-10.669a16.36 16.36 0 0 1 6.804-6.804C12.142.835 15.691 0 19.878 0"
        clipRule="evenodd"
      />
      <rect
        width={4}
        height={4}
        x={21}
        y={14.738}
        stroke="#7ACB8B"
        strokeWidth={1.5}
        rx={2}
      />
      <path
        stroke="#7ACB8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m12.718 24.94 1.739-1.74a4.16 4.16 0 0 1 5.885 0l1.739 1.74m0 0 .704-.705a3.12 3.12 0 0 1 4.604.209l.413.495m-5.721 0 2.6 2.601m-13.328-4.752a13.35 13.35 0 0 1 0-6.1 7.51 7.51 0 0 1 5.597-5.597c2.006-.47 4.094-.47 6.1 0a7.51 7.51 0 0 1 5.597 5.597c.47 2.006.47 4.094 0 6.1a7.51 7.51 0 0 1-5.597 5.597c-2.006.47-4.094.47-6.1 0a7.51 7.51 0 0 1-5.597-5.597"
      />
    </g>
  </svg>
)
export default SvgLineGallery1
