import { renderResourceBadges } from '../Resources/utils'
import React from 'react'
import { useSelector } from 'react-redux'
import { useGetAvailableResourcesByUidQuery } from '@/Services/modules/availableResources'

const Resources = ({ types }) => {
  const { currentUser } = useSelector(state => state.user)
  const { data: availableResources } = useGetAvailableResourcesByUidQuery(
    currentUser.uid,
    {
      pollingInterval: 4000,
    },
  )

  if (!availableResources) {
    return null
  }

  return (
    <div className="flex gap-2">
      {renderResourceBadges(availableResources, types)}
    </div>
  )
}

export default Resources
