import * as React from 'react'
const SvgLineIncrease = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        stroke="#7ACB8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.077 8.43H20m0 0v4.923m0-4.923-6.954 6.954a.616.616 0 0 1-.861 0l-2.831-2.83a.615.615 0 0 0-.862 0L4 17.045"
      />
    </g>
  </svg>
)
export default SvgLineIncrease
