import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgQrcodeRounded = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      {...props}
    >
      <g transform={`scale(${(props.size || 24) / 200})`}>
        <rect
          width={100}
          height={100}
          x={50}
          y={50}
          fill="#7ACB8B"
          stroke={props.color || theme?.colors?.black || '#212121'}
          rx={20}
          ry={20}
        />
      </g>
    </svg>
  )
}
export default SvgQrcodeRounded
