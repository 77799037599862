import * as React from 'react'
import { useTheme } from '@/Hooks'
const SvgQrcodeSquare = props => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200pt"
      height="200pt"
      viewBox="0 0 200 200"
      {...props}
    >
      <g transform={`scale(${1})`}>
        <path
          fill="#7ACB8B"
          stroke={props.color || theme?.colors?.black || '#212121'}
          d="M50 50h100v100H50zm0 0"
        />
      </g>
    </svg>
  )
}
export default SvgQrcodeSquare
