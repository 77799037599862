import styled, { css } from 'styled-components'
import { useTheme } from '@/Hooks'
import ModalEditOrDelete from '../Modals/ModalEditOrDelete'
import { LineAdd, Profile } from '@/Assets/icons/components'
import { clsx } from 'clsx'
import ButtonEditable from '../Buttons/ButtonEditable'

const Wrapper = styled.div`
  ${({ theme, disabled }) => css`
    height: ${props => parseInt(props.size, 10)}px;
    width: ${props => parseInt(props.size, 10)}px;
    border: ${props =>
      props.border ? `2px solid ${theme.colors.white}` : 'none'};
    position: relative;
    pointer-events: ${disabled ? 'none' : 'auto'};
  `}
`

const InnerAvatarLogo = styled(ButtonEditable)`
  ${({ theme }) => css`
    position: absolute !important;
    bottom: 0;
    right: 0;
    height: ${props => parseInt(props.size, 10) / 3}px;
    width: ${props => parseInt(props.size, 10) / 3}px;
    border-radius: ${props => parseInt(props.size, 10) / 6}px;
    border: 1px solid ${theme.colors.white};
    overflow: hidden;
    background: ${theme.colors.grey1};
  `}
`

const shapes = {
  shape1: 'rounded-full',
  shape2: 'rounded-none',
  shape3: 'rounded-lg',
  shape4: 'rounded-t-md rounded-b-3xl',
}

const Touchable = styled(ButtonEditable)`
  ${({ theme }) => css`
    background: ${theme.colors.grey1};
    width: 100%;
    height: 100%;
  `}
`

export const AvatarImage = ({ photo, border, shape, onClick, disabled }) => {
  const { theme } = useTheme()
  return (
    <ModalEditOrDelete
      type="photo"
      additionalOptions={{
        imageAI: true,
      }}
    >
      {props => (
        <Touchable
          {...props}
          fieldName="photo"
          onClick={onClick || props.onClick}
          className={clsx(
            'flex-1 border-white flex items-center justify-center ',
            shapes[shape],
            border && 'border',
          )}
          disabled={disabled}
        >
          {photo ? (
            <img
              className="w-full h-full object-cover"
              src={photo}
              alt="avatar"
            />
          ) : (
            <Profile color={theme.colors.grey2} />
          )}
        </Touchable>
      )}
    </ModalEditOrDelete>
  )
}

const AvatarLogo = ({ logo, showEmptyLogo, size }) => {
  const { theme } = useTheme()

  if (!logo && showEmptyLogo) {
    return (
      <ModalEditOrDelete
        type="logo"
        additionalOptions={{
          imageAI: true,
        }}
      >
        {props => {
          return (
            <InnerAvatarLogo
              {...props}
              fieldName="logo"
              size={size}
              className="flex items-center justify-center"
            >
              <LineAdd color={theme.colors.grey2} />
            </InnerAvatarLogo>
          )
        }}
      </ModalEditOrDelete>
    )
  }
  if (logo) {
    return (
      <ModalEditOrDelete
        type="logo"
        additionalOptions={{
          imageAI: true,
        }}
      >
        {props => (
          <InnerAvatarLogo {...props} size={size}>
            <img className="flex-1" src={logo} alt="logo" />
          </InnerAvatarLogo>
        )}
      </ModalEditOrDelete>
    )
  }

  return null
}

export const Avatar = ({
  profile,
  shape = 'shape1',
  border = true,
  size,
  showEmptyLogo,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <Wrapper {...props} size={size}>
      <AvatarImage
        photo={profile.photoURL}
        border={border}
        shape={shape}
        onClick={onClick}
        disabled={disabled}
      />
      {!profile.businessMode && (
        <AvatarLogo
          logo={profile.logoURL}
          showEmptyLogo={showEmptyLogo}
          size={size}
        />
      )}
    </Wrapper>
  )
}
