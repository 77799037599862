const modal_download_app = {
  title: 'Great job ! Want the same profile ?',
  subtitle: 'Get Budly to create your own digital profile',
  btn_label: 'Get Budly',
  download_the_budly_app: 'Download the Budly App',
  explore_budly:
    "Explore Budly's unique features: plant trees, customize your profile, adopt companions, and much more!",
}

export default modal_download_app
