const modal_download_app = {
  title: '¡Buen! ¿Quieres el mismo perfil?',
  subtitle: 'Obtén Budly para crear tu propio perfil digital',
  btn_label: 'Obtén Budly',
  download_the_budly_app: 'Descarga la aplicación Budly',
  explore_budly:
    'Explora las características únicas de Budly: planta árboles, personaliza tu perfil, adopta compañeros y mucho más.',
}

export default modal_download_app
