import React, { useEffect, useState } from 'react'
import HashtagField from '../Controls/HashtagField'
import { LockedFieldSwitch } from '../LockedFieldSwitch'
import { useEditCurrentUser } from '../Dashboard/Contexts/EditProfileContext'
import { useLockedField } from '@/Hooks/useLockedField'

export const HashtagFieldLock = ({
  hashtags,
  onChange,
  disabled,
  name,
  children,
  type = 'user',
}) => {
  const { editCurrentUser } = useEditCurrentUser()
  const { onLocked } = useLockedField()
  const [isLocked, setIsLocked] = useState(
    editCurrentUser.lockedFields?.[type]?.includes(name) || false,
  )

  useEffect(() => {
    setIsLocked(editCurrentUser.lockedFields?.[type]?.includes(name))
  }, [name, editCurrentUser.lockedFields, type])

  return (
    <div className="relative">
      <HashtagField hashtags={hashtags} onChange={onChange} disabled={disabled}>
        {children}
      </HashtagField>
      <div className="absolute right-[3px] top-[4px] cursor-pointer">
        <LockedFieldSwitch
          variant="secondary"
          isLocked={isLocked}
          onChange={e =>
            onLocked({
              e: e,
              setIsLocked: setIsLocked,
              type: type,
              fieldName: name,
            })
          }
        />
      </div>
    </div>
  )
}
