import { LeftArrow, RightArrow } from '@/Assets/icons/components'
import { useTheme } from '@/Hooks'
import React from 'react'
const BUTTONS = [
  {
    icon: LeftArrow,
    className: 'mr-6 cursor-pointer hover:opacity-60',
    onChange: (setSlideIndex, FAKE_RATES) =>
      setSlideIndex(prev => (prev === 0 ? FAKE_RATES.length - 1 : prev - 1)),
  },
  {
    icon: RightArrow,
    className: 'cursor-pointer hover:opacity-60',
    onChange: (setSlideIndex, FAKE_RATES) =>
      setSlideIndex(prev => (prev === FAKE_RATES.length - 1 ? 0 : prev + 1)),
  },
]

export const IconButtons = ({ setSlideIndex, fakeRates }) => {
  const { theme } = useTheme()

  return (
    <div className="flex">
      {BUTTONS.map(({ icon: Icon, onChange, ...btnProps }) => (
        <Icon
          color={theme.colors.white}
          size={56}
          onClick={() => onChange(setSlideIndex, fakeRates)}
          {...btnProps}
        />
      ))}
    </div>
  )
}
