const referral = {
    title: 'afiliación',
    overview: "Visión general",
    stats: 'Estadísticas',
    bonus: 'Bono',
    portfolio: 'Cartera',
    copy: 'Copiar',
    overviewData: {
        bonusPool: "Bonus Pool",
        personnalCustomers: "Ventas activas personales",
        lastMonthRevenue: "Ingresos del mes pasado",
        totalRevenue: "Ingresos totales",
        salesThisMonth: "Ventas este mes",
        levelOne: "Nivel 1",
        levelTwo: "Nivel 2",
    },
    statsData: {
        newMonthlySell: "Nuevas ventas mensuales",
        monthlyActiveCustomer: "Clientes activos mensuales",
        monthlyRevenue: "Ingresos mensuales",
    },
    myTransactions: "Mis transacciones",
    noTransactions: "Sin transacciones",
    mySales: "Mis ventas",
    date: "Fecha",
    user: "Usuario",
    sellBonus: "Bono de venta",
    profit: "Beneficio",
    myPortfolio: "Mi cartera",
    connectMetamask: "Conectar con Metamask",
    receivedAt: "Recibido en",
    collected: "Recogido",
    fromYourSales: "De tus ventas",
    connectSequence: "Conectar con Sequence",
    connectWalletConnect: "Conectar con WalletConnect",
    connected: "Conectado",
    actionsBalance: "Saldo de acciones de Budly",
    salesBalance: "Saldo de ventas",
    disconnect: "Desconectar",
    myClients: "Mis clientes",
    bonusData: {
        qualificationRequirements: "Requisitos de calificación",
        qualificationRequirementsDescription: "Cada mes, puedes calificar para los 3 pools haciendo nuevas ventas. Cuando se envía el bono del pool, la cantidad se dividirá por el número de usuarios calificados.",
        make: "Hacer",
        newSalesCurrentMonth: "nuevas ventas este mes",
        missingSales: "ventas faltantes",
        validated: "validado",
        bronze: "Bronce",
        silver: "Plata",
        gold: "Oro",
        platinum: "Platino",
        unqualified: "No calificado",
    }
}

export default referral