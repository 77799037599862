import clsx from 'clsx'
import React from 'react'
// import CircularProgress from 'react-native-circular-progress-indicator'
import styled from 'styled-components'
import Text from './Texts/Text'
import { useGetHighestLevelCompanionQuery } from '@/Services/modules/companions'
import { Avatar } from './Profile/Avatar'
import { getCompanionByLevel } from './NFT/Resources/utils'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import { useTheme } from '@/Hooks'

const WrapperLvl = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: ${props => parseFloat(props.size, 10) * 0.35}px;
  aspect-ratio: 1;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => (parseFloat(props.size, 10) * 0.35) / 8}px;
`

const Lvl = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ size, lvl }) =>
    lvl > 99
      ? `${(parseFloat(size, 10) * 0.35) / 3}px`
      : `${(parseFloat(size, 10) * 0.35) / 2}px`};
  text-align: center;
`

const Companion = styled.img`
  height: ${props => parseFloat(props.size, 10) * 0.45}px;
  width: ${props => parseFloat(props.size, 10) * 0.45}px;
  border-radius: 999px;
  border: 1px solid white;
`

const AvatarWithProgress = ({
  profile,
  lvl,
  progress,
  size,
  displayCompanion,
}) => {
  const { theme } = useTheme()
  const { data: companion } = useGetHighestLevelCompanionQuery(profile?.uid, {
    refetchOnFocus: true,
    skip: !displayCompanion,
    pollingInterval: 30000,
  })
  const { avatar: companionAvatar } = getCompanionByLevel(companion?.level)
  return (
    <div className="relative flex flex-row  items-end  justify-end">
      <CircularProgressbarWithChildren
        value={progress}
        strokeWidth={6}
        styles={buildStyles({
          pathColor: theme.colors.primary,
          trailColor: theme.colors.grey1,
        })}
        className="w-[54px]"
      >
        <Avatar
          profile={profile}
          size={size}
          border={false}
          className="absolute"
        />
      </CircularProgressbarWithChildren>
      <WrapperLvl
        size={size}
        className={clsx('absolute', companionAvatar && 'top-0 -right-1')}
      >
        <Lvl size={size} lvl={lvl}>
          {lvl}
        </Lvl>
      </WrapperLvl>
      {companionAvatar && (
        <Companion
          size={size}
          className="absolute -right-1.5 -bottom-0.5"
          source={companionAvatar}
        />
      )}
    </div>
  )
}

export default AvatarWithProgress
