// import addCoinsToCurrentCompanion from './addCoinsToCurrentCompanion'
// import addDropToCompanion from './addDropToCompanion'
// import claimYields from './claimYields'
// import evolveCompanion from './evolveCompanion'
// import forceEvolveCompanion from './forceEvolveCompanion'
// import getCurrentCompanionActivity from './getCurrentCompanionActivity'
import getHighestLevelCompanion from './getHighestLevelCompanion'
// import getYields from './getYields'
// import getYieldsHistory from './getYieldsHistory'
// import updateResourcesToYield from './updateResourcesToYield'
import { api } from '@/Services/api'

export const companionsApi = api.injectEndpoints({
  endpoints: build => ({
    getHighestLevelCompanion: getHighestLevelCompanion(build),
    //   getCurrentCompanionActivity: getCurrentCompanionActivity(build),
    //   addCoinsToCurrentCompanion: addCoinsToCurrentCompanion(build),
    //   evolveCompanion: evolveCompanion(build),
    //   forceEvolveCompanion: forceEvolveCompanion(build),
    //   addDropToCompanion: addDropToCompanion(build),
    //   getYields: getYields(build),
    //   claimYields: claimYields(build),
    //   getYieldsHistory: getYieldsHistory(build),
    //   updateResourcesToYield: updateResourcesToYield(build),
  }),
  overrideExisting: true,
})

export const {
  useGetHighestLevelCompanionQuery,
  //   useAddCoinsToCurrentCompanionMutation,
  //   useGetCurrentCompanionActivityQuery,
  //   useEvolveCompanionMutation,
  //   useForceEvolveCompanionMutation,
  //   useAddDropToCompanionMutation,
  //   useGetYieldsQuery,
  //   useClaimYieldsMutation,
  //   useGetYieldsHistoryQuery,
  //   useUpdateResourcesToYieldMutation,
} = companionsApi
