import React from 'react'

const SeedCoinReward = ({ childrenClassName, ...props }) => (
  <img
    {...props}
    className={childrenClassName}
    src={require('@/Assets/images/activity/SeedCoinMascot.webp')}
    alt="Coin Mascot"
  />
)

export { SeedCoinReward }
