const shop = {
  screen_name: 'Boutique',
  free_ressources: 'Offres gratuites',
  seeds_packages: 'Packs de graines',
  coins_packages: 'Packs de pièces',
  drops_packages: 'Packs de gouttes',
  tickets_packages: 'Packs de tickets',
  pack_title: 'Obtenez {{quantity}} ',
  seed: 'graine',
  coin: 'pièce',
  drop: 'goutte',
  ticket: 'ticket',
  free_subtitle: 'Regardez une pub pour gagner votre {{resource}} gratuitement',
  free_seed_button: 'Regarder une pub',
  already_recovered: 'Déjà récupérée',
  remaining: 'restante{{plurial}}',
  five_seeds_pack_subtitle:
    "Chaque achat augmente l'argent reversé aux projets...",
  fifty_seeds_pack_subtitle: "Et aide l'équipe Budly à se développer ...",
  two_hundred_seeds_pack_subtitle:
    'Plein de nouvelles fonctionnalités pour vous.',
  tab_all: 'Tout',
  tab_free: 'Gratuit',
  tab_coins: 'Pièces',
  thanks_for_purchase_title: 'Merci pour votre achat !',
  thanks_for_purchase_description:
    'Votre achat a bien été effectué, faites-en bon usage !',
  thanks_for_watching_add_description:
    'Votre récompense vous a bien été attribuée !',
  available_soon: 'Bientôt disponible',
  shop_creation_success: 'Votre shop a bien été créé',
}

export default shop
