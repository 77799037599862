import { memo } from 'react'
import { useProfileContext } from '../Profile/Contexts/ProfileContext'
import { Header } from './Header'
import Tags from '../Tags'
import { CarouselPost } from './CarouselPost'
import { Footer } from './Footer'
import { styled } from 'styled-components'
import InteractiveText from '../Controls/InteractiveText/InteractiveText'

const StyledSpan = styled(InteractiveText)`
  color: ${({ theme }) => theme.colors.grey3};
`

const Post = memo(({ post, options, refetch, ...props }) => {
  const {
    profile: { whiteLabel },
  } = useProfileContext()
  return (
    <div className="pt-4 pb-4" {...props}>
      <Header post={post} {...options} />
      {post.content && <StyledSpan>{post.content}</StyledSpan>}
      {post.tags && (
        <div className="mb-3 mt-3">
          <Tags tags={post.tags} type="posts" />
        </div>
      )}
      <CarouselPost images={post.images} />
      {whiteLabel === 'gpm' && (
        <Footer post={post} refetch={refetch} {...options} />
      )}
    </div>
  )
})

export default Post
