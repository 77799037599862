const putCurrentUser = build =>
  build.mutation({
    query: ({ body, userId }) => {
      return {
        url: `/users/${userId}`,
        method: 'PUT',
        body,
      }
    },
    invalidatesTags: ['UsersByHashtag', 'Quests', 'TeamMembers'],
  })

export default putCurrentUser
