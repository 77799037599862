import { OptionsLock } from '@/Components/ControlsLocked/OptionsLock'
import { useCosmetics } from '@/Components/Dashboard/Contexts/CosmeticsContext'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import { useGetOptions } from '@/Components/Dashboard/Profile/Appearance/options'
import Headings from '@/Components/Dashboard/Profile/Headings'
import Form from '@/Components/Form/Form'
import FormSubmitButton from '@/Components/Form/FormSubmitButton'
import { LockedFieldSwitch } from '@/Components/LockedFieldSwitch'
import useCurrentMember from '@/Hooks/useCurrentMember'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const TITLE = 'edit_profil.appearance.title'
const SUBTITLE = 'edit_profil.appearance.subtitle'

const Appearance = () => {
  const { t } = useTranslation()
  const cosmetics = useCosmetics()
  const { onSubmit, editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const options = useGetOptions()

  const { appearanceFields, isAllTabFieldLocked, currentMember } =
    useCurrentMember()

  const isLocked = useMemo(() => {
    return isAllTabFieldLocked(
      appearanceFields,
      editCurrentUser?.lockedFields?.user.filter(val =>
        appearanceFields.includes(val),
      ),
    )
  }, [
    appearanceFields,
    editCurrentUser?.lockedFields?.user,
    isAllTabFieldLocked,
  ])

  const handleLockAllFields = () => {
    const tabFieldsLock = isLocked ? [] : appearanceFields
    setEditCurrentUser(prev => {
      const updatedUserFields =
        prev?.lockedFields?.user.filter(
          field => !appearanceFields.includes(field),
        ) || []
      return {
        ...prev,
        lockedFields: {
          ...prev.lockedFields,
          user: [...updatedUserFields, ...tabFieldsLock],
        },
      }
    })
  }
  if (!cosmetics) {
    return null
  }
  return (
    <Form onSubmit={onSubmit}>
      <Headings
        title={t(TITLE)}
        subtitle={t(SUBTITLE)}
        renderComponent={() => (
          <LockedFieldSwitch
            label={t('businessCard.block_all_fields')}
            onChange={handleLockAllFields}
            isLocked={isLocked}
          />
        )}
      />
      <OptionsLock options={options} />
      <FormSubmitButton
        className="w-full"
        disabled={isLocked && !currentMember}
      />
    </Form>
  )
}

export default Appearance
