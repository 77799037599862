const getCurrentProfileOrRedirect = build =>
  build.query({
    query: ({ slug, whitelabel = 'gpm' }) => {
      return {
        url: `/profile/${slug}?whitelabelSlug=${whitelabel}`,
        method: 'GET',
      }
    },
  })

export default getCurrentProfileOrRedirect
