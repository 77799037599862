// import { useGetWhitelabelBySlugQuery } from '@/Services/modules/whitelabel'

export default function useGetWhitelabel() {
  // const { data: whitelabel } = useGetWhitelabelBySlugQuery('gpm')

  return {
    slug: 'gpm',
    customDomain: 'budly.link',
  }
}
