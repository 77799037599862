import Text from '@/Components/Texts/Text'
import styled, { css } from 'styled-components'
import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useEditBusinessCard } from '../../Contexts/EditBusinessCardContext'
import { useCartContext } from '../../Contexts/CartContext'
import { useMemo } from 'react'
import ColorSelection from '@/Components/ColorSelection'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@/Hooks'
import { formatColors } from '@/Utils/formatColors'

const Title = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.std};
    font-weight: 600;
    color: ${theme.colors.black};
  `}
`

export const ColorBusinessCard = () => {
  const { theme } = useTheme()
  const cosmetics = useCosmetics()
  const {
    editBusinessCard,
    setEditBusinessCard,
    selectedBusinessCard,
    defaultBusinessCard,
  } = useEditBusinessCard()
  const { addItemToCart } = useCartContext()
  const { t } = useTranslation()

  const { colors, customColor } = useMemo(() => {
    return {
      colors: formatColors({
        colors: cosmetics['card-color'],
        defaultColor: theme.colors.primary,
      }),
      customColor: cosmetics['card-color-custom'][0],
    }
  }, [cosmetics, theme])

  const selectedColor = useMemo(
    () => editBusinessCard.color.toLowerCase(),
    [editBusinessCard.color],
  )

  const usedColor = useMemo(
    () =>
      (selectedBusinessCard?.color || defaultBusinessCard.color).toLowerCase(),
    [selectedBusinessCard?.color, defaultBusinessCard.color],
  )

  const onAddToCart = item => {
    addItemToCart(item)
    setEditBusinessCard(current => {
      return {
        ...current,
        color: item.value,
      }
    })
  }

  return (
    <div className="flex-1">
      <div className="px-4 flex-1">
        <Title>{t('edit_profil.appearance.color_theme.color')}</Title>
        <ColorSelection
          colors={colors}
          customColor={customColor}
          selectedColor={selectedColor}
          usedColor={usedColor}
          onAddToCart={onAddToCart}
          type="card-color"
        />
      </div>
    </div>
  )
}
