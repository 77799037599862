import * as React from 'react'
const SvgDiamond2 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 22})`}>
      <path
        stroke="#7ACB8B"
        d="M4.495 2.045a1.25 1.25 0 0 1 1.04-.557h10.93a1.25 1.25 0 0 1 1.039.557l3.59 5.381c.121.183.135.414.039.606a32.45 32.45 0 0 1-7.907 10.127l-1.75 1.5.325.38-.325-.38a.73.73 0 0 1-.95 0l-1.75-1.5A32.5 32.5 0 0 1 .868 8.031a.6.6 0 0 1 .037-.605zm.416.276L1.929 6.797l-.459.688.823.086 1.405.147q1.06.113 2.125.192l.44.032.087-.432q.024-.12.071-.233L8.338 2.68l.288-.692h-3.09a.75.75 0 0 0-.624.333ZM2.333 8.077l-.948-.1.456.836a32 32 0 0 0 7.105 8.833l.796-.553L6.617 8.76l-.113-.3-.32-.023a70 70 0 0 1-2.536-.22zm5.452.45-.011.506-.456.17 3.214 8.574.468 1.25.468-1.25 3.215-8.574.266-.709-.757.034a70 70 0 0 1-6.054.015l-.005-.015h-.348Zm8.034-.09-.321.021-.113.301-3.125 8.334.796.553a32 32 0 0 0 7.105-8.833l.456-.836-.948.1-1.314.139q-1.267.133-2.536.22Zm3.89-.867.822-.086-.459-.688-2.983-4.474V2.32a.75.75 0 0 0-.624-.333h-3.09l.289.692 1.915 4.595q.047.114.07.233l.087.434.441-.032q1.065-.08 2.125-.192zm-5.09.437-.013-.505.447-.186-2.092-5.02-.128-.308H9.167l-.129.308-2.09 5.02-.273.655.708.036c2.411.125 4.826.125 7.236 0Z"
      />
    </g>
  </svg>
)
export default SvgDiamond2
