import CostBadge from '@/Components/Activity/components/CostBadge'
import LabeledSwitch from '@/Components/Controls/LabeledSwitch'
import { useCartContext } from '@/Components/Dashboard/Contexts/CartContext'
import { useCosmetics } from '@/Components/Dashboard/Contexts/CosmeticsContext'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const styles = { paddingBottom: 96 }

const HideOptionBloc = () => {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const { addItemToCart, removeItemFromCart } = useCartContext()
  const {
    isDisplayViews,
    btnAddIsHide,
    hideFollowing,
    hideFollowers,
    hideContentsSubtitle,
    hideSharingBtnContent,
    profileDisplay,
    phone,
    phoneVerified,
    isCertified,
  } = editCurrentUser
  const { t } = useTranslation()

  const cosmetics = useCosmetics()

  const options = useMemo(() => {
    return [
      {
        text: t('edit_profil.appearance.hide_informations.hide_btn_contact'),
        name: 'btnAddIsHide',
        value: btnAddIsHide,
      },
      {
        text: t('edit_profil.appearance.hide_informations.hide_following'),
        name: 'hideFollowing',
        value: hideFollowing,
      },
      {
        text: t('edit_profil.appearance.hide_informations.hide_followers'),
        name: 'hideFollowers',
        value: hideFollowers,
      },
      ...(profileDisplay !== 'icon'
        ? [
            {
              text: t(
                'edit_profil.appearance.hide_informations.hide_contents_subtitle',
              ),
              name: 'hideContentsSubtitle',
              value: hideContentsSubtitle,
            },
            {
              text: t(
                'edit_profil.appearance.hide_informations.hide_sharing_btn',
              ),
              name: 'hideSharingBtnContent',
              value: hideSharingBtnContent,
            },
          ]
        : []),
      ...(phone && phoneVerified
        ? [
            {
              text: t(
                'edit_profil.appearance.hide_informations.show_certification',
              ),
              name: 'isCertified',
              value: isCertified,
            },
          ]
        : []),
    ]
  }, [
    isDisplayViews,
    btnAddIsHide,
    hideFollowing,
    hideFollowers,
    profileDisplay,
    hideContentsSubtitle,
    hideSharingBtnContent,
    phone,
    phoneVerified,
    isCertified,
    t,
  ])

  const onChange = (name, value) => {
    const cosmetic = cosmetics[name] ? cosmetics[name][0] : null

    if (!cosmetic) {
      return setEditCurrentUser(user => ({
        ...user,
        [name]: !user[name],
      }))
    }

    const isDefaultValue = !value
    const isAcquired = cosmetic ? cosmetic.isAcquired : true
    const cantBeBuy = name === 'isDisplayViews'
    if (cantBeBuy || isAcquired || isDefaultValue) {
      if (isDefaultValue) {
        removeItemFromCart(cosmetic.type)
      }
      return setEditCurrentUser(user => ({
        ...user,
        [name]: value,
      }))
    }
    addItemToCart({ ...cosmetic, value })
    setEditCurrentUser(user => ({
      ...user,
      [name]: value,
    }))
  }

  const haveCosmetic = name => {
    if (!cosmetics[name]) {
      return false
    }
    return true
  }

  return (
    <>
      {options.map(({ text, name, value }) => (
        <div key={name} className="mb-3 w-full">
          <LabeledSwitch
            title={text}
            value={value}
            onChange={val => onChange(name, val)}
            variant="secondary"
          />
          {haveCosmetic(name) && (
            <div className="pt-2 w-full flex flex-row justify-end">
              <CostBadge
                isAcquired={cosmetics[name][0].isAcquired}
                isUsed={cosmetics[name][0].isAcquired && value}
                price={cosmetics[name][0].price}
              />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default HideOptionBloc
