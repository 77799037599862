const getFollowings = build =>
  build.query({
    query: uid => {
      return {
        url: `/users/${uid}/following`,
        method: 'GET',
      }
    },
    providesTags: () => [
      { type: 'Followers', id: 'LIST' },
      { type: 'Followings', id: 'LIST' },
    ],
  })

export default getFollowings
