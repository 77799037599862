import { useTheme } from '@/Hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from './ModalWrapper/Modal'
import { useNavigate } from 'react-router-dom'
import useCurrentUser from '@/Hooks/useCurrentUser'
import { useGetAvailableResourcesByUidQuery } from '@/Services/modules/availableResources'
import { getConsumableResources } from '../Activity/Resources/utils'
import AddResourceBadge from '../Activity/Resources/AddResourceBadge'
import { formatNumber } from '@/Utils/formaters'

const ModalInsufficientResource = ({
  children: button = () => null,
  resourcePrice,
  onDismiss = () => null,
}) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <>
      {button(setIsOpen)}
      <Modal
        isOpen={isOpen}
        onDismiss={() => {
          onDismiss()
          setIsOpen(false)
        }}
        header={<HeaderRessource resourcePrice={resourcePrice} />}
        title={t('modal.insufficientResource.title')}
        subtitle={t('modal.insufficientResource.subtitle')}
        actions={[
          {
            color: theme.colors.grey1,
            label: t('modal.btn.cancel'),
            onClick: () => {
              onDismiss()
              setIsOpen(false)
            },
          },
          {
            label: t('modal.insufficientResource.btnLabelVisitStore'),
            onClick: async () => {
              onDismiss()
              navigate(`/dashboard/shop/${resourcePrice.type[0]}`)
              setIsOpen(false)
            },
            color: theme.colors.primary,
          },
        ]}
        displayCloseButton={false}
      />
    </>
  )
}

const HeaderRessource = ({ resourcePrice }) => {
  const { currentUser } = useCurrentUser()
  const { data: availableResources, isLoading } =
    useGetAvailableResourcesByUidQuery(currentUser.uid)

  if (isLoading || !availableResources) return null
  return (
    <div className="flex flex-row mt-6 px-10">
      {getConsumableResources().map(({ type, icon: Icon }, index) => (
        <AddResourceBadge
          key={type}
          type={type}
          childrenClassName={index > 0 && 'ml-2'}
          quantity={availableResources[`${type}s`]}
          format={type.length > 1 ? formatNumber : undefined}
          onClick={() => null}
          resourcePrice={resourcePrice}
        >
          <Icon />
        </AddResourceBadge>
      ))}
    </div>
  )
}

export default ModalInsufficientResource
