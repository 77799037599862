import themeGlobal, { darkColors, lightColors } from '@/Theme'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

const useTheme = () => {
  const { isDarkMode } = useSelector(state => state.theme)
  const colors = useMemo(
    () => (!isDarkMode ? lightColors : darkColors),
    [isDarkMode],
  )
  const theme = {
    ...themeGlobal,
    colors: {
      ...colors,
    },
  }

  useEffect(() => {
    if (isDarkMode) {
      document.body.style.background = darkColors.white
      return
    }
    document.body.style.background = lightColors.white
  }, [isDarkMode])

  return { theme, isDarkMode }
}

export default useTheme
