import resources from './index'
import { formatNumber } from '@/Utils/formaters'
import React from 'react'
import AddResourceBadge from './AddResourceBadge'

export const getResources = () => {
  return resources
}

export const getConsumableResources = () => {
  return resources.filter(({ isConsumable }) => isConsumable === true)
}

export const getPrimaryResources = () => {
  return resources.filter(({ isPrimary }) => isPrimary === true)
}

export const getResourceByType = type => {
  return resources.find(resource => resource.type === type)
}

export const renderResourceBadges = (
  availableResources,
  types = getConsumableResources().map(({ type }) => type),
) => {
  const filteredResources = types.map(type =>
    resources.find(resource => resource.type === type),
  )

  return filteredResources.map(({ type, icon: Icon }, index) => (
    <AddResourceBadge
      key={type}
      type={type}
      quantity={availableResources?.[`${type}s`]}
      format={types.length > 1 ? formatNumber : undefined}
    >
      <Icon />
    </AddResourceBadge>
  ))
}
