const edit_profil = {
  preview: 'Mira mi perfil',
  save: 'Guardar',
  insight: 'Conocimiento',
  reset: 'Reiniciar',
  title: 'Editar mi perfil',
  manage_contents: 'Gestionar mi contenido',
  notif: {
    succes: 'La informacion ha sido guardada con exito',
    champ: 'No se han modificado campos',
    not_enough_coins:
      'Usted no tiene suficientes monedas para hacer esta compra',
    error: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde',
    interior: 'Interior',
    subtitle_interior:
      'El interior corresponde a la forma de los globos oculares de tu QRCode',
    exterior: 'Exterior',
    subtitle_exterior:
      'El exterior corresponde a la forma de los marcos de los ojos de tu QRCode',
  },

  contents: {
    title: 'Contenido',
    subtitle: 'Organiza tu contenido y compártelo desde tu perfil de Budly.',
    add_content_panel_titles: {
      contact: 'Agregar un nuevo enlace de contacto',
      link: 'Agregar un nuevo enlace',
      social: 'Agregar un nuevo enlace a redes sociales',
      gallery: 'Agregar una nueva imagen a la galería',
    },
    edit_content_panel_titles: {
      contact: 'Editar un enlace de contacto',
      link: 'Editar un enlace',
      social: 'Editar un enlace de redes sociales',
      gallery: 'Editar una imagen en la galería',
    },
  },
  qr_code: {
    title: 'QR Code',
    subtitle:
      'Personalice su QRCode para hacerlo único y reconocible por su red.',
    pay_and_save: 'Para ver los cambios seleccionados paga y guarda tu cesta.',
  },
  informations: {
    title: 'Vuestras informaciones',
    subtitle: 'Toda la información es visible para los usuarios.',
    modify_photos: 'Editar mis fotos',
    add_photos: 'Agregar fotos',
    appearance: 'Apariencia',
    add_pp: 'Agregar foto de perfil',
    add_bg: 'Añadir foto de portada',
    add_logo: 'Añadir logo de empresa',

    my_infos: {
      title: 'Información',
      name: 'Nombre',
      name_placeholder: 'Tu nombre',
      surname: 'Apellido',
      surname_placeholder: 'Tu apellido',
      job: 'Trabajo',
      job_placeholder: 'Tu puesto',
      enterprise: 'Empresa',
      enterprise_placeholder: 'Tu empresa',
      address: 'Dirección',
      address_placeholder: 'Tu dirección',
      description: 'Descripción',
      description_placeholder: 'Tu descripción',
    },

    hashtags: {
      title: 'Tus hashtags',
      add_hashtags: 'Agregar hashtag',
      error1: 'Máximo 3 hashtags',
      error2: 'Carácter mínimo 3 y máximo 12',
      error3: 'Repetición de hashtag prohibido',
    },

    advanced_options: {
      private_mode: 'Modo privado',
      title: 'Opciones avanzadas',
      business_profile: 'Perfil de empresa',
    },

    save: 'Para salvaguardar',
    insight: 'Conocimiento',
  },

  appearance: {
    title: 'Apariencia',
    subtitle: 'Destaca personalizando tu perfil.',
    color_theme: {
      title: 'Color del tema',
      color: 'Colores',
      perso: 'personal',
      grey: 'gris',
      blue: 'azul',
      red: 'rojo',
      yellow: 'amarillo',
      green: 'verde',
      violet: 'púrpura',
      orange: 'naranja',
      color_perso: 'Color personalizado',
      restart: 'Reiniciar',
      preview_btn: 'Resumen',
      default: 'predeterminado',
    },
    shape_avatar: {
      title: 'Forma de avatar',
      shapes: 'Formas',
      motif: 'Patrón',
      motif_subtitle: 'Formas y colores de los marcos de los ojos de su QRCode',
      bg: 'Fondo',
      eye: 'Ojos',
    },
    btn_style: {
      title: 'Estilo de botones',
      shape: 'Forma',
      styles: 'Estilo',
      show_background: 'Mostrar fondo',
      show_outline: 'Mostrar esquema',
      show_content_as_list: 'Mostrar contenido como lista',
      outline_color: 'Color del contorno',
    },
    hide_informations: {
      title: 'Información',
      view_profil: 'Ocultar vistas en tu perfil',
      hide_btn_contact: 'Ocultar el botón \n"Agregar la tarjeta de contacto"',
      hide_contact: 'Oculta tus contactos',
      hide_followers: 'Oculta a tus seguidores',
      hide_following: 'Ocultar tus seguidores',
      hide_contents_subtitle: 'Ocultar subtítulo del contenido',
      hide_sharing_btn: 'Ocultar botones \npara compartir en mi contenido',
      show_certification: 'Mostrar mi certificación',
    },
    replica_profil: {
      title: 'Replicar un perfil',
      replica: 'Reproducir exactamente',
      url_profile: 'URL del perfil o nombre de usuario',
      dont_forget_to_save: 'Se ha replicado un perfil, recuerda guardar',
    },
    text_style: {
      title: 'Estilo de texto',
    },
    social_color: {
      title: 'Colorea las redes sociales',
    },
    logo: {
      preview_text: 'modificar el logotipo',
      label_slider: 'Tamaño',
    },
    buy_items_modal: {
      cart: 'Carrito',
      title: 'Estás a punto de gastar {{totalPrice}} coins.',
      pay_and_save_btn: 'Pagar y guardar',
      item_descriptions: {
        'background-color': 'Color de tema personalizado',
        'shape-avatar': 'Forma de avatar personalizada',
        btnShape: 'Forma de botón personalizada',
        btnOutlineColor: 'Color de contorno personalizado del botón',
        btnAddIsHide: "Ocultar botón 'Agregar'",
        duplicateProfile: 'Duplicar un perfil',
        hideContentsSubtitle: 'Ocultar subtítulo del contenido',
        hideFollowers: 'Ocultar seguidores',
        hideFollowing: 'Ocultar seguidos',
        hideSharingBtnContent: 'Ocultar botones de compartir',
        banner: 'Banner',
        isCertified: 'Certificación',
        'card-color': 'Color de tarjeta personalizado',
        'qr-background-color': 'Color de fondo del QRCode',
        'qr-pattern-shape': 'Forma del patrón del QRCode',
        'qr-eye-ball-shape': 'Forma de la pupila del QRCode',
        'qr-eye-frame-shape': 'Forma del marco del QRCode',
        'card-template': 'Template de tarjeta',
        'qr-background-color-custom':
          'Color de fondo personalizado para el código QR',
        'qr-background-color-custom-eyes-interior':
          'Color personalizado del interior de los ojos del código QR',
        'qr-background-color-custom-eyes-outside':
          'Color personalizado del exterior de los ojos del código QR',
        'qr-background-color-shape-custom':
          'Color de fondo personalizado del patrón de código QR',
      },
    },
    acquired: 'Obtenido',
    used: 'Utilizado',
    select_color: 'Selecciona un color',
  },
}

export default edit_profil
