import React from 'react'
import AvatarWithProgress from './AvatarWithProgress'
import { useGetUserActivity } from '@/Hooks/useGetUserActivity'
import { useSelector } from 'react-redux'
import { useTheme } from '@/Hooks'
import { useGetFullName } from '@/Hooks/useGetFullName'
import styled, { css } from 'styled-components'
import { Star } from '@/Assets/icons/components'
import Text from './Texts/Text'

const FullName = styled(Text)`
  line-height: 18px;
  font-size: 16px;
`
const Experience = styled(Text)`
  ${({ theme }) => css`
    line-height: 18px;
    font-size: 12px;
    color: ${theme.colors.grey2};
  `}
`
export const PlayerExperienceAvatar = props => {
  const { currentUser } = useSelector(state => state.user)
  const { userActivity } = useGetUserActivity(currentUser.uid)
  const { theme } = useTheme()
  const fullName = useGetFullName(currentUser)
  return (
    <div className="flex items-center mb-6" {...props}>
      <AvatarWithProgress
        profile={currentUser}
        lvl={userActivity.lvl}
        progress={userActivity.progress}
        size={theme.size.m}
      />
      <div className="grow pl-3">
        <FullName className="mb-1">{fullName}</FullName>
        <div className="flex flex-row">
          <Star size={16} className="mr-1" />
          <Experience regular>{`${userActivity.exp || '-'} exp`}</Experience>
        </div>
      </div>
    </div>
  )
}
