const activitypage = {
  casino: {
    remaining_free_slot: ' 1 tirage gratuit restant',
    tabName: 'Casino',
    title: 'Budly Slots',
    description:
      'Jouez pour accumuler un maximum de ressources Budly utilisables pour planter, arroser et une multitude d’actions dans l’application',
    possible_gains_by_game: 'Gains possibles par partie',
    costs_of_multiple_gains_per_game:
      'Coûts des multiplicateurs de gain par partie',
    bet: 'Mise',
    multiple: 'multiplicateur',
    play_free: 'Jouer gratuitement',
  },
  tab_games: 'Jeux',
  title_tab_bottom: 'Jouer',
  quests: 'Quêtes',
  games: 'Jeux',
  leaderboard: 'Classement',
  experience: 'Expérience',
  planted: 'Planté',
  streak: 'Série',
  streak_unit: 'jour consécutif',
  streak_unit_plural: 'jours consécutifs',
  planted_plural: 'Plantés',
  plantations: 'Plantations',
  claim_your_daily_rewards: 'Récupérez vos ressources journalières',
  claim_your_daily_reward_female: 'Réclamez votre {{ressource}} quotidienne',
  claim_your_daily_reward_male: 'Réclamez votre {{ressource}} quotidien',
  claim_multi: 'Récupérer mes ressources',
  claim_female: 'Récupérer ma {{resource}}',
  claim_male: 'Récupérer mon {{resource}}',
  claimed: 'Prochaine {{resource}} dans ',
  claimed_multi: 'Récupérer mes ressources dans',
  come_every_days:
    'Revenez chaque jour pour réclamer 1 {{ressource}} gratuitement',
  come_every_days_multi:
    'Revenez chaque jour pour récupérer vos ressources journalières gratuitement',
  my_daily_quests: 'Mes quêtes quotidiennes',
  my_permanent_quests: 'Mes quêtes permanentes',
  welcome_quests: 'Quêtes de bienvenue',
  remaining: 'restantes',
  seed: 'graine',
  drop: 'goutte',
  ticket: 'ticket',
  exp: 'exp',
  coin: 'pièce',
  level_short: 'niv',
  plant_trees: 'Planter vos arbres',
  add_seeds_to_plant_trees: 'Ajouter des graines pour planter un nouvel arbre',
  add_seeds: 'Ajouter {{quantity}} graines',
  plant_your_tree: 'Planter votre arbre',
  complete_welcome_quests_to_unlock:
    'Terminez les quêtes de bienvenue pour débloquer les ',
  daily_quests: 'quêtes quotidiennes',
  permanent_quests: 'quêtes permanentes',
  planted_trees: 'arbre{{plurial}} planté{{plurial}}',
  planted_trees_subtitle: 'Retrouvez tous vos arbres plantés.',
  planted_on: 'Planté le',
  participants_number: 'participants',
  seeds_number: 'graines',
  drops_number: 'gouttes',
  planted_by_you: 'Par vous',
  user_plantations: 'Plantations de {{userName}}',
  modalSelectResourceQuantity: {
    title: 'Sélectionner la quantité de votre ressource',
    subtitle:
      'Choisissez la quantité de ressource que vous souhaitez dépenser.',
  },
  quantity: 'Quantité',
  collective: 'Collectif ({{quantity}})',
  individual: 'Individuel ({{quantity}})',
  step_history: 'Historique de pas',
  step_toasters: {
    info: {
      text1: 'Budly a besoin d’accéder à vos données de santé',
      text2: 'Veuillez l’activer dans vos paramètres',
    },
    error: {
      text1: 'Erreur lors de la récupération des données de pas',
      text2: 'Veuillez réessayer plus tard',
    },
    warning: {
      text1: 'Aucun pas trouvé',
      text2:
        "Assurez-vous d'avoir réalisé des pas aujourd'hui ou d'avoir activé les permissions",
    },
    banner: {
      title:
        "Vous devez avoir l'application {{appName}} et activer les permissions",
      subtitle:
        'Cliquez ici pour télécharger {{appName}} puis revenez pour activer les permissions',
    },
  },
}


export default activitypage
