import useCurrentUser from '@/Hooks/useCurrentUser'
import { useEditCurrentUser } from './Dashboard/Contexts/EditProfileContext'
import { useCartContext } from './Dashboard/Contexts/CartContext'
import { useCallback } from 'react'
import { clsx } from 'clsx'
import CostBadge from './Activity/components/CostBadge'
import styled, { css } from 'styled-components'
import useCurrentMember from '@/Hooks/useCurrentMember'

const OVERRIDE_STYLES = {
  'rounded-full': 'rounded-3xl',
}

const Outline = styled.div`
  ${({ theme }) => css`
    border-color: ${props =>
      props.selected ? theme.colors.primary : 'transparent'};
    border-width: 2px;
    border-style: solid;
    padding: 4px;
  `}
`

const ShapeChoice = ({ name, shapes, preview: Preview }) => {
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const { addItemToCart } = useCartContext()

  const onAddToCart = useCallback(
    item => {
      addItemToCart(item)
      setEditCurrentUser(current => {
        return {
          ...current,
          styles: { ...current.styles, [item.type]: item.value },
        }
      })
    },
    [addItemToCart, setEditCurrentUser],
  )

  return (
    <div className="flex flex-row w-full justify-between">
      {shapes?.map((item, index) => {
        const { label: shape, payload: className, price, isAcquired } = item
        const selected = shape === (editCurrentUser.styles[name] || 'shape1')
        return (
          <Outline
            key={index}
            className={clsx(OVERRIDE_STYLES[className] || className)}
            selected={selected}
          >
            <div className="mb-2">
              <Preview
                editCurrentUser={editCurrentUser}
                className={clsx(className)}
                onClick={() => onAddToCart(item)}
              />
            </div>

            <CostBadge
              price={price}
              isAcquired={isAcquired}
              isUsed={user.styles[name] === shape}
            />
          </Outline>
        )
      })}
    </div>
  )
}

export default ShapeChoice
