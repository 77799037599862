// import { useGetWhitelabelBySlugQuery } from '@/Services/modules/whitelabel'
// import { useSelector } from 'react-redux'

export default function useGetBaseUrl({ whitelabel = true } = {}) {
  // const { currentUser } = useSelector(state => state.user)
  // const { data: whitelabelData } = useGetWhitelabelBySlugQuery(
  //   currentUser?.whiteLabel,
  //   {
  //     skip: !currentUser?.whiteLabel,
  //   },
  // )

  const whitelabelData = {
    customDomain: 'budly.link',
    slug: 'gpm',
  }

  const defaultBaseUrl = `gpm.business${
    whitelabel ? '/' + whitelabelData?.slug : ''
  }`
  return `https://${whitelabelData?.customDomain || defaultBaseUrl}`.replace(
    'www.',
    '',
  )
}
