import { BadgeResourceQuantity } from '@/Components/Profile/styles'
import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${props =>
      props.forceBg ? theme.colors.white : 'transparent'};
    border-radius: ${theme.borderRadius.m};
  `}
  display:flex;
  flex-direction: row;
  justify-content: center;
`

const PriceBadge = styled(BadgeResourceQuantity)`
  ${({ theme }) => css`
    justify-content: center;
    padding: 4px 4px;
    height: ${theme.size.s2};
    min-width: 64px;
    background-color: ${theme.colors.grey1};

    ${({ isUsed }) =>
      isUsed &&
      css`
        border: none;
        background-color: ${theme.colors.primary + '20'};
        color: ${theme.colors.primary};
      `}
  `}
`

const Label = styled(Text)`
  font-size: 10px;
`

const CostBadge = ({ isAcquired, isUsed, price, forceBg = false }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <Wrapper forceBg={forceBg}>
      <PriceBadge isUsed={isUsed}>
        {isAcquired && !isUsed && (
          <Label color={theme.colors.grey2}>
            {t('edit_profil.appearance.acquired')}
          </Label>
        )}
        {isUsed && (
          <Label color={theme.colors.primary}>
            {t('edit_profil.appearance.used')}
          </Label>
        )}

        {!isAcquired && !isUsed && (
          <>
            <img
              className="h-4 w-4 mr-1"
              src={require('@/Assets/images/activity/coinCm.webp')}
              alt="coin"
            />
            <Text>{price}</Text>
          </>
        )}
      </PriceBadge>
    </Wrapper>
  )
}

export default CostBadge
