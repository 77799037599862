import getAllMembers from './getAllMembers'
import createMembers from './createMembers'
import duplicateMember from './duplicateMember'
import { api } from '@/Services/api'
import sendEmailMember from './sendEmailMember'
import getMemberCosmetics from './getMemberCosmetics'

export const teamsApi = api.injectEndpoints({
  endpoints: build => ({
    getAllMembers: getAllMembers(build),
    createMembers: createMembers(build),
    duplicateMember: duplicateMember(build),
    sendEmailMember: sendEmailMember(build),
    getMemberCosmetics: getMemberCosmetics(build),
  }),
  overrideExisting: true,
})

export const {
  useGetAllMembersQuery,
  useCreateMembersMutation,
  useDuplicateMemberMutation,
  useSendEmailMemberMutation,
  useGetMemberCosmeticsQuery,
} = teamsApi
