import user from './User'
import member from './Member'
import language from './Language'
import { api, web3Api } from '@/Services/api'
import theme from './Theme'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'

const reducer = combineReducers({
  user,
  member,
  language,
  [api.reducerPath]: api.reducer,
  [web3Api.reducerPath]: web3Api.reducer,
  theme,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['theme', 'language'],
}
const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware, web3Api.middleware)
    return middlewares
  },
})

const persistor = persistStore(store)

setupListeners(store.dispatch)

export { store, persistor }
