import Drop from './Drop'
import React from 'react'

const Drops = ({ drops, ...props }) => {
  return (
    <>
      <div className="overflow-auto">
        {drops.map(drop => {
          return <Drop key={drop.userId} drop={drop} {...props} />
        })}
      </div>
    </>
  )
}

export default Drops
