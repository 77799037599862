import { useLazyGetAvailableLinkByUrlQuery } from '../links'
import { useLazyAddProductActivationQuery } from '../products'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useActivateProductByCode = profile => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { currentUser } = useSelector(state => state.user)
  const user = profile || currentUser
  const [getAvailableLinkByUrlQuery] = useLazyGetAvailableLinkByUrlQuery()
  const [addProductActivation] = useLazyAddProductActivationQuery()

  const activateProductByCode = useCallback(
    async (code, callback = () => null) => {
      if (!user?.uid) {
        return null
      }

      setLoading(true)
      try {
        const availaibleResponse = await getAvailableLinkByUrlQuery({
          url: `https://gpm.business/${code}`,
        })
        if (availaibleResponse.error) {
          throw new Error(availaibleResponse.error.data.error)
        }

        const activationResponse = await addProductActivation({
          uid: user.uid,
          code,
          body: {
            email: user.email,
            whitelabelSlug: 'gpm',
          },
        })

        if (activationResponse.error) {
          throw new Error(activationResponse.error.data.error)
        }
        setLoading(false)
        toast.success(t('toastActivateProduct.success'))
        callback()
      } catch (error) {
        toast.error(error.message || t('toastErrorDefault.error'))
        setLoading(false)
      }
    },
    [
      t,
      getAvailableLinkByUrlQuery,
      user?.uid,
      user?.email,
      addProductActivation,
    ],
  )

  return { activateProductByCode, loading }
}
