import Text from '@/Components/Texts/Text'
import { useTheme } from '@/Hooks'
import React, { useEffect, useState } from 'react'
import { IconButtons } from './components/IconButtons'
import { RatingStars } from './components/RatingStars'

const FAKE_RATES = [
  {
    rate: 5,
    userName: 'Amélie',
    text: 'A real commitment to the environment! they make the difference.',
  },
  {
    rate: 4.7,
    userName: 'David',
    text: 'Une app unique ! Promos exclusives, jeux amusants et planter des arbres. Exceptionnel !',
  },

  {
    rate: 4.5,
    userName: 'Elvan',
    text: 'Un compromiso auténtico con el medio ambiente! ¡Una gran red social!',
  },
  {
    rate: 4.8,
    userName: 'Claude',
    text: 'Cette app est géniale ! Des arbres plantés à chaque like. Écolo et fun !',
  },
]

export const Comments = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  const { theme } = useTheme()

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex(prevIndex => (prevIndex + 1) % FAKE_RATES.length)
    }, 5000)

    return () => clearInterval(interval)
  }, [])
  return (
    <div className="w-full">
      {FAKE_RATES.filter((item, index) => index === slideIndex).map(
        (item, index) => {
          return (
            <>
              <div key={index} className="mb-6">
                <Text className="text-1xl xl:text-2xl select-none text-white">
                  {item.text}
                </Text>
              </div>
              <div className="flex justify-between">
                <RatingStars userName={item.userName} rate={item.rate} />
                <IconButtons
                  setSlideIndex={setSlideIndex}
                  fakeRates={FAKE_RATES}
                />
              </div>
            </>
          )
        },
      )}
    </div>
  )
}
