const getMemberCosmetics = build =>
  build.query({
    query: ({ memberIds }) => {
      return {
        url: `/members/cosmetics?memberIds=${memberIds}`,
        method: 'GET',
      }
    },
    providesTags: ['MemberCosmetics'],
  })

export default getMemberCosmetics
