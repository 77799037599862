import styled, { css } from 'styled-components'
import { TouchableOpacity } from './Buttons/TouchableOpacity'
import CostBadge from './Activity/components/CostBadge'
import ColorItem from './ColorItem'
import ThemeColorButton from './ThemeColorButton'

const GridContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: ${theme.space.m};
    margin-top: ${theme.space.m};
    gap: 8px;
  `}
`

const SelectCustomColor = styled(TouchableOpacity)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.space.xs};
    border: ${({ selected }) =>
      selected ? `2px solid ${theme.colors.primary}` : `2px solid transparent`};
    border-radius: ${theme.borderRadius.xl};
    justify-content: space-between;
    align-items: center;
  `}
`

const Colors = ({
  isCustomColorSelected,
  onAddToCart,
  customColor,
  colors,
  inputColor,
  selectedColor,
  usedColor,
  type,
}) => {
  return (
    <GridContainer>
      <SelectCustomColor
        selected={isCustomColorSelected}
        onClick={() =>
          onAddToCart({
            ...customColor,
            type,
            value: inputColor,
          })
        }
      >
        <ThemeColorButton />
        <CostBadge
          price={customColor?.price || null}
          isUsed={inputColor.toLowerCase() === usedColor}
          isAcquired={customColor?.isAcquired || null}
        />
      </SelectCustomColor>
      {colors.map((item, index) => (
        <ColorItem
          item={item}
          selectedColor={selectedColor}
          usedColor={usedColor}
          onAddToCart={onAddToCart}
        />
      ))}
    </GridContainer>
  )
}

export default Colors
