const addResourceUsesByUid = build =>
  build.mutation({
    query: data => {
      return {
        url: `/resource-uses/${data.uid}`,
        method: 'POST',
        body: data,
      }
    },
    invalidatesTags: ['AvailableResources'],
  })

export default addResourceUsesByUid
