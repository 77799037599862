import * as React from 'react'
const SvgDelivery = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        stroke="#7ACB8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 14.738h1c1.1 0 2-.9 2-2v-10H6c-1.5 0-2.81.83-3.49 2.05"
      />
      <path
        stroke="#7ACB8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2 17.738c0 1.66 1.34 3 3 3h1c0-1.1.9-2 2-2s2 .9 2 2h4c0-1.1.9-2 2-2s2 .9 2 2h1c1.66 0 3-1.34 3-3v-3h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1.29l-1.71-2.99a2.02 2.02 0 0 0-1.74-1.01H15v7c0 1.1-.9 2-2 2h-1"
      />
      <path
        stroke="#7ACB8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8 22.738a2 2 0 1 0 0-4 2 2 0 0 0 0 4M16 22.738a2 2 0 1 0 0-4 2 2 0 0 0 0 4M22 12.738v2h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1.29zM2 8.738h6M2 11.738h4M2 14.738h2"
      />
    </g>
  </svg>
)
export default SvgDelivery
