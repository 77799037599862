import { Spinner } from '@/Components/Spinner'
import Text from '@/Components/Texts/Text'
import useCurrentMember from '@/Hooks/useCurrentMember'
import { useSelectAllMembers } from '@/Hooks/useSelectAllMembers'
import { useGetMemberCosmeticsQuery } from '@/Services/modules/teams'
import React, { createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'

const CosmeticMembersContext = createContext()

export const CosmeticMembersProvider = ({ children }) => {
  const { t } = useTranslation()
  const { isMultiMemberEdition } = useCurrentMember()
  const { checkedAllMemberUid } = useSelectAllMembers()
  const skip = !isMultiMemberEdition || !checkedAllMemberUid
  const { data: memberCosmetics, isLoading } = useGetMemberCosmeticsQuery(
    {
      memberIds: checkedAllMemberUid.join(','),
    },
    { skip },
  )

  if (skip) {
    return children
  }

  if (isLoading) {
    return (
      <div className="flex-1 h-full items-center flex justify-center gap-2 flex-col mt-10">
        <Spinner loading />
        <Text>{t('teams_page.loading_member_data')}</Text>
      </div>
    )
  }

  return (
    <CosmeticMembersContext.Provider value={memberCosmetics}>
      {children}
    </CosmeticMembersContext.Provider>
  )
}

export function useCosmeticMembers() {
  return useContext(CosmeticMembersContext) || {}
}
