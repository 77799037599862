import React, { memo } from 'react'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import { useCartContext } from '../../Contexts/CartContext'
import { useTheme } from '@/Hooks'
import { ModalImageTypeSelectionButton } from '@/Components/Modals/ModalImageTypeSelectionButton'
import BannerAvatar from '@/Components/BannerAvatar/BannerAvatar'

const EditProfileBanner = memo(() => {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const { theme } = useTheme()
  const { addItemToCart, clearCart } = useCartContext()

  return (
    <div>
      <div className="relative">
        <BannerAvatar
          profile={editCurrentUser}
          avatarSize={theme.size.xl3}
          showEmptyLogo
          className="tablet:aspect-[2.5]"
        />
      </div>
      <div className="w-full flex justify-end">
        <div>
          <ModalImageTypeSelectionButton
            editCurrentUser={editCurrentUser}
            setEditCurrentUser={setEditCurrentUser}
            clearCart={clearCart}
            addItemToCart={addItemToCart}
          />
        </div>
      </div>
    </div>
  )
})

export default EditProfileBanner
