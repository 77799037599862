const parametre = {
  title: 'Paramètres',
  title_bloc1: 'PRODUITS',
  title_bloc2: 'GENERAL',
  title_bloc3: 'SECURITE',
  title_bloc4: 'SUPPORT',
  community_title: 'COMMUNAUTÉ',
  title_bloc6: 'A PROPOS',
  produit_gopop: {
    activer_produit: {
      title: 'Activer un produit',
      select: "Sélectionnez un moyen d'activation",
      description:
        'Choisissez parmi ces options celle que vous souhaitez utiliser pour activer votre produit.',
      subtitle: "Scanner le QRCode sur l'emballage du produit.",
      detect_product: 'Détecter mon produit',
      subtitle2: 'Approchez le produit du dos de votre téléphone',
      productDetected: 'Produit détecté',
      productActivate: 'Appuyez pour activer',
      manuellement: {
        title: 'Ajouter manuellement mon produit',
        subtitle: "Entrez le code inscrit sur l'emballage du produit.",
        modal: {
          title: 'Nouveau produit',
          subtitle: "Entrez le code situé sur l'emballage du produit",
          input: 'Entrez le code produit',
          btn: 'Valider',
        },
      },
    },
    my_products: {
      insight: 'Mes produits',
      title: 'Retrouvez tous vos produits',
      subtitle:
        'Activez, désactivez et modifiez tous les produits associés à ce compte.',
      modal_delete: {
        title: 'Suppression du produit',
        subtitle: 'Êtes-vous certain de vouloir supprimer ce produit ?',
        btn: {
          delete_btn: 'Supprimer',
          cancel_btn: 'Annuler',
        },
        bottom_btn: {
          btn_add: 'Ajouter',
          btn_get: 'Obtenir',
        },
      },
    },
    get_product: {
      title: 'Obtenir un de nos produits',
    },
    unlock_gpm_team: {
      title: 'Découvrez GPM Team',
      subtitle: 'Contrôlez le récit de votre marque avec GPM Team',
      description:
        "GPM Team est un tableau de bord d'administration qui permet aux entreprises de gérer les cartes de visite GPM Team pour leurs employés.",
      possibilities: 'En tant qu’utilisateur de GPM Team, vous pouvez :',
      possibility_1:
        'Créer, modifier et supprimer des cartes pour vos employés',
      possibility_2:
        'Mettre à jour toutes les cartes de votre entreprise lorsque les informations de votre entreprise changent (par exemple, adresse de l’entreprise, logo de l’entreprise ou liens marketing)',
      possibility_3:
        'Rendre certaines cartes non modifiables pour certains utilisateurs',
      possibility_4:
        'Collecter et exporter les coordonnées des personnes qui reçoivent les cartes de visite de vos employés',
      access: 'De plus, tous vos employés ont accès à GPM Team !',
      bottom_btn: 'Découvrir',
    },
  },
  services: {
    title: 'SERVICES',
    gpm_Travel: {
      option_title: 'Découvrez GPM Travel',
      title:
        'Économisez gros sur les voyages avec nos tarifs secrets et des réductions incroyables !',
      description:
        'Découvrez notre porte dérobée vers des tarifs de voyage de gros et des réductions cachées que les détaillants en ligne ne veulent pas que vous connaissiez. Rejoignez-nous pour économiser des milliers de dollars chaque année sur vos voyages !',
      title_possibilities:
        'En tant qu’utilisateur de GPM Travel, vous pouvez :',
      possibility_1:
        'Profiter de jusqu’à 70 % de réduction sur vos deux premières nuits dans plus de deux millions d’hôtels à travers le monde grâce à nos offres exclusives.',
      possibility_2:
        'Explorer le monde pour un tiers du prix habituel grâce à nos offres exceptionnelles et à nos tarifs exclusifs.',
      possibility_3:
        'Ne laissez pas les tarifs de voyage élevés vous retenir. Avec notre abonnement à seulement 99 $ par an, le monde est à portée de main.',
    },
    gpm_Card: {
      option_title: 'Découvrez GPM Card',
      title:
        'Créez et Partagez Votre Carte de Visite Numérique Ultime avec Notre Application Unique !',
      description:
        'Oubliez les cartes de visite en papier dépassées. Notre application révolutionnaire est tout ce dont vous avez besoin pour créer, personnaliser et partager votre carte de visite numérique. Explorez des possibilités infinies de personnalisation, partagez facilement vos coordonnées, suivez vos vues et clics, le tout dans une expérience numérique conviviale.',
      title_possibilities: "En tant qu'utilisateur de GPM Card, vous pouvez :",
      possibility_1: 'Créez une carte unique.',
      possibility_2: "Échangez vos coordonnées d'un clic.",
      possibility_3:
        'Statistiques en Temps Réel Suivez les vues et clics en direct.',
      possibility_4: 'Sans cartes papier, plus écologique.',
      possibility_5: 'Accessibilité Maximale Votre carte, disponible partout.',
    },
    gpm_Wallet: {
      option_title: 'Découvrez GPM Wallet',
      title:
        "Investissez dans l'avenir avec nos compagnons à rendement exceptionnel et plantez des arbres pour un impact positif !",
      description:
        "Découvrez notre collection unique de compagnons qui offre des rendements incroyables, allant jusqu'à 300 % par an. En investissant dans nos compagnons, non seulement vous réalisez des profits impressionnants, mais vous contribuez également à la reforestation. Pour chaque carte compagnon créée, nous plantons un arbre, ce qui signifie que vos bénéfices contribuent à un avenir plus vert et plus prospère.",
      title_possibilities:
        "En tant qu'utilisateur de GPM Wallet, vous pouvez :",
      possibility_1:
        "Nos compagnons offrent un rendement potentiel exceptionnel, jusqu'à 300 % par an.",
      possibility_2:
        'Suivez facilement vos profits générés par nos compagnons.',
      possibility_3:
        "Chaque carte compagnon créée équivaut à la plantation d'un arbre, pour un monde plus vert.",
    },
    mall: {
      option_title: 'Découvrez Budly Mall',
      title:
        'Obtenez 70% de Réduction sur une Sélection de Produits et Profitez de Prix Préférentiels pour les Membres Budly !',
      description:
        "Découvrez une offre exceptionnelle : bénéficiez de 70 % de réduction sur une sélection de produits de haute qualité. Chez Budly, nous chérissons nos utilisateurs, c'est pourquoi nous vous offrons également des prix préférentiels si vous êtes membre Budly ou si vous avez accumulé des points. Profitez de ces avantages exclusifs et économisez sur une multitude de produits à prix réduit.",
      title_possibilities:
        "En tant qu'utilisateur de Budly Mall, vous pouvez :",
      possibility_1:
        'Réduction de 70 % économisez massivement sur une variété de produits de qualité.',
      possibility_2:
        'Prix Préférentiels pour les Membres Budly en tant que membre, profitez de tarifs encore plus avantageux.',
      possibility_3:
        'Utilisez Vos Points pour Économiser, Cumulez des points et réduisez le coût de vos achats.',
      possibility_4:
        'Large Sélection de Produits choisissez parmi de nombreux produits à prix réduit pour répondre à tous vos besoins.',
      tab_mall: {
        title: 'Le mall arrive !',
        subtitle:
          "Faites l'expérience du shopping de luxe à moindre coût ! Le Mall propose les plus grandes marques de luxe à des prix incroyablement réduits, avec une livraison gratuite*",
        advantages: {
          title: 'Produit de marque et de créateurs',
          subadvantages: 'Profitez des meilleurs marques à des prix avantageux',
          title2: "L'expérience du shopping de luxe",
          subadvantages2: 'Découvrez plus de 100.000 produits exclusifs',
          title3: 'Livraison gratuite dans plus de 100 pays',
          subadvantages3:
            "Bénéficiez d'une livraison gratuite pour les commandes supérieures à 150€",
        },
        notify_me: 'Me prévenir quand ça sort',
        reminder_enabled: 'Rappel activé',
      },
    },
  },
  modal_menu: {
    settings: 'Paramètres et préférences',
    bookmarks: 'Voir mes favoris',
    share_QR_code: 'Partager mon profil',
    scan_QR_code: 'Scanner un QR code',
  },
  general: {
    personal_info: {
      title: 'Informations personnelles',
      btn: 'Enregistrer',
      inputs: {
        my_link: 'Mon lien personnalisé',
        email: 'Adresse email',
        number: 'Numéro de téléphone',
      },
    },
    link_preference: {
      title: "Préférences d'affichage",
      btn: 'Enregistrer',
      subtitle:
        "Choisissez votre préférence d'affichage des liens de votre profil",
      list_shap: {
        title: 'Sous forme de liste',
        subtitle: 'Icone, titre et description visibles',
      },
      icon_shap: {
        title: "Sous forme d'icône",
        subtitle: 'Icône du lien uniquement',
      },
    },
    seo: {
      top: {
        title: 'Prévisualisation des métadonnées',
        sentence: 'Mon SEO personnalisé',
      },
      bottom: {
        title: 'Éditer vos informations',
        email: 'Titre',
        description: 'Meta description',
      },
    },
    statistique: {
      title: 'Mes statistiques',
      title_tabs1: 'Générale',
      title_tabs2: 'Vues',
      title_tabs3: 'Vues uniques',
      title_tabs4: 'Clics',
      title_tabs5: 'CTR',
      title_tabs6: 'Affichage par appareil',
      title_tabs7: 'Affichage par pays',
      title_tabs8: 'Principaux réseaux sociaux',
      title_tabs9: 'Principaux liens',
      title_tabs10: 'Principaux contacts',
      top: {
        title: 'Titre',
        clicks: 'Clics',
        graph: 'Graphique',
        show_graph: 'Voir sur le graphique',
      },
      deleted_content: 'Contenu supprimé',
      week_card_text: 'Cette semaine',
      no_data: 'Aucune donnée disponible',
    },
    password: {
      insight: 'Mot de passe',
      title: 'Mot de passe actuel',
      description:
        'Entrez votre mot de passe actuel pour pouvoir le réinitialiser',
      input: 'Mot de passe actuel',
      btn: 'Réinitialiser mon mot de passe',
      notif: {
        email: "L'adresse e-mail est déjà utilisée par un autre compte.",
        error: "Une erreur s'est produite. Veuillez réessayer.",
        psw: 'Vous devez spécifier un mot de passe',
        psw2: "Le mot de passe doit contenir au moins 6 caractères d'au moins une lettre et un chiffre",
        psw3: 'Les mots de passe ne correspondent pas',
        psw4: "Le mot de passe ou l'e-mail que vous avez entré est incorrect. Veuillez réessayer.",
        psw5: 'Aucun utilisateur trouvé avec cet identifiant. Il est possible que le compte ait été supprimé.',
      },
    },
    newpassword: {
      title: 'Nouveau mot de passe',
      subtitle:
        'Votre nouveau mot de passe doit être différent de celui que vous avez utilisé précédemment.',
      inputs: {
        new_psw: 'Nouveau mot de passe',
        confirm_new_psw: 'Confirmer le nouveau mot de passe',
      },
      btn: 'Changer mon mot de passe',
    },
    notification_settings: {
      title: 'Notifications',
    },
    darkmode: {
      title: 'Mode sombre',
      enabled: 'Activé',
      disabled: 'Désactivé',
      device: "Paramètres de l'appareil",
      theme_changed: 'Thème changé avec succès !',
      activate_dark_mode:
        'Activez le mode sombre pour réduire la fatigue oculaire. Utilisez le bouton ci-dessous pour basculer entre les thèmes clair et sombre.',
    },
    languages: {
      insight: 'Langue',
      title: 'Sélection de la langue',
      subtitle: "La langue sélectionnée sera appliquée à toute l'application",
      choice: {
        fr: 'Français',
        en: 'Anglais',
        es: 'Espagnol',
      },
      save: 'Sauvegarder',
    },
  },
  plan: {
    pro_abo: {
      title: 'Abonnement Pro',
      subtitle: 'Accéder à nos abonnements Pro directement sur notre site web',
    },
  },
  share: {
    copy: 'Copier',
    contact_us: {
      title: 'Nous contacter',
      subtitle:
        "Nous sommes là pour vous aider, n'hésitez pas à nous contacter",
      type: {
        email: 'Par email',
        direct_chat: {
          title: 'Contacter le chat en direct',
          subtitle: 'Nous sommes prêts à vous répondre',
        },
      },
    },
    share_friend: {
      title: 'Partager à un ami',
    },
    referral: {
      title: 'Parrainer un ami',
      reward_text:
        'Gagnez 10 graines, 10 gouttes et 10 pièces pour chaque ami invité !',
      module_title:
        'Gagnez 10 graines, 10 gouttes et 10 pièces grâce à vos amis.',
      reward_subtitle:
        'Obtenez des récompenses et faites en gagner à votre ami pour chaque parrainage validé.',
      referred_friends: 'Voir mes <bold>{{friends}}</bold> amis parrainés',
      no_referred_friends: 'Aucun ami parrainé',
      share_referral_link: 'Partagez votre code de parrainage',
      become_ambassador: 'Devenir ambassadeur ?',
      invite_friends: 'Inviter des amis',
      share_message:
        "Bienvenue sur Budly, l'application pour votre identité numérique.",
      share_link: '🌳 Voici mon code parrain :',
      app_link:
        "Pour télécharger l'application cliquez sur le lien suivant : https://budly.link/dl-app?w=gpm",
      sponsored_friends: 'Amis parrainés',
      copy: 'Copier',
      referred_friend_all: 'Tout',
      search: 'Rechercher une personne',
      referred: 'Parrainé',
      valid: 'Validé',
      pending: 'En cours',
      referrer: 'Mon parrain',
      finish_your_quests:
        'Terminer vos quêtes de bienvenue pour valider votre parrainage.',
      drops_rewarded: 'Goutte d’eau obtenus',
      seeds_rewarded: 'Graines obtenus',
      coins_rewarded: 'Pièces obtenus',
      empty_results: {
        no_sponsored_person: 'Aucune personne parrainée trouvée',
        it_looks_like_you:
          "Il semble que vous n'ayez pas encore parrainé cette personne.",
      },
    },
    explain_referral: {
      screen_title: 'Gagner des récompenses',
      title: {
        main_text:
          'Gagnez <color>{{1}}</color>, <color>{{2}}</color> et <color>{{3}}</color> pour chaque ami invité !',
        1: '10 graines',
        2: '10 gouttes',
        3: '10 pièces',
      },
      subtitle:
        'Obtenez des récompenses et faites en gagner à votre ami pour chaque parrainage validé.',
      subscribe_title: "S'inscrire avec votre lien",
      subscribe_text: "Votre ami doit s'inscrire avec votre lien personnalisé",
      finish_quest_title: 'Terminer leurs quêtes de bienvenue',
      finish_quest_text:
        'Votre ami doit compléter ses quêtes de bienvenue pour valider le parrainage',
      collect_reward_title: 'Récuperez vos récompenses',
      collect_reward_text:
        'Une fois les quêtes de bienvenue terminées, vous et votre ami recevrez 10 graines, 10 gouttes et 10 coins gratuitement.',
    },
    review: {
      title: 'Nous laisser un avis',
    },
    faq: {
      title: 'FAQS',
    },
    deconnect: {
      title: 'Se déconnecter',
    },
    delete_acc: {
      delete: 'Supprimer mon compte',
    },
  },
  about: {
    privacy: 'Politique de confidentialité',
    conditions: 'Conditions générales',
    version: 'Version',
  },
}

export default parametre
