import addResourceUsesByUid from './addResourceUsesByUid'
import { api } from '@/Services/api'

export const resourcesUsesApi = api.injectEndpoints({
  endpoints: build => ({
    addResourceUsesByUid: addResourceUsesByUid(build),
  }),
  overrideExisting: true,
})

export const { useAddResourceUsesByUidMutation } = resourcesUsesApi
