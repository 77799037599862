import { DropWaving, Waterdrop } from '@/Assets/icons/components'
import React from 'react'

const type = 'drop'

const Icon = props => (
  <div {...props}>
    <Waterdrop size={props.size} />
  </div>
)

const getLabel = t => t('shop.drop')

const Img = ({ src }) => <img className="h-12" src={src} alt="src" />
const getFreeOffers = t => [
  {
    subtitle: t('shop.free_subtitle', { resource: getLabel(t) }),
    quantity: 1,
    type,
    image: DropWaving,
  },
]

const getOffers = t => [
  {
    subtitle: t('shop.five_seeds_pack_subtitle'),
    quantity: 5,
    image: () => (
      <Img src={require('@/Assets/images/activity/WaterDropOnOneFoot.webp')} />
    ),
    type,
    priceId: 'price_1P6WUyGhdaiFJMYlvOoM1BxR',
  },
  {
    subtitle: t('shop.fifty_seeds_pack_subtitle'),
    quantity: 50,
    image: () => (
      <Img src={require('@/Assets/images/activity/WaterDropJumping.webp')} />
    ),
    type,
    priceId: 'price_1P6WVVGhdaiFJMYl6D2tLEwc',
  },
  {
    subtitle: t('shop.two_hundred_seeds_pack_subtitle'),
    quantity: 200,
    image: () => (
      <Img src={require('@/Assets/images/activity/WaterDropSunglasses.webp')} />
    ),
    type,
    priceId: 'price_1P6WXCGhdaiFJMYlDVZVsKpP',
  },
]

export const drop = {
  type,
  getLabel,
  icon: Icon,
  getFreeOffers,
  getOffers,
  isPrimary: true,
  isConsumable: true,
}
